import { makeStyles } from '@mui/styles';
import { Paper, Stack, Typography, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        width: "100%",
        display: "flex",
        color: "white",
        backgroundColor: "#6f2dbd",
        justifyContent: "center",
    },
    headerCaption: {
        margin: "10px",
        fontWeight: "bold",
    },
    signInPanel: { margin: "10px" },
}));

export interface ISignInBoxProps {
    title: string;
    children?: any | any[];
};

export function SignInBox(props: ISignInBoxProps): JSX.Element {
    const classes = useStyles();
    return (
        <div className="login-box-container">
            <Paper elevation={3} className="login-box">
                <Stack>
                    <div className={classes.header}>
                        <Typography
                            variant="h6"
                            component="h1"
                            className={classes.headerCaption}>
                            {props.title}
                        </Typography>
                    </div>
                    <div className={classes.signInPanel}>{props.children}</div>
                </Stack>
            </Paper>
        </div>
    );
}