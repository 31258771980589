import { Rest, ICrudApiResult, IDataView, CrudApi } from 'omni-voice-react-shared';
import { ICaller, IUser } from '../ApiInterfaces';

export default class UserApi extends CrudApi<IUser> {
    private constructor() { super(1000); }
    private static _instance = new UserApi();

    public getCaller() { return Rest.get<ICaller>('/user/getCaller'); }

    protected async onGet(data_view: IDataView): Promise<ICrudApiResult<IUser>> {
        const resp = await Rest.post<ICrudApiResult<IUser>>('/user/get', data_view);
        return this.processRestResponse(resp, e => e);
    }
    protected async onUpdate(entity: IUser): Promise<ICrudApiResult<IUser>> {
        const resp = await Rest.post<ICrudApiResult<IUser>>('/user/update', entity);
        return this.processRestResponse(resp, e => e);
    }
    protected async onDelete(entity_ids: string[]): Promise<ICrudApiResult<IUser>> {
        if (entity_ids.length > 1) throw new Error("The API supports the deletion of a single entry only");
        const resp = await Rest.get<ICrudApiResult<IUser>>(`/user/delete/${entity_ids[0]}`);
        return this.processRestResponse(resp, e => e);
    }
    protected onDeleteView(data_view: IDataView): Promise<ICrudApiResult<IUser>> {
        throw new Error("Method not supported");
    }
    protected onDeleteAll(): Promise<ICrudApiResult<IUser>> {
        throw new Error("Method not supported");
    }
    protected async onCreate(create_entity_dto: object): Promise<ICrudApiResult<IUser>> {
        const resp = await Rest.post<ICrudApiResult<IUser>>('/user/create', create_entity_dto);
        return this.processRestResponse(resp, e => e);
    }

    async rescope(rescope_dto, data_view: IDataView) {
        const resp = await Rest.post<void>('/user/rescope', rescope_dto);
        if (resp && !resp.hasHttpError) {
            this.invalidateState();
        }
        return this.get(data_view);
    }

    async rerole(rerole_dto, data_view: IDataView) {
        const resp = await Rest.post<void>('/user/rerole', rerole_dto);
        if (resp && !resp.hasHttpError) {
            this.invalidateState();
        }
        return this.get(data_view);
    }

    static getInstance(): UserApi { return UserApi._instance; }
};