import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, RecordVoiceOver as VoiceIcon } from '@mui/icons-material';

interface ILoginMethodSectionProps {
    children: any | any[];
    title: string;
    sx?: any;
    expanded: boolean;
    onChange: () => void;
    icon: any;
};

export function LoginMethodSection(props: ILoginMethodSectionProps): JSX.Element {
    return (
        <Accordion
            elevation={5}
            expanded={props.expanded}
            onChange={props.onChange}
            style={props.sx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {props.icon}
                <Typography sx={{ color: "text.secondary", alignSelf: "right" }}>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{minHeight: "200px"}}>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
}