import { useCallback } from 'react';
import { useStateIfMounted } from 'omni-voice-react-shared';

import { CredsLogin } from './CredsLogin';
import { SignInBox } from './SignInBox';
import { LoginMethodSection } from './LoginMethodSection';
import { VoiceLogin } from './VoiceLogin';

import { Link } from 'react-router-dom';
import { Typography, Box, Snackbar, Alert, Stack } from '@mui/material';
import { RecordVoiceOver as VoiceIcon, Password as CredsIcon } from '@mui/icons-material';

import { ReactComponent as OmniVoiceByOmniIntelligence } from '../../img/omnivoice-by-omniintelligence.svg';

import Cache from '../../stores/Cache';
import "./LoginPage.css";
import { RootRoutePaths } from '../routes/RootRoutePaths';

const LoginMethods = { voice: "voice", creds: "creds", };
const VoiceLoginIcon = <VoiceIcon sx={{ width: "15%", flexShrink: 0, color: "text.secondary" }} />;
const CredsLoginIcon = <CredsIcon sx={{ width: "15%", flexShrink: 0, color: "text.secondary" }} />;

export function LoginPage(): JSX.Element {
    const [activePanel, setActivePanel] = useStateIfMounted(Cache.loginMethod ?? LoginMethods.voice);
    const [loginError, setLoginError] = useStateIfMounted(null);

    const LoginError = () => loginError && (
        <Snackbar open={true} autoHideDuration={1000 * 60} onClose={() => setLoginError(null)}>
          <Alert severity="error" sx={{ width: "100%" }}>{loginError}</Alert>
        </Snackbar>
    );

    const setPanelAndSave = useCallback((login_method: string) => {
        Cache.loginMethod = login_method;
        setActivePanel(login_method);
    }, [setActivePanel]);

    return (
        <Stack
            className="login-page-root"
            direction="row"
            alignItems="stretch">
            <Box
                className="login-box-panel"
                component="div"
                sx={{
                    width: {
                        xs: "100%",
                        md: "35%"
                    },
                }}>
                <SignInBox title="Sign-in to OMNI Voice">
                    <Typography color="secondary">Don't have an account? <Link to={RootRoutePaths.homeNav(RootRoutePaths.Register)}>Click here</Link> to sign-up for free!</Typography>
                    
                    <LoginMethodSection
                        sx={{ marginTop: "20px" }}
                        icon={VoiceLoginIcon}
                        onChange={() => setPanelAndSave(LoginMethods.voice)}
                        expanded={activePanel===LoginMethods.voice || !activePanel}
                        title="Sign-in with your voice">
                        <VoiceLogin onLoginError={setLoginError} />
                    </LoginMethodSection>

                    <LoginMethodSection
                        icon={CredsLoginIcon}
                        onChange={() => setPanelAndSave(LoginMethods.creds)}
                        expanded={activePanel===LoginMethods.creds}
                        title="Sign-in with email & password">
                        <CredsLogin onLoginError={setLoginError} />
                    </LoginMethodSection>
                </SignInBox>
            </Box>

            <Stack
                className="branding-box-panel"
                component="div"
                direction="column"
                sx={{
                    display: {
                        xs: "none",
                        md: "flex",
                    },
                }}>
                <Box className="branding-body">
                    <Typography
                        sx={{
                            width: {
                                lg: "800px",
                                md: "300px",
                            },
                            fontSize: {
                                lg: "50pt",
                                md: "40pt",
                            },
                        }}
                        className="branding-text"
                        variant="h2"
                        component="div">
                        Integrate Voice Biometrics into your Website.
                    </Typography>
                </Box>
                <Box className="branding-footer">
                    <OmniVoiceByOmniIntelligence />
                </Box>
            </Stack>
            <LoginError />
        </Stack>
    );
}