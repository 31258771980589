import { IConverter, IPropEditorDef, ModelEditorType } from "./interfaces";
import { CreateConverter } from "./converters";
import { EditorMetadata } from "./EditorMetadata";

export class PropEditor {
    readonly propertyKey: string;
    readonly metadata: EditorMetadata;
    readonly converter: IConverter;
    readonly definition: IPropEditorDef;
    readonly propType: ModelEditorType;

    getPropValue() { return this.metadata.model[this.propertyKey]; }
    getPropValueAsString(): string { return this.converter.toString(this.getPropValue()); }
    setPropValue(value: any): void { this.metadata.model[this.propertyKey] = value; }
    setPropValueFromString(str_val: string): void { this.setPropValue(this.converter.fromString(str_val)); }

    constructor(metadata: EditorMetadata, prop_key: string, definition: IPropEditorDef) {
        if (!metadata) throw new Error("metadata is null or undefined");
        if (!prop_key) throw new Error("prop_key is null or undefined");
        if (!definition) throw new Error("definition is null or undefined");

        this.converter = CreateConverter(definition.type, definition.selectValues);
        this.metadata = metadata;
        this.definition = definition;
        this.propType = definition.type;
        this.propertyKey = prop_key;
    }
};