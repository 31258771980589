import { BACKEND_URL } from '../../env-variables';

export class RootRoutePaths {
    /**
     * env-variables.js is created inside of a docker container by the docker script
     * under development, BACKEND_URL is null and so this hardcoded URL will be used.
     */
    public static BaseBackendAddress: string = BACKEND_URL ?? "http://127.0.0.1:5000/api";
    // Root paths
    public static AppsHome: string = "";
    public static AppsAdmin: string = "admin";
    public static BackendError: string = "backend-error";
    public static SignIn: string = "login";
    public static Register: string = "register";
    public static ResetPassword: string = "reset-password";

    // Admin paths
    public static ManageOrgs: string = "orgs";
    public static ManageUsers: string = "users";
    public static ManageScopes: string = "scopes";
    public static ManagePlatform: string = "platform";

    public static adminNav(admin_route: string): string { return RootRoutePaths.homeNav(`${RootRoutePaths.AppsAdmin}/${admin_route}`); }
    public static homeNav(home_route: string): string {
        return (!RootRoutePaths.AppsHome || RootRoutePaths.AppsHome === "")
            ? `/${home_route}`
            : `/${RootRoutePaths.AppsHome}/${home_route}`;
    }
    public static errorNav(error_msg: string, details: string): string { return `/${RootRoutePaths.BackendError}?error=${error_msg}&details=${details}`; }
};