import { useStateIfMounted, UtilityDialog } from "omni-voice-react-shared";
import { WizardLayout } from "./WizardLayout";
import { useNavigate } from "react-router-dom";
import { RootRoutePaths } from "../routes/RootRoutePaths";
import { WaitForPhoneConfirmation } from "./WaitForPhoneConfirmation";

import ViewLayout from "../ViewLayout";

import { GetRegistrationDetails } from "./GetRegistrationDetails";
import { RegistrationComplete } from "./RegistrationComplete";
import { Box } from "@mui/material";
import { IApiCallResult } from "../../services/ApiInterfaces";
import { AuthApi } from "../../services/backend-apis/AuthApi";

type UtilityDialogProps = { title: string, desc: string, onClose: any, variant: string };

enum WizardStepEnum { RegistrationDetails, WaitForLinkConfirmation, RegistrationComplete };

export function RegistrationForm(): JSX.Element {
    const [wizardStep, setWizardStep] = useStateIfMounted(WizardStepEnum.RegistrationDetails);
    const [error, setError] = useStateIfMounted(null);
    const [pending, setPending] = useStateIfMounted(false);
    const [disableNext, setDisableNext] = useStateIfMounted(true);
    const [details, setDetails] = useStateIfMounted(null);

    const navigate = useNavigate();

    const handleDetailsChange = (valid, reg_details) => {
        if (valid) setDetails(reg_details);
        setDisableNext(!valid);
    }

    const handleNext = async () => {
        setPending(true);
        setWizardStep(WizardStepEnum.WaitForLinkConfirmation);
        const result: IApiCallResult = await AuthApi.instance.register({
            ...details,
            country: details.country?.code,
            email: details.username
        });
        setPending(false);
        if (result?.success) {
            setWizardStep(WizardStepEnum.RegistrationComplete);
        }
        else {
            setError(result?.error ?? "Backend Unavailable. Please try again or contact OMNI Voice support");
        }
    };

    const handleComplete = () => { navigate(RootRoutePaths.homeNav(RootRoutePaths.SignIn)); }
    
    const renderWizardStep = () => {
        switch(wizardStep) {
            case WizardStepEnum.RegistrationDetails:
                return <GetRegistrationDetails
                            details={details}
                            onChange={handleDetailsChange}
                            />;
            case WizardStepEnum.WaitForLinkConfirmation:
                return <WaitForPhoneConfirmation />;
            default:
                return <RegistrationComplete />;
        }
        
    };

    return (
        <ViewLayout>
            <WizardLayout
                onBack={() => setWizardStep(WizardStepEnum.RegistrationDetails)}
                onComplete={handleComplete}
                onNext={handleNext}
                disabled={disableNext || wizardStep === WizardStepEnum.WaitForLinkConfirmation || pending}
                step={wizardStep}
                steps={["Fill-in Registration Form", "Confirm Your Phone Number", "Set Password"]}>
                {renderWizardStep()}
            </WizardLayout>
            <Box sx={{ height: "100px" }} />
            {error && (
                <UtilityDialog
                    {...{
                        onClose: () => {
                            setError(null);
                            setWizardStep(WizardStepEnum.RegistrationDetails);
                        },
                        variant: "error",
                        title: "Registration Error",
                        desc: error,
                    } as UtilityDialogProps} />
            )}
       </ViewLayout>
    );
}