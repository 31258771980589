import { IRoleDefinition, ICaller, IOrg, IScope } from "../services/ApiInterfaces";

export default class Cache {
    static ROLE_KEY: string = "selectedRole";
    static ORG_KEY: string = "selectedOrg";
    static SCOPE_KEY: string = "selectedScope";
    static CALLER_KEY: string = "caller";
    static LOGIN_METHOD_KEY: string = "loginMethod";

    static set loginMethod(value: string) { window.localStorage.setItem(Cache.LOGIN_METHOD_KEY, value); }
    static get loginMethod(): string { return window.localStorage.getItem(Cache.LOGIN_METHOD_KEY); }

    static get effectiveRole(): IRoleDefinition {
        try {
            var role_json = window.localStorage.getItem(Cache.ROLE_KEY)
            var result = JSON.parse(role_json);
            return result;
        }
        catch {
            return null;
        }
    }

    static set effectiveRole(role: IRoleDefinition) {
        if (!role) {
            window.localStorage.removeItem(Cache.ROLE_KEY);
        }
        else {
            var role_json = JSON.stringify(role);
            window.localStorage.setItem(Cache.ROLE_KEY, role_json);
        }
    }

    static get effectiveOrg(): IOrg {
        try {
            var role_json = window.localStorage.getItem(Cache.ORG_KEY)
            var result = JSON.parse(role_json);
            return result;
        }
        catch {
            return null;
        }
    }

    static set effectiveOrg(org: IOrg) {
        if (!org) {
            window.localStorage.removeItem(Cache.ORG_KEY);
        }
        else {
            var role_json = JSON.stringify(org);
            window.localStorage.setItem(Cache.ORG_KEY, role_json);
        }
    }

    static get effectiveScope(): IScope {
        try {
            var role_json = window.localStorage.getItem(Cache.SCOPE_KEY)
            var result = JSON.parse(role_json);
            return result;
        }
        catch {
            return null;
        }
    }

    static set effectiveScope(scope: IScope) {
        if (!scope) {
            window.localStorage.removeItem(Cache.SCOPE_KEY);
        }
        else {
            var role_json = JSON.stringify(scope);
            window.localStorage.setItem(Cache.SCOPE_KEY, role_json);
        }
    }

    static get callerUser(): ICaller {
        try {
            var role_json = window.localStorage.getItem(Cache.CALLER_KEY)
            var result = JSON.parse(role_json);
            return result;
        }
        catch {
            return null;
        }
    }

    static set callerUser(caller: ICaller) {
        if (!caller) {
            window.localStorage.removeItem(Cache.CALLER_KEY);
        }
        else {
            var role_json = JSON.stringify(caller);
            window.localStorage.setItem(Cache.CALLER_KEY, role_json);
        }
    }
};