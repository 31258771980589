import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Typography, Paper } from '@mui/material';
import { Settings, Person, Apartment as OrgIcon } from '@mui/icons-material';
import { RootRoutePaths } from '../routes/RootRoutePaths';

export interface IAdminPanelProps {
    orgCount?: number;
    userCount?: number;
};

export function AdminPanel(props: IAdminPanelProps) {
    return <>
        <Paper className="card" elevation={10}>
            <div className="bkg">
                {/*<div className={classes.rotated} style={{backgroundImage: `url(${ReportWallpaper})`}} />*/}
                {/*<img className={classes.rotated} src={Settings} style={{marginTop: "-15%"}} />*/}
                <Settings className="rotated" style={{marginTop: "-30%"}} />
            </div>

            <Typography className="card-title"><Link to={RootRoutePaths.adminNav("")}>Admin</Link></Typography>

            <List className="root">
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <OrgIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Organizations in Scope">
                        <Link to={RootRoutePaths.adminNav(RootRoutePaths.ManageOrgs)}>Orgs: {props.orgCount ?? "--"}</Link>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <Person />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Users in Scope">
                        <Link to={RootRoutePaths.adminNav(RootRoutePaths.ManageUsers)}>Users: {props.userCount ?? "--"}</Link>
                    </ListItemText>
                </ListItem>
            </List>

            <Typography className="description">
                
            </Typography>
        </Paper>
    </>;
}