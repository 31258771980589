import './App.css';
import { RootAppRouter } from './components/routes/RootAppRouter';
import { RestState } from './services/app-state/RestState';
import { Rest } from 'omni-voice-react-shared';
import { setNavigate } from './services/navigate';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

Rest.State = new RestState();

function App() { 
    const navigate = useNavigate();

    useEffect(() => {
      setNavigate(navigate);
    }, [navigate]);
    return <RootAppRouter />; 
};

export default App;