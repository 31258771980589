import { useMemo, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';

import { useStateIfMounted } from 'omni-voice-react-shared';
import ManagePlatformApi from '../../../services/backend-apis/ManagePlatformApi';
import AppControls from './AppControls';
import { PlatformSettings } from './PlatformSettings';
import { GroupBox } from 'omni-voice-react-shared';

const scrollable = {
    overflowY: 'scroll',
    overflowX: "hidden",
    border:'0px solid red',
    float: 'left',
    maxWidth: "100%",
    height:'calc(100vh - 270px)',
    position:'relative',
};

const ManagePlatform = () => {
    const api = useMemo(() => new ManagePlatformApi(), []);
    const [apps, setApps] = useStateIfMounted([]);
    const [config, setConfig] = useStateIfMounted(null);

    useEffect(() => {
        api
            .getApps()
            .then(apps => setApps(apps));
        api
            .getPlatform()
            .then(cfg => setConfig(cfg));
    // eslint-disable-next-line
    }, []);

    return (
        <div style={scrollable}>
            <Stack direction="column" spacing={1}>
                <GroupBox sx={{ margin: "30px" }} caption="Platform Settings">
                    {config &&
                        <PlatformSettings config={config} />}
                </GroupBox>
                
                <Grid container direction="row" spacing={4} justifyContent="center" sx={{ paddingRight: "60px" }}>
                    {apps.map((app, key) => {
                        return (
                            <Grid key={key} item xs={12} lg={6}>
                                <GroupBox caption={app.id}>
                                    <AppControls appStatus={app} />
                                </GroupBox>
                            </Grid>
                        );
                    })}
                </Grid>
            </Stack>
        </div>
    );
};

export default ManagePlatform;