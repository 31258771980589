import { Box, Stepper, Step, StepLabel, Button, Typography, Stack } from "@mui/material";

export interface IWizardLayoutProps {
    children: any | any[];
    step: number;
    disabled?: boolean;
    steps: string[];
    onNext: () => void;
    onComplete: () => void;
    onBack: () => void;
};

export function WizardLayout(props: IWizardLayoutProps): JSX.Element {
    return (
        <Stack
            sx={{ marginTop: "3%" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <Typography component="div" variant="h4" sx={{ marginBottom: "2%" }}>Register</Typography>
            <Box sx={{ width: "60%" }}>
                <Stepper activeStep={props.step}>
                    {props.steps.map((label, key) => {
                        const stepProps = {
                            completed: props.step > key,
                        };
                        return (
                            <Step key={key} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Box sx={{ marginTop: "40px" }}>{props.children}</Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, gap: "10px" }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                        disabled={props?.disabled || props.step === 0 || props.step === props.steps.length - 1}
                        variant="outlined"
                        onClick={() => props.onBack()}>
                        Back
                    </Button>
                    <Button
                        disabled={props?.disabled}
                        variant="contained"
                        onClick={() => {
                            if (props.step < props.steps.length - 1) props.onNext();
                            else props.onComplete();

                        }}>
                        {props.step < props.steps.length - 1 ? "Next" : "Finsh"}
                    </Button>
                </Box>
            </Box>
        </Stack>
    );
}