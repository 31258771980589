import { Centered, useStateIfMounted, UtilityDialog } from "omni-voice-react-shared";
import { useNavigate, useParams } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist"
import { Button, TextField } from "@mui/material";
import { AuthApi } from "../../services/backend-apis/AuthApi";
import { RootRoutePaths } from "../routes/RootRoutePaths";

import MakeBasicCard from "../MakeBasicCard";
import ViewLayout from "../ViewLayout";
import { useEffect } from "react";

type UtilityDialogProps = { title: string, desc: string, onClose: any, variant: string };

const common_props = {
    type: "password" as any,
    variant: "outlined" as any,
    autoComplete: "current-password" as any,
    sx: {
        margin: "10px",
        minWidth: "100%",
    },
};

export function ResetPassword(): JSX.Element {
    const [password, setPassword] = useStateIfMounted("");
    const [confirmPassword, setConfirmPassword] = useStateIfMounted("");
    const [passwordValid, setPasswordValid] = useStateIfMounted(false);
    const [pending, setPending] = useStateIfMounted(false);
    const [error, setError] = useStateIfMounted(null);
    const { confirmationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!confirmationId) {
            navigate(RootRoutePaths.homeNav(RootRoutePaths.SignIn));
        }
    }, []);

    const handleResetClick = async () => {
        setPending(true);
        const result = await AuthApi.instance.resetPassword(confirmationId, password);
        setPending(false);
        if (result.success) {
            navigate(RootRoutePaths.homeNav(RootRoutePaths.SignIn));
        }
        else {
            setError(result.error);
        }
    }

    return (
        <ViewLayout>
            <Centered>
                <form onSubmit={handleResetClick}>
                <div style={{ minHeight: "80vh", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <MakeBasicCard title="Reset Password">
                        <TextField
                            {...common_props}
                            disabled={pending}
                            label="Type password"
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                        <TextField
                            {...common_props}
                            disabled={pending}
                            label="Confirm password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)} />
                        <PasswordChecklist
                            rules={["minLength","specialChar","number","capital","match"]}
                            minLength={8}
                            value={password}
                            valueAgain={confirmPassword}
                            onChange={(isValid) => {setPasswordValid(isValid)}}
                        />
                        <Centered>
                            <Button
                                type="submit"
                                autoFocus
                                onClick={handleResetClick}
                                disabled={!passwordValid || pending}
                                children="Reset" />
                        </Centered>
                    </MakeBasicCard>
                    {error && (
                        <UtilityDialog
                            {...{
                                onClose: () => setError(null),
                                variant: "error",
                                title: "Error Resetting Password",
                                desc: error,
                            } as UtilityDialogProps} />
                    )}
                </div>
                </form>
            </Centered>
        </ViewLayout>
    );
}