import { useEffect } from "react";
import { useAuthState } from "omni-voice-react-shared";
import { useNavigate } from "react-router-dom";
import { RootRoutePaths } from "./RootRoutePaths";

export interface IProtectedRouteProps {
    children: any | any[];
};

/**
 * Redirects to login if not authenticated.
 */
export function ProtectedRoute(props: IProtectedRouteProps): JSX.Element {
    if (!props) throw new Error("props is null or undefined");

    const authState = useAuthState();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authState.signedIn) {
            navigate(RootRoutePaths.homeNav(RootRoutePaths.SignIn));
        }
    }, [authState.signedIn]);

    return (<>{props.children}</>);
}