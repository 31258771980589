import { useEffect, useCallback, useMemo } from 'react';

import { TextField, Tooltip, InputAdornment, Button, Stack, Typography } from '@mui/material';
import { Language as FrontendIcon, Storage as BackendIcon,
    VpnKey as ApiKeyIcon, Save as ApplyChangesIcon, ExitToApp as LoginIcon } from '@mui/icons-material';

import { TriStateLED, ApplyCancelDialog, useStateIfMounted } from 'omni-voice-react-shared';
import { OpenAppButton } from './OpenAppButton';

import ManagePlatformApi from '../../../services/backend-apis/ManagePlatformApi';
import useAppLogin from '../../../services/hooks/useAppLogin';

//const loginIcon = <LoginIcon sx={{ width: "35px", height: "35px" }} />

function getStatusLabel(status) {
    return status.isMounted ? ( status.hasError ? "Error" : "Mounted" ) : "Unmounted";
}

const AppControls = ({ appStatus }) => {
    const api = useMemo(() => new ManagePlatformApi(), []);
    const appLogin = useAppLogin(appStatus.id);

    const [status, setStatus] = useStateIfMounted(appStatus);

    const [systemApiKey, setSystemApiKey] = useStateIfMounted(appStatus.systemApiKey);
    const [backendUrl, setBackendUrl] = useStateIfMounted(appStatus.backendUrl);
    const [frontendUrl, setFrontendUrl] = useStateIfMounted(appStatus.frontendUrl);
    const [isModified, setIsModified] = useStateIfMounted(false);
    const [statusText, setStatusText] = useStateIfMounted(getStatusLabel(appStatus));

    const [showApplyChangesDialog, setShowApplyChangesDialog] = useStateIfMounted(false);
    const [showMountDialog, setShowMountDialog] = useStateIfMounted(false);
    const [errorText, setErrorText] = useStateIfMounted(null);

    useEffect(() => {
        if (status) {
            setSystemApiKey(status.systemApiKey);
            setBackendUrl(status.backendUrl);
            setFrontendUrl(status.frontendUrl);
            setIsModified(false);
            setStatusText(getStatusLabel(status));
        }
    }, [status]);

    useEffect(() => {
        if (status) {
            var is_modified = systemApiKey !== appStatus.systemApiKey ||
                backendUrl !== status.backendUrl ||
                frontendUrl !== status.frontendUrl;
            setIsModified(is_modified);
        }
    // eslint-disable-next-line
    }, [systemApiKey, status, backendUrl, frontendUrl]);

    const onApplyMountDialog = useCallback(() => {
        const handle_new_status = new_status => {
            //console.log("New status: " + JSON.stringify(new_status));
            if (new_status) {
                setStatus(new_status);
                if (new_status.hasError) {
                    setErrorText(new_status.error);
                }
            }
        };

        if (status) {
            setShowMountDialog(false);
            if (status.isMounted) {
                api
                    .unmountApp(status.id)
                    .then(new_status => handle_new_status(new_status));
            }
            else {
                api
                    .mountApp(status.id)
                    .then(new_status => handle_new_status(new_status));
            }
        }
    }, [setShowMountDialog, status, api]);

    const onApplyChangesDialog = useCallback(() => {
        setShowApplyChangesDialog(false);
        api
            .updateAppConfig({
                ...status,
                backendUrl: backendUrl,
                frontendUrl: frontendUrl,
                systemApiKey: systemApiKey,
            })
            .then(new_status => {
                //console.log("Updated status: " + JSON.stringify(new_status));
                if (new_status) {
                    setStatus(new_status);
                    if (new_status.hasError) {
                        setErrorText(new_status.error);
                    }
                }
            })
    }, [setShowApplyChangesDialog, setStatus, status, backendUrl, frontendUrl, systemApiKey, api, setErrorText]);

    const onCancelMountDialog = useCallback(() => setShowMountDialog(false), [setShowMountDialog]);
    const onCancelChangesDialog = useCallback(() => setShowApplyChangesDialog(false), [setShowApplyChangesDialog]);

    const handleBackendUrlChange = useCallback(val => setBackendUrl(val), [setBackendUrl]);
    const handleFrontendUrlChange = useCallback(val => setFrontendUrl(val), [setFrontendUrl]);
    const handleSystemApiKeyChange = useCallback(val => setSystemApiKey(val), [setSystemApiKey]);

    return (
        <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                border: '0px solid purple',
            }}>

            <Tooltip placement="top" title={status?.hasError ? status.error : "No Errors"}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <TriStateLED onClick={() => setShowMountDialog(true)} state={status?.isMounted ? !status.hasError : null} />
                    <Typography variant="overline">{statusText}</Typography>
                </Stack>
            </Tooltip>

            <TextField
                fullWidth
                value={backendUrl}
                onChange={e => handleBackendUrlChange(e.target.value)}
                variant="outlined"
                label="Backend URL"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <BackendIcon />
                        </InputAdornment>
                    ),
                }}
                />
            <TextField
                fullWidth
                value={frontendUrl}
                onChange={e => handleFrontendUrlChange(e.target.value)}
                variant="outlined"
                label="Frontend URL"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FrontendIcon />
                        </InputAdornment>
                    ),
                }}
                />
            <TextField
                fullWidth
                value={systemApiKey}
                onChange={e => handleSystemApiKeyChange(e.target.value)}
                label="System API Key"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <ApiKeyIcon />
                        </InputAdornment>
                    ),
                }}
                variant="outlined"/>
            <Stack direction="row" spacing={1}>
                <Button
                    disabled={!isModified}
                    onClick={() => setShowApplyChangesDialog(true)}
                    sx={{ padding: "10px" }}
                    startIcon={<ApplyChangesIcon sx={{ width: "35px", height: "35px" }} />}>
                    Apply
                </Button>
                <Button
                    disabled={appLogin.pending || !status?.isMounted}
                    onClick={appLogin.signIn}
                    sx={{ padding: "10px" }}
                    startIcon={<LoginIcon sx={{ width: "35px", height: "35px" }} />}>
                    Open
                </Button>
            </Stack>

            {showMountDialog && (
                <ApplyCancelDialog title="App Mount / Unmount Confirmation" onApply={onApplyMountDialog} onCancel={onCancelMountDialog}>
                    <span>We will attempt to {status?.isMounted ? "unmount" : "mount"} {status?.id} when you confirm the action.
                    <br/>
                    {status?.isMounted ? "Once the App is unmounted, it will not be available for the other apps as well." :
                        "Once the App is mounted successfully, it will be available immediately."}</span>
                    <br/>
                    <br/>
                    <span>To {status?.isMounted ? "unmount" : "mount"} click on "Apply".</span>
                </ApplyCancelDialog>
            )}

            {showApplyChangesDialog && (
                <ApplyCancelDialog title="Apply Changes" onApply={onApplyChangesDialog} onCancel={onCancelChangesDialog}>
                    <span>Click on "Apply" to commit the changes you have made to {status?.id}.</span>
                </ApplyCancelDialog>
            )}

            {errorText && (
                <ApplyCancelDialog title={`Error (${status?.id})`} onCancel={() => setErrorText(null)}>
                    <span>{errorText}</span>
                </ApplyCancelDialog>
            )}

            {appLogin.showStatus && (
                <ApplyCancelDialog title={`Sign-In Status (${status?.id})`} onCancel={appLogin.cancelStatus}>
                    <span>{appLogin.loginResult.success ? `Signed-In to: ${appLogin.loginResult.frontendUrl}` : `Sign-In error: ${appLogin.loginResult.errorMessage}`}</span>
                    <br />
                    <br />
                    <OpenAppButton
                        appId={status?.id}
                        disabled={appLogin.pending || !status?.isMounted} />
                </ApplyCancelDialog>
            )}
        </Stack>
    );
};

export default AppControls;