import { useStateIfMounted } from 'omni-voice-react-shared';

export interface IEvent {
    payload: any;
    raise(payload: any): void;
};

export function useEvent(): IEvent {
    const [payload, setPayload] = useStateIfMounted(null);
    return {
        payload: payload,
        raise: (payload) => setPayload(payload),
    }
}