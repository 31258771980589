import { InlineWorkerCompiled } from './InlineWorkerCompiled';

export interface IWorkerResult<TResult> {
  success: boolean;
  result: TResult;
  error: string;
};

/**
 * Borrowed from here: https://gist.github.com/julianpoemp/1292445696eae2ea319d92ae15ecffa4
 */
export class InlineWorker {
    private blobURL: string;
    private worker: Worker;
    private import: string;
  
    constructor(imports?: string[]) {
      this.import = imports ? imports.reduce((imp1, imp2) => `${imp1}\n\n\n${imp2}`) : null;
      //console.log("Worker Script:");
      //console.log(this.getWorkerScript());
      // creates a worker that runs a job
      this.blobURL = URL.createObjectURL(new Blob([
          this.getWorkerScript()
        ],
        {
          type: 'application/javascript'
        }
      ));
      this.worker = new Worker(this.blobURL);
    }
  
    public run<TResult>(job: InlineWorkerJob): Promise<IWorkerResult<TResult>> {
      return new Promise<any>(
        (resolve, reject) => {
          this.worker.onmessage = (ev: MessageEvent) => {
            var worker_result = ev.data as IWorkerResult<TResult>;
            job.statistics.ended = Date.now();
            ev.data.statistics = job.statistics;
            resolve(worker_result);
          };
  
          this.worker.onerror = (err) => {
            job.statistics.ended = Date.now();
            var worker_result = {
              error: err + "",
              result: null,
              success: false,
            } as IWorkerResult<TResult>;
            reject(worker_result);
          };
  
          job.statistics.started = Date.now();
          //console.log("JOB", this.convertJobToObj(job));
          // doFunction: "(context, args) => {return new Promise(resolve => {var num_channels = args[0];var aud_SR_Hz = args[1];var wav_SR_Hz = args[2];context[\"workerApi\"] = new AudioCaptureWorkerApi(num_channels, aud_SR_Hz, wav_SR_Hz);resolve();});}"
          this.worker.postMessage({
            command: 'run',
            args: [this.convertJobToObj(job)]
          });
        }
      );
    }
  
    private convertJobToObj(job: InlineWorkerJob) {
      return {
        id: job.id,
        args: job.args,
        doFunction: job.doFunctionScript ?? job.doFunction.toString()
      };
    }
  
    /**
     * destroys the Taskmanager if not needed anymore.
     */
    public destroy() {
      URL.revokeObjectURL(this.blobURL);
    }
  
    private getWorkerScript(): string {
      return InlineWorkerCompiled;

      // uncomment the below to produce a pre-compiled version of the
      // worker script -> this will need to be done each time the logic
      // of resampling / filteting changes
      // The reason for it is WEBPACK completely destroys the autogenerated code...

//      return (
// `
// ${this.import ? this.import + "\n\n\n" : ""}
// var job = null;
// var base = self;
// var context = new Object();
// onmessage = function (msg) {
//     var data = msg.data;
//     var command = data.command;
//     var args = data.args;
//     switch (command) {
//         case("run"):
//             base.job = args[0];
//             var func = new Function("return " + base.job.doFunction)();
//             func(context, base.job.args).then(function (result) {
//                 base.postMessage({
//                     success: true,
//                     result: result,
//                     error: "",
//                 });
//             }).catch(function (error) {
//                 base.postMessage({
//                     success: false,
//                     result: null,
//                     error: error,
//                 });
//             });
//             break;
//         default:
//             base.postMessage({
//                 success: false,
//                 result: null,
//                 error: "invalid command"
//             });
//             break;
//     }
// }`);
     }
}
  
export class InlineWorkerJob {
  private static jobIDCounter = 0;
  private _id: number;
  private _args: any[] = [];
  private _do_func_script: string = null;
  
  private _statistics = {
    started: -1,
    ended: -1
  };

  get statistics(): { ended: number; started: number } { return this._statistics; }
  set statistics(value: { ended: number; started: number }) { this._statistics = value; }

  get args(): any[] { return this._args; }
  get id(): number { return this._id; }

  get doFunctionScript(): string { return this._do_func_script; }
  
  constructor(doFunction: (context: object, args: any[]) => Promise<any>, args: any[], func_script = null) {
      this._id = ++InlineWorkerJob.jobIDCounter;
      this.doFunction = doFunction;
      this._args = args;
      this._do_func_script = func_script;
    }
  
  doFunction = (context: any, args: any[]) => {
    return new Promise<any>((resolve, reject) => { reject('not implemented'); });
  }
};