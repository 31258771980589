import { useMemo, useRef, useCallback } from 'react';
import { IAppLoginResult } from '../ApiInterfaces';
import ManagePlatformApi from '../backend-apis/ManagePlatformApi';
import { useStateIfMounted } from 'omni-voice-react-shared';

export class AppId {
    static Billing: string = "Billing";
    static OmniVoice: string = "Omni Voice";
    static Media: string = "Genesys Cloud Media";
};

function useAppLogin(appId: string) {
    const signedOut = useMemo(() => {
        return {
            oAuthToken: "",
            success: false,
            errorMessage: "Signed out",
            frontendUrl: "",
        } as IAppLoginResult;
    }, []);

    const api = useRef(new ManagePlatformApi());
    const [showStatus, setShowStatus] = useStateIfMounted(false);
    const [pending, setPending] = useStateIfMounted(false);
    const [appLoginResult, setAppLoginResult] = useStateIfMounted(null);

    const login = useCallback(async () => {
        setPending(true);
        var login_result = await api
            .current
            .loginToApp(appId);

        //console.log(JSON.stringify(login_result));

        setAppLoginResult(login_result);
        setShowStatus(true);
        setPending(false);

        return login_result;
    }, [api, appId, setAppLoginResult, setShowStatus, setPending]);

    const logoff = useCallback(() => setAppLoginResult(signedOut), [setAppLoginResult, signedOut]);
    const cancelStatus = useCallback(() => setShowStatus(false), [setShowStatus]);
    const getSignInLink = useCallback((route?: string) => {
        var url = appLoginResult.frontendUrl;
        if (url && url.slice(-1) !== '/') {
            url += '/';
        }
        var r = "summary";
        if (route) {
            if (route.slice(0, 1) === "/") {
                r = route.slice(1);
            }
            else {
                r = route;
            }
        }
        return `${url}${r}?oauth=${encodeURIComponent(appLoginResult.oAuthToken)}&backendUrl=${encodeURIComponent(appLoginResult.backendUrl)}`;
    }, [appLoginResult]);

    return {
        isLoggedIn: () => appLoginResult && appLoginResult.success,
        loginResult: appLoginResult,
        signIn: login,
        signOut: logoff,
        // dialog state...
        showStatus: showStatus,
        cancelStatus: cancelStatus,
        pending: pending,
        signInUrl: getSignInLink,
    };
};

export default useAppLogin;