import { useCallback, useEffect } from 'react';
import useAppLogin from '../../../services/hooks/useAppLogin';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { useStateIfMounted, ApplyCancelDialog } from 'omni-voice-react-shared';
import { styled } from '@mui/styles';
import { useTheme } from '@mui/material';

export interface IOpenAppButtonProps {
    appId: string;
    children?: any;
    path?: string;
    justifyContent?: string;
    disabled?: boolean;
};

export const OpenAppButton = ({ appId, children, path, justifyContent = "center", disabled = false }: IOpenAppButtonProps) => {
    const appLogin = useAppLogin(appId);
    const [errorMessage, setErrorMessage] = useStateIfMounted(null);
    const theme = useTheme();

    const handleLoginResult = useCallback(() => {
        if (!appLogin.loginResult.pending) {
            if (appLogin.isLoggedIn()) {
                window.open(appLogin.signInUrl(path));
                //alert(appLogin.signInUrl());
            }
            else {
                //console.log(appLogin.loginResult.errorMessage);
                setErrorMessage(appLogin.loginResult.errorMessage);
            }
        }
    }, [appLogin, setErrorMessage]);

    useEffect(() => {
        if (appLogin.loginResult)
            handleLoginResult();
        // eslint-disable-next-line
    }, [appLogin.loginResult])

    const Pending = () => (
        <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
                color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                animationDuration: '550ms',
                left: "0",
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
            }}
            size={30}
            thickness={5} />
    );

    const LinkButton = styled("div")(() => ({
        color: theme.palette.primary.dark,
        cursor: "pointer",
        textDecoration: "underline",
    }));

    const OpenButton = (props) => {
        return (
            <LinkButton
                {...props}
                disabled={disabled}
                onClick={appLogin.signIn}>
                    {children ? children : <span>"Go to App (new window)"</span>}
            </LinkButton>
        );
    };
    
    return (
        <div style={{ display: "flex", justifyContent: justifyContent, alignItems: "center", height: "auto" }}>
            {appLogin.pending ? <Pending /> : <OpenButton />}
            {errorMessage && (
                <ApplyCancelDialog
                    onApply={null}
                    onCancel={() => setErrorMessage(null)}
                    title="Oops..">
                    <span>We could not obtain the app link due to the following error:</span>
                    <br />
                    <br />
                    <span>{errorMessage}</span>
                </ApplyCancelDialog>
            )}
        </div>
    );
}