import { useCallback, useContext } from "react";

import { Theme, Stack } from "@mui/material"
import { useTheme } from '@mui/styles';

import { VoiceRecorder, VoiceRecorderSize } from "./voice-recorder/VoiceRecorder";
import { RecorderThemeId } from "./voice-recorder/RecorderTheme";
import { useWavRecorder } from "./voice-recorder/useWavRecorder";
import { VoiceRecorderContext, VoiceRecorderContextProvider } from "./voice-recorder/VoiceRecorderContext";
import { green } from "@mui/material/colors";
import { IRecordedMedia } from "./voice-recorder/browser-api/WavRecorder_AudioCaptureBased";

export interface IObtainVoiceSampleProps {
    minSpeech_sec: number;
    timeout_sec: number;
    instructions: string;
    disabled: boolean;
    onObtained?: (media: Blob) => void;
};

function ObtainVoiceSample_Wrapped(props: IObtainVoiceSampleProps) {
    const theme: Theme = useTheme();

    const handleVoiceObtained = useCallback(async (media: IRecordedMedia) => {
        if (media.success) {
            props.onObtained(media.blob);
        }
    }, [props]);

    return (
        <Stack direction="column" sx={{ alignSelf: "center" }}>
            <VoiceRecorder
                size={VoiceRecorderSize.small}
                prompt={props.instructions}
                color={green[700]}
                themeId={RecorderThemeId.dark}
                timeoutSec={props.timeout_sec}
                minNetSpeechSec={props.minSpeech_sec}
                disabled={props.disabled}
                onVoiceObtained={handleVoiceObtained}
                />
        </Stack>
    );
}

export function ObtainVoiceSample(props: IObtainVoiceSampleProps): JSX.Element {
    const voiceRecorder = useWavRecorder();
    useContext(VoiceRecorderContext);
    return (
        <VoiceRecorderContextProvider voiceRecorder={voiceRecorder}>
            <ObtainVoiceSample_Wrapped {...props} />
        </VoiceRecorderContextProvider>
    );
}