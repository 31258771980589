import { Stack, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Done as SuccessIcon, Error as ErrorIcon, AvTimer as DurationIcon, Person as IdIcon, HourglassBottom as PendingIcon } from '@mui/icons-material';
import { red, green, amber } from "@mui/material/colors";
  
export interface IStep2Props {
    success: boolean;
    message: string;
    netSpeechDuration_sec: number;
    identifier: string;
    pending: boolean;
};
 
const icon_common = {
    width: "35px",
    height: "35px",
    alignSelf: "center"
};
 
const fail_icon_style = {    
    ...icon_common,
    color: red[500],
};

const success_icon_style = {
    ...icon_common,
    color: green[500],
};

const pending_icon_style = {
    ...icon_common,
    color: amber[500],
};

function StatusIcon(props: {success: boolean, pending: boolean}): JSX.Element {
    if (props.pending) return (
        <PendingIcon sx={pending_icon_style} />
    )
    else if (props.success) return (
        <SuccessIcon sx={success_icon_style} />
    )
    else return (
        <ErrorIcon sx={fail_icon_style} />
    );
}

export function Step2(props: IStep2Props): JSX.Element {
    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
                <StatusIcon {...props} />
                <Typography variant="h4" component="div" sx={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                    {props.pending ? "One moment..." : "Enrollment Results"}
                </Typography>
            </Stack>

            <List dense sx={{ width: "400px" }}>
                <ListItem>
                    <ListItemIcon>
                        <IdIcon />
                    </ListItemIcon>
                    <ListItemText primary="Enrolled as:" />
                    <Typography alignItems="right" sx={{ fontWeight: "bold" }}>
                        {!props.pending && props.success ? props.identifier : "..."}
                    </Typography>
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <DurationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Speech Length:" />
                    <Typography alignItems="right" sx={{ fontWeight: "bold" }}>
                        {!props.pending && props.success ? `${props.netSpeechDuration_sec.toFixed(1)} sec` : "..."}
                    </Typography>
                </ListItem>
            </List>

            <Typography>
                {props.message}
            </Typography>

        <div />
        </Stack>
    );
}