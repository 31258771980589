import { ModelBase, editor, ModelEditorType } from "omni-voice-react-shared";

export class CreateUserModel extends ModelBase {
    // TODO: add email editor
    @editor({
        index: -1,
        label: "Email",
        doc: "This is also username",
        required: true,
        type: ModelEditorType.Text,
        validationError: "",
    })
    username: string = "";

    @editor({
        index: 0,
        label: "Password",
        doc: "Must be 8 to 16 characters long",
        required: true,
        type: ModelEditorType.PasswordAndValidator,
        validationError: "",
    })
    password: string = "";

    @editor({
        index: 1,
        label: "User's Full Name",
        doc: "",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    fullName: string = "";

    @editor({
        index: 2,
        label: "Position",
        doc: "",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    position: string = "";

    @editor({
        index: 3,
        label: "Cell Phone Number",
        doc: "Users who do not have valid mobile phone numbers configured will not be able to log-in with voice",
        required: false,
        type: ModelEditorType.Phone,
        validationError: "Must contain digits only ('+' for phone numbers) and have the length of at least 8 characters",
    })
    phoneNumber: string = "";
    
    constructor(partial?: any) {
        super();
        if (partial) Object.assign(this, partial);
        for(const prop in this) {
            //console.log(prop, typeof this[prop], this[prop] === null);
            if (prop !== 'metadata' && this[prop] === null) {
                this[prop as string] = "";
            }
        }
    }
};