import React from 'react';
//import WavRecorder_MediaRecorderBased, { IRecordedMedia } from './browser-api/WavRecorder_MediaRecorderBased';
import WavRecorder_AudioCaptureBased,  { IRecordedMedia } from './browser-api/WavRecorder_AudioCaptureBased';
import WavVisualizer, { IWavVisualizerStyle } from './browser-api/WavVisualizer';

export interface IUseVoiceRecorderResult {
    recordedMedia: IRecordedMedia;
    isRecording: boolean;
    error: string;
    loaded: boolean;
    start: (duration_sec: number) => void;
    setDisabled: (value: boolean) => void;
    visualizeTo: (canvas: HTMLCanvasElement, theme: IWavVisualizerStyle) => void;
};

export function useWavRecorder(): IUseVoiceRecorderResult {
    const [recordedMedia, setRecordedMedia] = React.useState(null);
    const [isRecording, setIsRecording] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);

    const recorderRef = React.useRef<WavRecorder_AudioCaptureBased>(null);
    const timerRef = React.useRef(null);
    const mountedRef = React.useRef(true);

    const stopRecording = React.useCallback(async () => {
        if (recorderRef.current) {
            var recording: IRecordedMedia = await recorderRef.current.stop();
            if (mountedRef.current) {
                //console.log(`Recording stopped. Media: duration=${recording.durationSec} sec, size=${recording.blob.size}, type='${recording.blob.type}'`);
                setRecordedMedia(recording);
                setIsRecording(false);
            }
        }
    }, [recorderRef, mountedRef, setRecordedMedia, setIsRecording]);

    const recordVoice = React.useCallback((duration_sec: number) => {
        if (recorderRef.current !== null) {
            setRecordedMedia(null);
            setIsRecording(true);
            recorderRef.current.start();
            timerRef.current = setTimeout(async () => await stopRecording(), 1000*Math.round(duration_sec));
        }
    }, [setIsRecording, timerRef, recorderRef]);

    const visualizeTo = React.useCallback((canvas, theme) => {
        if (recorderRef.current !== null && loaded) {
            recorderRef.current.visualizeTo(canvas, theme);
        }
    }, [recorderRef, loaded]);

    const setDisabled = React.useCallback(val => {
        if (recorderRef.current?.visualizer) {
            var visualizer: WavVisualizer = recorderRef.current?.visualizer;
            visualizer.disabled = val;
        }
    }, [recorderRef.current]);

    // lifetime management
    React.useEffect(() => {
        recorderRef.current = new WavRecorder_AudioCaptureBased();
        recorderRef
            .current
            .initialize()
            .then(() => {
                if (mountedRef.current) {
                    if (recorderRef.current.hasError) {
                        setError(recorderRef.current.error);
                    }
                    setLoaded(true);
                }
            });

        return () => {
            stopRecording();

            if (recorderRef.current !== null) {
                recorderRef.current.dispose();
            }
            // update mounted state
            mountedRef.current = false;
            // abort pending recording, if any
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return {
        recordedMedia: recordedMedia as IRecordedMedia,
        isRecording: isRecording,
        error: error,
        loaded: loaded,
        start: recordVoice,
        setDisabled: setDisabled,
        visualizeTo: visualizeTo,
    } as IUseVoiceRecorderResult;
};