import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { RootRoutePaths } from './routes/RootRoutePaths';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import { useAuthState } from 'omni-voice-react-shared';
import { AccessModePanel } from './access-mode/AccessModePanel';
import { ReactComponent as Logo } from '../img/logo.svg';

function ViewLayout(props) {
    const { children, nav } = props;
    const authState = useAuthState();
    const navigate = useNavigate();

    //if (!authState.signedIn) return RoutePaths.Redirect(RoutePaths.SignIn);

    const nav_style = {
        flexGrow: 1,
        marginLeft: "15px",
        marginRight: "15px",
        alignSelf: "center",
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ marginBottom: "10px" }}>
                <Toolbar>
                    <Logo className="logo" />
                    <Typography variant="h6" component="div"> OMNI Voice </Typography>
                    <div style={nav_style}>{nav}</div>
                    <Button
                        color="inherit"
                        onClick={() => authState.signOut()}
                        sx={{ display: authState.signedIn ? "inline-flex" : "none" }}>
                        Sign-Out
                    </Button>
                    <Button
                        color="inherit"
                        onClick={() => navigate(RootRoutePaths.homeNav(RootRoutePaths.signedIn))}
                        sx={{ display: authState.signedIn ? "none" : "inline-flex" }}>
                        Sign-In
                    </Button>
                </Toolbar>
            </AppBar>
            <Paper elevation={4} sx={{ padding: "10px", m: "20px", display: authState.signedIn ? "block" : "none" }}>
                <AccessModePanel />
            </Paper>
            {children}
        </Box>
    );
};

export default ViewLayout;