import { editor } from "../../services/editable-model/decorator";
import { ModelBase } from "../../services/editable-model/ModelBase";
import { ModelEditorType } from "../../services/editable-model/interfaces";

export class EmailConfig extends ModelBase {
    @editor({
        index: 10,
        label: "SMTP Config",
        doc: "Expected format: username:password@localhost:port",
        required: true,
        type: ModelEditorType.Text,
        validationError: "",
    })
    SmtpConfig: string = "";

    @editor({
        index: 20,
        label: "Email 'from' name",
        doc: "Alias for the 'from' field",
        required: true,
        type: ModelEditorType.Text,
        validationError: "",
    })
    EmailFromName: string = "OMNI Voice";

    @editor({
        index: 30,
        label: "Email 'from' address",
        doc: "The email address placed in the 'from' field",
        required: true,
        type: ModelEditorType.Text,
        validationError: "",
    })
    EmailFromAddress: string = "";

    @editor({
        index: 40,
        label: "Enable SSL",
        doc: "",
        required: true,
        type: ModelEditorType.Bool,
        validationError: "",
    })
    EnableSSL: boolean = true;

    @editor({
        index: 50,
        label: "Admin email address",
        doc: "Internal email address to which secret information is to be sent",
        required: true,
        type: ModelEditorType.Email,
        validationError: "",
    })
    AdminEmailAddress: string = "dmitry.nikitin@mw-development.com";
    
    constructor(partial?: any) {
        super();
        if (partial) Object.assign(this, partial);
        for(const prop in this) {
            //console.log(prop, typeof this[prop], this[prop] === null);
            if (prop !== 'metadata' && this[prop] === null) {
                this[prop as string] = "";
            }
        }
    }
};