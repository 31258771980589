import { IOrg } from '../ApiInterfaces';
import { Rest, CrudApi, ICrudApiResult, IDataView } from 'omni-voice-react-shared';
//import { CrudApi } from '../CrudApi';

export default class OrgApi extends CrudApi<IOrg> {
    private constructor() { super(1000); }
    private static _instance = new OrgApi();

    protected async onGet(data_view: IDataView): Promise<ICrudApiResult<IOrg>> {
        const resp = await Rest.post<ICrudApiResult<IOrg>>("/organization/get", data_view);
        return this.processRestResponse(resp, e => e);
    }
    protected async onUpdate(entity: IOrg): Promise<ICrudApiResult<IOrg>> {
        const resp = await Rest.post<ICrudApiResult<IOrg>>("/organization/update", entity);
        return this.processRestResponse(resp, e => e);
    }
    protected async onDelete(entity_ids: string[]): Promise<ICrudApiResult<IOrg>> {
        if (entity_ids.length > 1) throw new Error("OrgApi supports the deletion of a single entry only");
        const resp = await Rest.get<ICrudApiResult<IOrg>>(`/organization/delete/${entity_ids[0]}`);
        return this.processRestResponse(resp, e => e);
    }
    protected onDeleteView(data_view: IDataView): Promise<ICrudApiResult<IOrg>> {
        throw new Error("Method not supported");
    }
    protected onDeleteAll(): Promise<ICrudApiResult<IOrg>> {
        throw new Error("Method not supported");
    }
    protected async onCreate(create_entity_dto: object): Promise<ICrudApiResult<IOrg>> {
        const resp = await Rest.post<ICrudApiResult<IOrg>>("/organization/create", create_entity_dto);
        return this.processRestResponse(resp, e => e);
    }

    async rescope(rescope_dto, data_view: IDataView): Promise<ICrudApiResult<IOrg>> {
        const resp = await Rest.post<ICrudApiResult<IOrg>>("/organization/rescope", rescope_dto);
        const crud_result = this.processRestResponse(resp, e => e);
        if (crud_result) {
            if (crud_result.total === 1) {
                // this is an update op so let's update the state replacing the updated entity in memory
                var model = crud_result.data[0];
                this.updateEntryInState(e => e.id === model.id, model);
            }

            // update error state
            this.updateErrorState(crud_result.hasError, crud_result.error);

            // propagate rescope error first
            if (crud_result.error) {
                return this.state();
            }

            // if the data view is the same, this will not cost another backend request
            return this.get(data_view);
        }
        else {
            // this is a fail op that will be handled as a backend error
            // so just return the state to avoid exceptions
            return this.state();
        }
    }

    static getInstance(): OrgApi { return OrgApi._instance; }
};