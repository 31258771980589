import { PropEditor } from "./PropEditor";

export class EditorMetadata {
    readonly model: Object;
    getPropEditors(): PropEditor[] {
        const all_keys = Object.getOwnPropertyNames(this);
        const result = all_keys
            .map(key => this[key])
            .filter(prop => prop instanceof PropEditor)
            .map(prop => prop as PropEditor)
            .sort((a,b) => a.definition.index - b.definition.index);
        
        //result.forEach(editor => console.log(`Editor for ${editor.propertyKey}=${editor.getPropValue()}`));

        return result;
    }
};