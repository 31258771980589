import OmniVoiceWallpaper from '../../img/biomtetrics-banner.png';

import { List } from '@mui/material';
import { Typography, Paper } from '@mui/material';

import { VpnKey as ApiKeysIcon, LiveHelpOutlined as DocsIcon,
    GraphicEq as MediaIcon, SpatialAudio as LiveStreamsIcon } from '@mui/icons-material';

import { AppId } from '../../services/hooks/useAppLogin';
import { OpenAppButton } from '../admin/manage-platform/OpenAppButton';
import { PanelItem } from './PanelItem';

export interface IMediaPanelProps {
    liveStreams?: number;
}

export function MediaPanel(props: IMediaPanelProps): JSX.Element {
    return <>
        <Paper className="card" elevation={10}>
            <div className="bkg">
                <MediaIcon className="rotated" style={{marginTop: "-30%"}} />
            </div>

            <Typography className="card-title" component="div" variant="h4">
                <OpenAppButton appId={AppId.Media}>Media</OpenAppButton>
            </Typography>

            <List className="root">
                <PanelItem
                    className="card-item"
                    icon={<LiveStreamsIcon />}>
                    <OpenAppButton
                        appId={AppId.Media}
                        justifyContent="flex-start">
                            Live Streams: {`${props.liveStreams ?? "--"}`}
                    </OpenAppButton>
                </PanelItem>

                <PanelItem
                    className="card-item"
                    icon={<ApiKeysIcon />}
                    subscript="Integrations Security">
                    <OpenAppButton
                        appId={AppId.Media}
                        path="manageAPIKeys"
                        justifyContent="flex-start">
                            API Keys
                    </OpenAppButton>
                </PanelItem>

                <PanelItem
                    className="card-item"
                    icon={<DocsIcon />}
                    subscript="API Docs & Tutorials">
                    <OpenAppButton
                        appId={AppId.Media}
                        path="docs"
                        justifyContent="flex-start">
                            Docs
                    </OpenAppButton>
                </PanelItem>

            </List>

            <Typography className="description">
                
            </Typography>
        </Paper>
    </>;
}