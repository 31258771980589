import * as React from 'react';

export interface IUseCountdown {
    secLeft: number;
    start: (timeout_sec: number) => void;
    stop: () => void;
};

export function useCountdown(): IUseCountdown {
    const [timeLeft, setTimeLeft] = React.useState(0);
    const timerRef = React.useRef(null);
    const handleTick = React.useCallback((time_left: number) => {
        setTimeLeft(time_left);
        if (time_left > 0) {
            timerRef.current = setTimeout(() => handleTick(time_left-1), 1000);
        }
        else timerRef.current = null;
        
    }, [setTimeLeft]);

    const start = React.useCallback((timeout_sec: number) => {
        const rounded = Math.round(timeout_sec);
        setTimeLeft(rounded);
        if (!rounded || rounded < 0) throw new Error("timeout_sec is undefined or invalid. It must be a positive integer");
        if (timerRef.current === null) {
            timerRef.current = setTimeout(() => handleTick(rounded-1), 1000);
        }
    }, [timerRef]);

    const stop = React.useCallback(() => {
        if (timerRef && timerRef.current)
            clearTimeout(timerRef.current);        
    }, [timerRef]);

    React.useEffect(() => { return stop; }, []);

    return {
        secLeft: timeLeft,
        start: start,
        stop: stop,
    }
}