import { Typography, Stack, Box, LinearProgress } from "@mui/material";
import { Centered } from "omni-voice-react-shared";

export function WaitForPhoneConfirmation() {
    return (
        <Centered>
            <Stack
                direction="column"
                spacing={3}
                sx={{
                    textAlign: "center",
                    border: "2px solid #F0F0F0",
                    borderRadius: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Box>
                    <Typography component="div" variant="h3" sx={{ margin: "80px 80px 20px" }}>
                        We have texted you a confirmation link. Please check your phone.
                    </Typography>
                </Box>
                <Box>
                    <LinearProgress sx={{ minWidth: "200px", marginBottom: "60px" }} />
                </Box>
            </Stack>
        </Centered>
    );
}