import { Scope, UserType } from "../../services/Scope";

const renderOrgScope = (row) => {
    const scope_fields = row.orgScope?.map(f => f.toLocaleLowerCase());
    var result = "(global)";

    if (scope_fields && scope_fields.length > 0) {
        result = `(${scope_fields.join('|')})`;
    }

    return result;
}

export const orgColumns = [
    { field: 'name', headerName: 'Name', width: 200, editable: false, },
    { field: 'reseller', headerName: 'Scope', width: 200, editable: false, renderCell: (params) => renderOrgScope(params.row) },
    { field: 'contactName', headerName: 'Contact Name', width: 200, editable: false, },
    { field: 'contactEmail', headerName: 'Email', width: 200, editable: false, },
    { field: 'contactPhone', headerName: 'Phone', width: 200, editable: false, },
    { field: 'website', headerName: 'Website', width: 200, editable: false, },
    { field: 'addressLine1', headerName: 'Address 1', width: 200, editable: false, },
    { field: 'addressLine2', headerName: 'Address 2', width: 200, editable: false, },
    { field: 'addressLine3', headerName: 'Address 3', width: 200, editable: false, },
    { field: 'postcode', headerName: 'Postcode', width: 200, editable: false, },
    { field: 'state', headerName: 'State', width: 200, editable: false, },
    { field: 'country', headerName: 'Country', width: 200, editable: false, },
];

const renderLocalities = (row) => {
    const localities = row.localities;
    //console.log(row);
    var result = "(empty)";
    if (localities && localities.length > 0) {
        result = `${localities.length} localities`;
    }
    return result;
};

export const scopeColumns = [
    { field: 'name', headerName: 'Name', width: 200, editable: false, },
    { field: 'localities', headerName: 'Localities', width: 200, editable: false, sortable: false, filterable: false, renderCell: (params) => renderLocalities(params.row) },
];

const renderScopes = (user) => {
    const user_type = Scope.userType(Scope.scopeDef(user));
    var result = "";
    switch(user_type) {
        case UserType.Global:
            result = "(global)";
            break;
        case UserType.Scoped:
            result = user.scope.name + " (scoped)";
            break;
        case UserType.Local:
            result = user.org.name + " (local)";
            break;
    }
    return result;
};

const renderOrg = (org) => {
    var result = "-";
    if (org) {
        if (org.name) result = org.name;
        else result = org.id;
    }
    return result;
}

export const userColumns = [
    { field: 'username', headerName: 'E-mail (username)', width: 200, editable: false, },
    { field: 'role', headerName: 'Role', width: 200, editable: false, sortable: false, filterable: false, renderCell: (params) => params.row.role.name },
    { field: 'scopes', headerName: 'Scope', width: 250, editable: false, filterable: false, renderCell: (params) => renderScopes(params.row) },
    { field: 'org', headerName: 'Org', width: 200, editable: false, filterable: false, renderCell: (params) => renderOrg(params.row.org) },
    { field: 'fullName', headerName: 'Full Name', width: 200, editable: false, },
    { field: 'position', headerName: 'Position', width: 200, editable: false, },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 200, editable: false, },
];