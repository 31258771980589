import { useCallback } from "react";

import { Lookup, ILookupProps } from "./Lookup";
import { IAppState, useAppState } from "../../services/app-state/useAppState";
import { AppState, AppStateEnum } from "../../services/app-state/AppState";
import { useEvent } from "./useEvent";

import { IOrg, IScope, IRoleDefinition } from "../../services/ApiInterfaces";
import OrgApi from "../../services/backend-apis/OrgApi";
import ScopeApi from "../../services/backend-apis/ScopeApi";

import { DataView } from "omni-voice-react-shared";

import { Grid, Paper, Dialog, Typography } from '@mui/material';
import { UserType } from "../../services/Scope";
import { PermissionType } from "../../services/PermissionType";

export interface IAccessModeSelectionDialogProps {
    onClose(): void;
};

export function AccessModeSelectionDialog(props: IAccessModeSelectionDialogProps): JSX.Element {
    const appState: IAppState = useAppState();
    const callerType = AppState.instance.callerType;
    const refetchOrgEvent = useEvent();

    const handleClose = useCallback(() => {
        if (!AppState.instance.effectiveRole) {
            // check effective role when closing dialog; if null, set to caller's role
            AppState.instance.effectiveRole = AppState.instance.caller.role;
        }
        if (props.onClose) props.onClose();
    }, [props]);

    const orgLookupProps: ILookupProps = {
        label: "Org",
        disabled: appState.state !== AppStateEnum.idle || callerType < UserType.Scoped ||
            !appState.hasPermission(PermissionType.Org_Read),
        search: async (term: string) => {
            const crud_res = await OrgApi.getInstance().get(DataView.searchByNamePattern(term, 20));
            var result = [];
            if (crud_res && !crud_res.hasError) {
                //console.log(`SEARCH ORGS '${term}':`, crud_res.total, 'records');
                result = crud_res.data;
            }
            return result;
        },
        value: appState.effectiveOrg,
        refetchEvent: refetchOrgEvent,
        onValueChange: (value) => AppState.instance.org = (value as IOrg),
    };

    const scopeLookupProps: ILookupProps = {
        label: "Scope",
        disabled: appState.state !== AppStateEnum.idle || callerType < UserType.Global ||
            !appState.hasPermission(PermissionType.Scope_Read),
        search: async (term: string) => {
            const crud_res = await ScopeApi.getInstance().get(DataView.searchByNamePattern(term, 20));
            var result = [];
            if (crud_res && !crud_res.hasError) {
                result = crud_res.data;
                //console.log(`SEARCH SCOPES '${term}':`, crud_res.total, 'records');
                //console.log(result);
            }
            return result;
        },
        value: appState.effectiveScope,
        onValueChange: (value) => {
            AppState.instance.scope = (value as IScope);
            AppState.instance.org = null;
            OrgApi.getInstance().invalidateState();
            refetchOrgEvent.raise(value);
        },
    };

    const roleLookupProps: ILookupProps = {
        label: "Role",
        disabled: appState.state !== AppStateEnum.idle || AppState.instance.caller.role.assignableRoles.length < 2,
        initialOptions: AppState.instance.caller.role.assignableRoles,
        value: appState.effectiveRole,
        onValueChange: (value) => AppState.instance.effectiveRole = (value as IRoleDefinition),
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <Paper elevation={4}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        gap: "20px",
                    }}>
                    <Typography variant="h4">Select Access Level</Typography>
                    <Typography color="text.secondary">Look up and select an org if you wish to assume "local" org access level.</Typography>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={6}><Lookup {...roleLookupProps} /></Grid>
                        <Grid item xs={12} sm={6}><Lookup {...scopeLookupProps} /></Grid>
                        <Grid item xs={12} sm={12}><Lookup {...orgLookupProps} /></Grid>
                    </Grid>
                </div>
            </Paper>
        </Dialog>
    );
};