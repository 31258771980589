import { FormEvent, useEffect } from "react";
import { useStateIfMounted, UtilityDialog } from "omni-voice-react-shared";
import { ILoginResult } from "../../services/ApiInterfaces";
import { AuthApi } from "../../services/backend-apis/AuthApi";
import { useAuthState } from 'omni-voice-react-shared';
import { useNavigate } from "react-router-dom";
import { RootRoutePaths } from "../routes/RootRoutePaths";

import { Stack, Button, TextField, Typography } from "@mui/material";
import { useCountdown } from "./voice-recorder/useCountdown";

type UtilityDialogProps = { title: string, desc: string, onClose: any, variant: string };

export interface ICredentialsLoginProps {
    onLoginError: (error: string) => void;
};

const field_style = { marginBottom: "10px", };

function Pending() {
    const checkPhoneCountdown = useCountdown();

    useEffect(() => {
        checkPhoneCountdown.start(3);
        return () => {
            checkPhoneCountdown.stop();
        }
    }, []);

    return (
        <Stack className={checkPhoneCountdown.secLeft > 0 && "pending-animation"} sx={{ textAlign: "center", marginTop: "40px" }}>
            <Typography component="div" variant="h5">
                {
                    checkPhoneCountdown.secLeft > 0
                        ? "Please wait..."
                        : "We have texted you a phone confirmation link. Please check your phone."
                }
            </Typography>
        </Stack>
    );
}

export function CredsLogin(props: ICredentialsLoginProps): JSX.Element {
    const [username, setUsername] = useStateIfMounted("");
    const [password, setPassword] = useStateIfMounted("");
    const [pending, setPending] = useStateIfMounted(false);
    const [dialogMessage, setDialogMessage] = useStateIfMounted(null);

    const authState = useAuthState();
    const navigate = useNavigate();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPending(true);
        const login_result: ILoginResult = await AuthApi.instance.login(username, password);
        setPending(false);

        if (login_result.success) {
            authState.signIn(login_result.token);
            navigate(RootRoutePaths.homeNav(RootRoutePaths.AppsHome));
        }
        else {
            setUsername("");
            setPassword("");
            props.onLoginError(login_result.error);
        }        
    }

    useEffect(() => {
        if (authState.signedIn) {
            navigate(RootRoutePaths.homeNav(RootRoutePaths.AppsHome));
        }
    }, [authState.signedIn]);

    const forgotPassword = async () => {
        setPending(true);
        const result = await AuthApi.instance.requestPasswordReset(username);
        setPending(false);
        if (result.success) {
            setDialogMessage("A password reset link has been sent to your email.");
        }
        else {
            if (props.onLoginError) {
                props.onLoginError(result.error);
            }
        }
    };

    return (
        <form onSubmit={onSubmit}>
            {pending
                ? <Pending />
                : <Stack>
                    <TextField
                        variant="outlined"
                        label="Email or phone"
                        type="text"
                        style={field_style}
                        onChange={e => setUsername(e.target.value)}
                        value={username} />

                    <TextField
                        variant="outlined"
                        label="Password"
                        type="password"
                        style={field_style}
                        onChange={e => setPassword(e.target.value)}
                        value={password} />

                    <Button children="Sign-in" type="submit" disabled={!(!pending && username && password)} />
                    <Button children="Forgot Password" onClick={() => forgotPassword()} disabled={pending || !username} />
                </Stack>
            }

            {dialogMessage && (
                <UtilityDialog
                    {...{
                        onClose: () => setDialogMessage(null),
                        variant: "info",
                        title: "Information",
                        desc: dialogMessage,
                    } as UtilityDialogProps} />
            )}
        </form>
    );
}