import { useAppState } from "../../services/app-state/useAppState";
import { useStateIfMounted } from "omni-voice-react-shared";
import { AccessModeSelectionDialog } from "./AccessModelSelectionDialog";

import { Skeleton, Chip } from '@mui/material';
import { Policy as ScopeIcon, Apartment as OrgIcon, AdminPanelSettings as RoleIcon } from '@mui/icons-material';
import { AppStateEnum } from "../../services/app-state/AppState";

const formatScope = (scope) => scope ? scope.name : "(global)";
const formatRole = (role) => role ? role.name : "(empty)";
const formatOrg = (org) => org ? org.name : "(not set)";

export function AccessModePanel(): JSX.Element {
    const appState = useAppState();
    const disabled = appState.state !== AppStateEnum.idle;
    const [lookup, setLookup] = useStateIfMounted(false);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
            }}>
            <Chip label={disabled ? (<Skeleton width={80} />) : formatRole(appState.effectiveRole)} sx={{ flexGrow: 0.5, maxWidth: "200px", minWidth: "110px" }} icon={<RoleIcon />} variant="outlined" onClick={() => setLookup(true)} disabled={disabled} />
            <Chip label={disabled ? (<Skeleton width={80} />) : formatScope(appState.effectiveScope)} sx={{ flexGrow: 0.5, maxWidth: "200px", minWidth: "110px" }} icon={<ScopeIcon />} variant="outlined" onClick={() => setLookup(true)} disabled={disabled} />
            <Chip label={disabled ? (<Skeleton width={80} />) : formatOrg(appState.effectiveOrg)} sx={{ flexGrow: 1, maxWidth: "250px", minWidth: "200px" }} icon={<OrgIcon />} variant="outlined" onClick={() => setLookup(true)} disabled={disabled} />
            {lookup && <AccessModeSelectionDialog onClose={() => setLookup(false)} /> }
       </div>
    );
}