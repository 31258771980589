import AudioCapture from "./AudioCapture";

export interface RGB {
    r: number;
    g: number;
    b: number;
};

export interface IIconPalette {
    icon: string;
    led: string;
    shadowIcon: string;
    shadowLed: string;
    background: string;
    backgroundHover: string;
};

export interface IColorAnimationInfo {
    color1: RGB;
    color2: RGB;
};

export interface IWavVisualizerStyle {
    enabled: IIconPalette;
    disabled: IIconPalette;
    led: IColorAnimationInfo;
    thickness: number;
};

interface IInternalPalette {
    icon: string;
    led: string;
    shadowIcon: string;
    shadowLed: string;
    background: string;
};

class NumberAnimation {
    private _values: { x0: number; x1: number };
    public animate(oscillator: number): number {
        const { x0, x1 } = this._values;
        const A = x1 - x0;
        const result = x0 + A*oscillator;
        return result;
    }
    constructor(vals: { x0: number; x1: number }) { this._values = vals; }
};

class ColorAnimation {
    private _startTime: number;
    private _rAnimation: NumberAnimation;
    private _gAnimation: NumberAnimation;
    private _bAnimation: NumberAnimation;
    private _freqHz: number;

    public getColor(): string {
        const anim_dur_sec = (new Date().getTime() - this._startTime)/1000;
        const oscillator = (Math.sin(2*Math.PI*this._freqHz*anim_dur_sec) + 1) / 2;

        const r = this._rAnimation.animate(oscillator);
        const g = this._gAnimation.animate(oscillator);
        const b = this._bAnimation.animate(oscillator);

        return `rgb(${r},${g},${b})`;
    }

    public reset(): void { this._startTime = new Date().getTime(); }

    constructor(freq_Hz: number, c1: RGB, c2: RGB) {
        this._freqHz = freq_Hz;
        this._rAnimation = new NumberAnimation({x0: c1.r, x1: c2.r});
        this._gAnimation = new NumberAnimation({x0: c1.g, x1: c2.g});
        this._bAnimation = new NumberAnimation({x0: c1.b, x1: c2.b});
        this.reset();
    }
};

class CoordsMapper {
    private _w: number;
    private _h: number;

    private _w0: number;
    private _h0: number;
    private _x0: number;
    private _y0: number;

    private _pad: number;
    private _aspect: number;

    private _calcRect() {
        const w0 = this._h * this._aspect;
        if (w0 <= this._w) {
            this._w0 = w0;
            this._h0 = this._h;
        }
        else {
            this._w0 = this._w;
            this._h0 = this._w / this._aspect;
        }

        this._x0 = (this._w - this._w0) / 2;
        this._y0 = (this._h - this._h0) / 2;
    }

    public get singular(): boolean { return this._w === 0 || this._h === 0; }
    public get x0(): number { return this._x0 + this._pad; }
    public get y0(): number { return this._y0 + this._pad; }
    public get w0(): number { return this._w0 - this._pad*2; }
    public get h0(): number { return this._h0 - this._pad*2; }
    public get w(): number { return this._w; }
    public get h(): number { return this._h; }
    public get bottom(): number { return this.y0 + this.h0; }
    public get right(): number { return this.x0 + this.w0; }

    public toString() {
        return `Canvas Size: {w=${this._w}, h=${this._h}}, Render Rect: {x=${this._x0}, y=${this._y0}, w=${this._w0}, h=${this._h0}}, Aspect=${this._aspect}`;
    }

    /**
     * Maps the unit square of the first quadrant of a standard descartes system (CCW) to the
     * canvas area defined by the screen coords (CW). I am doing it manually so I do not need to
     * modify the transform state of the underlying canvas.
     */
    public map(x_norm: number, y_norm: number): {x: number; y: number} {
        const x_scaled = x_norm * this.w0;
        const y_scaled = y_norm * this.h0;
        const y_flipped = this.h0 - y_scaled;
        const x_translated = x_scaled + this.x0;
        const y_translated = y_flipped + this.y0;
        return {
            x: x_translated,
            y: y_translated,
        };
    }

    public normalizeY(y_px: number): number { return y_px / this.h0; }
    public normalizeX(x_px: number): number { return x_px / this.w0; }

    constructor(canvas, padding: number = 10, aspect: number = 60/90) {
        this._aspect = aspect;
        this._w = canvas.width;
        this._h = canvas.height;
        this._pad = padding;
        this._calcRect();
    }
};

export default class WavVisualizer {
    // api stuff
    private _capture: AudioCapture;
    private _source;
    private _analyser: AnalyserNode;
    private _buffer;
    private _bufferLen;
    private _canvasContext: CanvasRenderingContext2D;
    private _canvas: HTMLCanvasElement;

    // animation fixture
    private _animation: ColorAnimation;

    // state
    private _disposed: boolean;
    private _disabled: boolean = false;
    private _hover: boolean = false;

    // theming
    private _theme: IWavVisualizerStyle;
    private _thickness: number;

    private _shadow(size: number = 10, color: string = "rgba(0,0,0,0.3)") {
        this._canvasContext.shadowColor = color;
        this._canvasContext.shadowOffsetX = 0;
        this._canvasContext.shadowOffsetY = 0;
        this._canvasContext.shadowBlur = size;
    }

    private _render_mic(draw: CanvasRenderingContext2D, coords: CoordsMapper) {
        const palette = this.palette;

        // settings
        const stroke_width_stand = coords.w0 * 0.15 * this._thickness;
        const stroke_width_mic = coords.w0 * 0.1 * this._thickness;
        const mic_stroke_normX = coords.normalizeX(stroke_width_mic);
        const mic_stroke_normY = coords.normalizeY(stroke_width_mic);
        const stand_radius_px = coords.w0 / 2;
        const mic_radius_px = 0.6 * coords.w0 / 2;
        const stand_radius_normX = coords.normalizeX(stand_radius_px);
        const stand_radius_normY = coords.normalizeY(stand_radius_px);
        const mic_radius_normY = coords.normalizeY(mic_radius_px);
        const mic_radius_normX = coords.normalizeX(mic_radius_px);

        this._shadow(10, palette.shadowIcon);

        // draw mic stand
        const p0 = coords.map(0.5, 0);
        const p1 = coords.map(0.5, 0.18);
        const arc_center_bot = coords.map(0.5, stand_radius_normY + 0.18); // w0 is the diameter of the arc
        const arc_start = coords.map(0.5 + stand_radius_normX, stand_radius_normY + 0.18);

        draw.lineWidth = stroke_width_stand;
        draw.strokeStyle = palette.icon;
        draw.beginPath();
        draw.lineCap = "square";
        draw.moveTo(p0.x, p0.y);
        draw.lineTo(p1.x, p1.y);
        draw.moveTo(arc_start.x, arc_start.y);
        draw.arc(arc_center_bot.x, arc_center_bot.y, stand_radius_px, 0, -Math.PI);
        draw.stroke();

        this._shadow(0);

        // draw mic path
        const mic_arc_start_bot = coords.map(0.5 + mic_radius_normX, stand_radius_normY + 0.18);
        const mic_arc_start_top = coords.map(0.5 - mic_radius_normX, 1 - mic_radius_normY);
        const arc_center_top = coords.map(0.5, 1 - mic_radius_normY);

        function drawMicPath() {
            draw.beginPath();
            draw.moveTo(mic_arc_start_bot.x, mic_arc_start_bot.y);
            draw.arc(arc_center_bot.x, arc_center_bot.y, mic_radius_px, 0, -Math.PI);
            draw.lineTo(mic_arc_start_top.x, mic_arc_start_top.y);
            draw.arc(arc_center_top.x, arc_center_top.y, mic_radius_px, -Math.PI, 0);
            draw.lineTo(mic_arc_start_bot.x, mic_arc_start_bot.y);
        }

        // fill the whole thing first
        draw.lineWidth = stroke_width_mic;
        draw.fillStyle = palette.icon;
        drawMicPath();
        draw.fill();

        // clear the top based on the signal power
        const top_left = coords.map(0.5 - mic_radius_normX - mic_stroke_normX, 1);
        const mic_rect_bot_norm = stand_radius_normY + 0.18 - mic_stroke_normY*2;
        const mic_rect_height_norm = 1 - mic_rect_bot_norm;
        const right_bot = coords.map(
            0.5 + mic_radius_normX + mic_stroke_normX,
            mic_rect_bot_norm + mic_rect_height_norm * this.signalPowNorm
        );

        draw.fillStyle = palette.background;
        draw.fillRect(top_left.x, top_left.y, right_bot.x-top_left.x, right_bot.y-top_left.y);

        // now just draw the mic path outline
        this._shadow(10, palette.shadowIcon);
        drawMicPath();
        draw.stroke();
    }

    private _render_led(draw: CanvasRenderingContext2D, coords: CoordsMapper) {
        const palette = this.palette;

        // settings
        const stand_radius_px = coords.w0 / 2;
        const mic_radius_px = 0.6 * coords.w0 / 2;
        const stand_radius_normY = coords.normalizeY(stand_radius_px);
        const arc_center_bot = coords.map(0.5, stand_radius_normY + 0.18); // w0 is the diameter of the arc

        // now draw recording animation
        const rec_LED_radius_px = mic_radius_px * 0.7;

        draw.lineWidth = 0;
        draw.fillStyle = palette.led;

        this._shadow(4, palette.shadowLed);

        draw.beginPath();
        draw.arc(arc_center_bot.x, arc_center_bot.y, rec_LED_radius_px, Math.PI, -Math.PI);
        draw.fill();
    }

    private _render(): void {
        if (!this.disposed)
            window.requestAnimationFrame(this._render.bind(this));

        const draw = this._canvasContext;
        const coords = new CoordsMapper(this._canvas);

        // fill background, set stroke color
        draw.fillStyle = this.palette.background;
        draw.fillRect(0, 0, coords.w, coords.h);

        this._render_mic(draw, coords);
        this._render_led(draw, coords);
    }

    public resetAnimation(): void { this._animation.reset(); }

    public get signalPowNorm(): number {
        var result = 0;
        if (this._analyser) {
            this._analyser.getByteFrequencyData(this._buffer);
            const max = this._buffer.reduce((s1,s2) => Math.max(s1, s2), 0);
            result = Math.min(max / 256, 1);
        }
        return result;
    }
    public get thickness(): number { return this._thickness }
    public set thickness(val: number) { this._thickness = val; }
    public get animation(): ColorAnimation { return this._animation; }
    public get hover(): boolean { return this._hover; }
    public get disabled(): boolean { return this._disabled; }
    public set disabled(value: boolean) { this._disabled = value; }
    public get theme(): IWavVisualizerStyle { return this._theme; }
    public get disposed(): boolean { return this._disposed; }
    public get palette(): IInternalPalette {
        var interactive = !(this.disabled || this._capture.recording);
        var active_color_scheme = interactive ? this.theme.enabled : this.theme.disabled;
        var led = (this._capture && this._capture.recording) ? this.animation.getColor() : active_color_scheme.led;
        var bkg = (interactive && this.hover) ? active_color_scheme.backgroundHover : active_color_scheme.background;
        return {
            ...active_color_scheme,
            background: bkg,
            led: led,
        };
    }

    public dispose(): void {
        this._source?.disconnect(this._analyser);
        this._capture?.audioContext.resume();
        this._disposed = true;
    }

    constructor(capture: AudioCapture, canvas: HTMLCanvasElement, theme: IWavVisualizerStyle = null) {
        if (!canvas) throw new Error("canvas is undefined or null");
        if (!theme) throw new Error("theme is null or undefined; if unsure, you can create a default theme using RecorderTheme class");

        this._theme = theme;
        this._thickness = theme ? theme.thickness : 1;
        this._animation = new ColorAnimation(1, theme.led.color1, theme.led.color2);
        this._disposed = false;
        this._disabled = false;
        this._canvas = canvas;
        this._canvasContext = canvas.getContext("2d");

        try {
            this._capture = capture;
            this._source = capture.audioContext.createMediaStreamSource(capture.stream);
            this._analyser = capture.audioContext.createAnalyser();
            this._analyser.fftSize = 32;
            this._bufferLen = this._analyser.frequencyBinCount;
            this._buffer = new Uint8Array(this._bufferLen);
            this._source.connect(this._analyser);

            capture.audioContext.resume();
        }
        catch {
            this._disabled = true;
        }

        this._canvas.onmouseover = () => this._hover = true;
        this._canvas.onmouseout = () => this._hover = false;

        window.requestAnimationFrame(this._render.bind(this));
    }
};