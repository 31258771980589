import { useState } from "react";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { AuthApi } from "../../services/backend-apis/AuthApi";
import { useSearchParams } from "react-router-dom";
import { Centered } from "omni-voice-react-shared";
import { Dialog, DialogActions, DialogContent, DialogTitle,
    Grow, Button, Stack, GrowProps } from '@mui/material';
import React from "react";
import {Mic as EnrollIcon} from '@mui/icons-material'

enum EnrollmentStepEnum { step1, step2 };
const Transition_Grow = React.forwardRef<JSX.Element, GrowProps>(function Transition(props, ref) {
    return (<Grow
        in={true}
        ref={ref}
        {...props}
        {...{ timeout: 500 }}
        />);
});

export function EnrollDialog(props): JSX.Element {
    const { onClose, model } = props;
    if (model === null) throw new Error("model === null");
    if (!model.phoneNumber) throw new Error("Phone Number MUST be specified to enroll");
    const [step, setStep] = useState<EnrollmentStepEnum>(EnrollmentStepEnum.step1);
    const [success, setSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [identifier, setIdentifier] = useState<string>(null);
    const [netSpeechDur, setNetSpeechDur] = useState<number>(-1);
    const [searchParams] = useSearchParams();
    
    const handleStep1Complete = async (media: Blob) => {
        setStep(EnrollmentStepEnum.step2);
        if (media) {
            setMessage("Check your phone for a confirmation link");
            setPending(true);
            const enroll_result = await AuthApi.enroll(media, model.phoneNumber, searchParams.get("confirmation") ?? "");
            setPending(false);
            setSuccess(enroll_result.success);
            if (!enroll_result.success) {
                setMessage(enroll_result.error);
            }
            else {
                setIdentifier(enroll_result.identifier);
                setNetSpeechDur(enroll_result.netSpeechDuration_sec);
                setMessage("Enrollment Successful");
            }
        }
    }

    const handleClose = React.useCallback((apply: boolean) => {
        if (onClose) {
                onClose(null);
        }
    }, [onClose]);

    return (
        <Dialog
            TransitionComponent={Transition_Grow}
            open={true}
            onClose={() => handleClose(false)}>
            <DialogTitle>
                <Stack direction="row" spacing={1}>
                    <Centered>
                        <EnrollIcon />
                    </Centered>
                    <span>Enroll Voiceprint for {model.fullName} ({model.phoneNumber})</span>
                </Stack>
            </DialogTitle>

            <DialogContent>
                {step === EnrollmentStepEnum.step1 && <Step1 phoneNumber={model.phoneNumber} onSubmit={handleStep1Complete} />}
                {step === EnrollmentStepEnum.step2 && <Step2 identifier={identifier} message={message} success={success} pending={pending} netSpeechDuration_sec={netSpeechDur} />}
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={()=>handleClose(false)}>CLOSE</Button>
            </DialogActions>
        </Dialog>
    );
}