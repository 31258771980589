import { ModelBase, editor, ModelEditorType } from "omni-voice-react-shared";

// export interface IPlatformConfig {
//     frontendUrl: string;
//     backendUrl: string;
//     siteName: string;
//     logoUrl: string;
//     biometricsPvtKey: string;
//     biometricsPubKey: string;
//     useStrongVoiceLoginStrategy: boolean;
// };

export class PlatformConfig extends ModelBase {
    @editor({
        index: 10,
        label: "Front-end URL",
        doc: "This would be the public platform's FE URL that is replicated to the apps so they can redirect back to platform (e.g. re-login)",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    frontendUrl: string = "";

    @editor({
        index: 20,
        label: "Backend-end URL",
        doc: "This would be the public platform's BE URL that is replicated to the apps so they can interact with the platform's back-end",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    backendUrl: string;

    @editor({
        index: 30,
        label: "Site Name",
        doc: "FUTURE FEATURE: Branding functionality for Enterprise customers / white-labelled partners",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    siteName: string;

    @editor({
        index: 30,
        label: "Logo URL",
        doc: "FUTURE FEATURE: Branding functionality for Enterprise customers / white-labelled partners",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    logoUrl: string;

    @editor({
        index: 40,
        label: "Voice Login - Private Key",
        doc: "Must be set to enable the enrollment of platform users to Voice Biometrics. The key is generated automatically by the Biometrics App. Log in to Biometrics under the 'OMNI Voice' built-in org to obtain the key",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    biometricsPvtKey: string;

    @editor({
        index: 50,
        label: "Voice Login - Publishable Key",
        doc: "Must be set to enable voice login of the platform users. The key is generated automatically by the Biometrics App. Log in to Biometrics under the 'OMNI Voice' built-in org to obtain the key",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    biometricsPubKey: string;

    @editor({
        index: 60,
        label: "Voice Login - 2-Factor Authentication",
        doc: "If set, uses the 'Strong' voice log-in strategy; otherwise, uses the 'Weak' strategy that is more user-friendly but less secure",
        required: false,
        type: ModelEditorType.Bool,
        validationError: "",
    })
    useStrongVoiceLoginStrategy: boolean;
    
    constructor(partial?: any) {
        super();
        if (partial) Object.assign(this, partial);
        for(const prop in this) {
            //console.log(prop, typeof this[prop], this[prop] === null);
            if (prop !== 'metadata' && this[prop] === null) {
                this[prop as string] = "";
            }
        }
    }
};