import MakeBasicCard from '../MakeBasicCard';
import ViewLayout from '../ViewLayout';

import { RootRoutePaths } from '../routes/RootRoutePaths';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

import { ErrorOutline as ErrorIcon } from '@mui/icons-material';
import { Home as HomeIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    cardsLayout: {
        marginTop: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        justifyContent: "center",
    }
  }));

export function BackendError() {
    const classes = useStyles();
    const [params] = useSearchParams();
    const error = params.get("error");
    const details = params.get("details");
    const navigate = useNavigate();

    const Nav = () => (
        <Grid container direction="row" spacing={3}>
            <Grid item>
                <IconButton onClick={() => navigate(RootRoutePaths.homeNav(RootRoutePaths.AppsHome))}>
                    <HomeIcon style={{ color: "white" }} />
                </IconButton>
            </Grid>
        </Grid>
    );

    return (<>
        <ViewLayout nav={<Nav />}>
            <Grid container direction="row" className={classes.cardsLayout}>
                <MakeBasicCard
                    title={error}
                    desc={
                    <Typography component="span">Sorry but we were unable to fetch data from the backend. Hopefully, this is a temporary issue so please try to refresh the page. If the problem persists, please contact our support by clicking <a href="https://omniintelligence.online/support">this link</a>.</Typography>}>
                    {details&&details!='undefined'&&(
                        <Typography component="span">{details}</Typography>
                    )}
                    <ErrorIcon style = {{ color: "#F00060", width: "100%", height: "100%" }} />
                </MakeBasicCard>
            </Grid>
        </ViewLayout>
    </>);
};