import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle,
    Grow, Button, Stack, GrowProps, Select, InputLabel, MenuItem, FormControl, FormHelperText } from '@mui/material';
import { Centered, useStateIfMounted } from 'omni-voice-react-shared';
import { AdminPanelSettings as ChangeRoleIcon } from '@mui/icons-material';
import { AppState } from '../../../services/app-state/AppState';

const Transition_Grow = React.forwardRef<JSX.Element, GrowProps>(function Transition(props, ref) {
    return (<Grow
        in={true}
        ref={ref}
        {...props}
        {...{ timeout: 500 }}
        />);
});

export function ReroleDialog(props) {
    const { onClose, model } = props;
    if (model === null) throw new Error("model === null");

    const assignableRoles = React.useMemo(() => {
        const caller = AppState.instance.caller;
        return caller.role.assignableRoles;
    }, []);

    const currentRole = React.useMemo(() => {
        const curr_role = assignableRoles.filter(r => r.name === model.role.name)[0];
        return curr_role;
    }, [model]);

    const [selectedRole, setSelectedRole] = useStateIfMounted(currentRole);

    const handleClose = React.useCallback((apply: boolean) => {
        if (onClose) {
            if (apply) {
                onClose(selectedRole);
            }
            else {
                onClose(null);
            }
        }
    }, [onClose, selectedRole]);

    const handleChange = React.useCallback((event) => {
        setSelectedRole(event.target.value);
    }, [setSelectedRole]);

    return (
        <Dialog
            TransitionComponent={Transition_Grow}
            open={true}
            onClose={() => handleClose(false)}>
            <DialogTitle>
                <Stack direction="row" spacing={1}>
                    <Centered>
                        <ChangeRoleIcon />
                    </Centered>
                    <span>Select Role</span>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="rerole-dlg-label">Role</InputLabel>
                    <Select
                        labelId="rerole-dlg-label"
                        value={selectedRole}
                        label="Role"
                        onChange={handleChange}>
                        {assignableRoles.map((roleDef, key) => <MenuItem { ...props } key={key} value={roleDef}>{roleDef.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>Use the drop-down to select a role that you wish to assign to user <em>'{model.fullName}'</em></FormHelperText>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={()=>handleClose(false)}>CANCEL</Button>
                <Button
                    disabled={!selectedRole}
                    variant="contained"
                    onClick={()=>handleClose(true)}
                    autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}