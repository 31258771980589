import { IDashboardData } from '../ApiInterfaces';
import { DataView } from 'omni-voice-react-shared';
import { AppState } from '../app-state/AppState';

import OrgApi from './OrgApi';
import UserApi from './UserApi';

export default class DashboardApi {
    static isAdminAvailable(): boolean {
        // for the moment, if the role isn't none, we will display the admin
        // this can be reviewed at a later stage when the usability of the UI
        // is evaluated
        var selected_role = AppState.instance.effectiveRole;
        if (!selected_role) {
            selected_role = AppState.instance.caller.role;
        }
        return selected_role?.name.toLocaleLowerCase() !== 'none' && selected_role?.name.toLocaleLowerCase() !== 'local user';
    }

    async queryDashboardData(): Promise<IDashboardData> {
        if (DashboardApi.isAdminAvailable()) {
            const org_api = OrgApi.getInstance();
            const user_api = UserApi.getInstance();

            const data_view = DataView.star(200, 0);
            const org_resp = await org_api.get(data_view);
            if (org_resp.hasError) return {
                error: org_resp.error,
                orgCount: 0,
                userCount: 0
            };
            const usr_resp = await user_api.get(data_view);
            if (usr_resp.hasError) return {
                error: usr_resp.error,
                orgCount: org_resp.total,
                userCount: 0,
            }
            return {
                error: "",
                orgCount: org_resp.total,
                userCount: usr_resp.total,
            }
        }
        else return {
            orgCount: 0,
            userCount: 0,
            error: "Access Denied"
        }
    }
};