import { useEffect, useCallback } from 'react';

import { userColumns } from '../TableSchemas';
import { CreateUserModel } from '../../../services/crud-models/CreateUserModel';
import { EditUserModel } from '../../../services/crud-models/EditUserModel';
import { WrapsDataGrid, makeCrudButton, useDataGridState,
    EditorForm, useEditorState, UtilityDialog, ICrudApiResult, IEditorStateControl } from 'omni-voice-react-shared';
import { VerticalToolbar } from 'omni-voice-react-shared';

import { amber, brown, green, red, purple } from '@mui/material/colors';

import UserApi from '../../../services/backend-apis/UserApi';
import { PermissionType } from '../../../services/PermissionType';
import { IUser } from '../../../services/ApiInterfaces';
import { useStateIfMounted } from 'omni-voice-react-shared';

import { RescopeDialog } from '../RescopeDialog';
import { ReroleDialog } from './ReroleDialog';
import { useAppState } from '../../../services/app-state/useAppState';

// icons
import { AddCircle as CreateIcon, DeleteSweep as DeleteIcon, Mic as EnrollIcon,
    Policy as RescopeIcon, Edit as EditIcon, AdminPanelSettings as ChangeRoleIcon } from '@mui/icons-material';
import { AppStateEnum } from '../../../services/app-state/AppState';
import { EnrollDialog } from '../../enrollment/Enroll';


const ManageUsers = (props) => {
    const [errorMessage, setErrorMessage] = useStateIfMounted(null);
    const [pending, setPending] = useStateIfMounted(false);
    const [checkSMS, setCheckSMS] = useStateIfMounted(false);
    const appState = useAppState();
    const loaded = appState.state === AppStateEnum.idle;

    const processCrudResult = useCallback((result: ICrudApiResult<IUser>) => {
        setPending(false);
        if (result) {
            if (result.hasError) {
                setErrorMessage(result.error);
            }
            else {
                dataGridState.updateEntries(result.data, result.total);
            }
        }
        else {
            if (checkSMS) setErrorMessage("SMS verification time-out. Please ensure that you have a phone number associated with your user account");
            else setErrorMessage("System error occurred");
        }
        setCheckSMS(false);
    }, [setPending, setErrorMessage, checkSMS, setCheckSMS]);

    // Editor / dialogs management
    const createCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.User_Create),
        apply: async model => {
            setPending(true);
            setCheckSMS(true);
            const result: ICrudApiResult<IUser> = await UserApi.getInstance().create(model, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: true,
    } as IEditorStateControl;

    const editCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.User_Update),
        apply: async model => {
            setPending(true);
            setCheckSMS(true);
            const result: ICrudApiResult<IUser> = await UserApi.getInstance().update(model, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const rescopeCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.User_Rescope),
        apply: async model => {
            const dto = {
                userId: rescopeState.model.id,
                newScope: model,
            }
            setPending(true);
            const result: ICrudApiResult<IUser> = await UserApi.getInstance().rescope(dto, dataGridState.dataView);
            //console.log("RESCOPE RESULT", result);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const reroleCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.User_ChangeRole),
        apply: async model => {
            //console.log("rescope", model);
            const dto = {
                UserId: reroleState.model.id,
                role: model.name,
            };
            setPending(true);
            const result: ICrudApiResult<IUser> = await UserApi.getInstance().rerole(dto, dataGridState.dataView);
            //console.log("RESCOPE RESULT", result);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const deleteCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.User_Delete),
        apply: async model => {
            setPending(true);
            const result: ICrudApiResult<IUser> = await UserApi.getInstance().delete([model.id], dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const enrollCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.User_Read),
        apply: () => {},
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const createState = useEditorState(createCtrl);
    const editState = useEditorState(editCtrl);
    const rescopeState = useEditorState(rescopeCtrl);
    const reroleState = useEditorState(reroleCtrl);
    const deleteState = useEditorState(deleteCtrl);
    const enrollState = useEditorState(enrollCtrl);

    // Data Grid controls && state
    const dataGridControl = {
        pageSizeOptions: [10, 50, 100],
        populate: async data_view => {
            const view_res = await UserApi.getInstance().get(data_view);
            //console.log("POPULATE", view_res);
            return view_res;
        },
        handleEntrySelected: entry => {
            buttonsDef.forEach(def => def.state.selectModel(entry));
        },
    };

    // DataGrid management
    const dataGridState = useDataGridState(dataGridControl);
    //dataGridState.updateSort([{ field: 'username', sort: 'asc'}])

    useEffect(() => {
        if (loaded) {
            
            buttonsDef.forEach(def => def.state.invalidate());
            UserApi.getInstance().invalidateState();
            dataGridState.refresh();
        }
    }, [loaded, appState.effectiveRole, appState.effectiveScope, appState.effectiveOrg]);

    // toolbar buttons def and render
    const buttonsDef = [
        {icon: <CreateIcon />, tooltip: "Create New", state: createState, color: green[700]},
        {icon: <EditIcon />, tooltip: "Edit Selected", state: editState, color: brown[500]},
        {icon: <RescopeIcon />, tooltip: "Rescope Selected", state: rescopeState, color: amber[700]},
        {icon: <ChangeRoleIcon />, tooltip: "Change Role", state: reroleState, color: purple[700]},
        {icon: <DeleteIcon />, tooltip: "Delete Selected", state: deleteState, color: red[700]},
        {icon: <EnrollIcon />, tooltip: "Enroll Voiceprint", state: enrollState, color: red[700]},
    ];

    var toolbarButtons = buttonsDef
        .filter(def => def.state.isAvailable)
        //editor_state, icon, caption, minimized=false
        .map((def, key) => makeCrudButton(def.state, def.icon, def.tooltip, key, true, def.color));
    
    useEffect(() => {
        if (dataGridState.error) {
            setErrorMessage(dataGridState.error);
        }
    }, [dataGridState.error]);

    useEffect(() => {
        buttonsDef.forEach(def => def.state.disablePermanently(pending || dataGridState.pendingOp));
    }, [pending, dataGridState.pendingOp, buttonsDef]);

    const Toolbar = () => <VerticalToolbar {...props}>{toolbarButtons}</VerticalToolbar>;

    const Error = () => {
        return <>
            {errorMessage && (
                <UtilityDialog
                    {...props}
                    variant="error"
                    title="Error"
                    desc={errorMessage}
                    onClose={() => setErrorMessage(null)}/>
            )}
        </>
    };

    const Dialogs = () => {
        return <>
            {deleteState.isOpen && (
                <UtilityDialog
                    {...props}
                    variant="confirmation"
                    title="Confirm User Deletion"
                    desc="The operation is final and cannot be undone. Please confirm you wish to proceed"
                    onClose={ok => ok ? deleteState.apply(deleteState.model) : deleteState.cancel(deleteState.model)}
                    />
            )}

            {checkSMS && (
                <UtilityDialog
                    {...props}
                    variant="info"
                    title="Check Your Phone"
                    desc="A verification link has been sent to your phone. Please click on the link to confirm the operation"
                    onClose={ok => dataGridState.refresh()}
                    />
            )}

            {createState.isOpen && (
                <EditorForm
                    title={"Create User"}
                    description={`Fields marked with (*) are required.`}
                    model={new CreateUserModel()}
                    onClose={v => v ? createState.apply(v) : createState.cancel(v)}
                    />
            )}
        
            {editState.isOpen && (
                <EditorForm
                    title="Edit User"
                    description={`Fields marked with (*) are required.`}
                    model={new EditUserModel(editState.model)}
                    onClose={v => v ? editState.apply(v) : editState.cancel(v)}
                    />
            )}

            {rescopeState.isOpen && (
                <RescopeDialog
                    title="Rescope User"
                    model={rescopeState.model}
                    onClose={v => v ? rescopeState.apply(v) : rescopeState.cancel(v)}
                    allowLocal={true}
                    />
            )}

            {reroleState.isOpen && (
                <ReroleDialog
                    model={reroleState.model}
                    onClose={v => v ? reroleState.apply(v) : reroleState.cancel(v)}
                    />
            )}

            {enrollState.isOpen && (
                <EnrollDialog
                    model={enrollState.model}
                    onClose={v => v ? enrollState.apply(v) : enrollState.cancel(v)}
                    />
            )}
        </>;
    };

    const styles = {
        container: {
            display: "flex",
            width: "100%",
            flexFlow: "row",
            border: '0px solid green',
            p: "15px",
            alignItems: "stretch",
        },

        grid: {
            flex: 1,
            overflow: "auto",
            marginRight: "40px",
        },
    };

    return (
        <div style={styles.container}>
            <Toolbar />
            <div style={styles.grid}>
                <WrapsDataGrid
                    sx={null}
                    gridState={dataGridState}
                    columnSchema={userColumns}
                    loading={pending}
                    />
            </div>
            <Dialogs />
            <Error />
        </div>
    );
};

export default ManageUsers;