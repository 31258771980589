import { createContext } from 'react';
import DashboardApi from '../../services/backend-apis/DashboardApi';

const DashboardContext = createContext(null);
const api = new DashboardApi();

const DashboardContextProvider = ({ children }) => {
    return (
        <DashboardContext.Provider value={api}>
            {children}
        </DashboardContext.Provider>
    );
};

export { DashboardContext, DashboardContextProvider };