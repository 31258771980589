import { ProtectedRoute } from './ProtectedRoute';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from '../login/LoginPage';
import { RegistrationForm } from '../register/RegistrationForm';
import { BackendError } from '../backend-error/BackendError';
import { RootRoutePaths } from './RootRoutePaths';
import { ResetPassword } from '../password-reset/ResetPassword';

import Dashboard from '../dashboard/Dashboard';

import AdminView from '../admin/AdminView';

export function RootAppRouter() {
    return (
        <Routes>
            <Route path={RootRoutePaths.AppsHome}>
                <Route index element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path={RootRoutePaths.SignIn} element={<LoginPage />} />
                <Route path={RootRoutePaths.Register} element={<RegistrationForm />} />
                <Route path={RootRoutePaths.ResetPassword}>
                    <Route path=":confirmationId" element={<ResetPassword />} />
                </Route>
                <Route path={RootRoutePaths.BackendError} element={<BackendError />} />
                <Route path={RootRoutePaths.AppsAdmin}>
                    <Route index element={<Navigate to={RootRoutePaths.ManageOrgs} />} />
                    <Route path=":route" element={<ProtectedRoute><AdminView /></ProtectedRoute>} />
                </Route>
                <Route path="*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            </Route>
        </Routes>
    );
};