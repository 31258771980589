import { useEffect, useCallback, useContext } from 'react';
import { useStateIfMounted } from 'omni-voice-react-shared';
import { Typography, Grid } from '@mui/material';
import { useAppState } from '../../services/app-state/useAppState';
import { AppState, AppStateEnum } from '../../services/app-state/AppState';

import { BiometricsPanel } from './BiometricsPanel';
import { BillingPanel } from './BillingPanel';
import { AdminPanel } from './AdminPanel';

import ManagePlatformApi from '../../services/backend-apis/ManagePlatformApi';
import ViewLayout from '../ViewLayout';
import "./Dashboard.css"
import { MediaPanel } from './MediaPanel';
import { AppId } from '../../services/hooks/useAppLogin';
import { EnrollDialog } from '../enrollment/Enroll';
import { UserFlags } from '../../services/ApiInterfaces';

import { DashboardContext, DashboardContextProvider } from './DashboardContext';
import DashboardApi from '../../services/backend-apis/DashboardApi';

function DashboardWrapped() {
    const api = useContext(DashboardContext);
    const [canAdmin, setCanAdmin] = useStateIfMounted(false);
    const [adminDashData, setAdminDashData] = useStateIfMounted({
        orgCount: 0,
        userCount: 0,
    });
    const [biometricsDashData, setBiometricsDashData] = useStateIfMounted({
        speakerCount: "na",
        lastEnrollmentDateUTC: "na",
        applianceCount: "na",
    });
    const [billingDashData, setBillingDashData] = useStateIfMounted({
        balance: undefined,
        lastPaymentDateUTC: "n/a",
        currentPlan: "n/a",
    });
    const [mediaDashData, setMediaDashData] = useStateIfMounted({
        liveStreams: "n/a",
    });
    const appState = useAppState();
    const loaded = appState.state === AppStateEnum.idle;
    const [shouldEnrollSpeech, setShouldEnrollSpeech] = useStateIfMounted(false);


    const updateDash = useCallback(async () => {
        const caller = AppState.instance.caller;
        const open_enroll = caller.phoneNumber
                        && !((caller.flags & UserFlags.IsSpeeshEnrolled) === UserFlags.IsSpeeshEnrolled);
        setShouldEnrollSpeech(open_enroll);
        setCanAdmin(DashboardApi.isAdminAvailable());
        const admin_data = await api.queryDashboardData();
        setAdminDashData(admin_data);
        if (admin_data.error) console.log(admin_data.error);

        const platform_api = new ManagePlatformApi();
        const login_biometrics_result = await platform_api.loginToApp(AppId.OmniVoice);
        const login_billing_result = await platform_api.loginToApp(AppId.Billing);
        const login_media_result = await platform_api.loginToApp(AppId.Media);

        if (login_biometrics_result.success) {
            setBiometricsDashData(login_biometrics_result.meta);
        }
        else {
            setBiometricsDashData({
                speakerCount: "na",
                lastEnrollmentDateUTC: "na",
                applianceCount: "na",
            });
        }

        if (login_billing_result.success) {
            setBillingDashData(login_billing_result.meta);
        }
        else {
            setBillingDashData({
                balance: undefined,
                lastPaymentDateUTC: "n/a",
                currentPlan: "n/a",
            });
        }

        if (login_media_result.success) {
            setMediaDashData(login_media_result.meta);
        }
        else {
            setMediaDashData({
                liveStreams: "n/a",
            })
        }
    }, []);

    useEffect(() => {
        if (loaded) updateDash();
    }, [appState.effectiveOrg, appState.effectiveScope, appState.effectiveRole, loaded]);

    return (
        <ViewLayout>
            <Grid container direction="column">
                <Typography className="title">Quick Links</Typography>
                <Grid container direction="row" className="cards-layout">
                    <BiometricsPanel {...biometricsDashData} />
                    {canAdmin &&
                        <AdminPanel {...adminDashData} />}
                    <BillingPanel {...billingDashData} />
                    <MediaPanel {...mediaDashData} />
                </Grid>
                {shouldEnrollSpeech && (
                <EnrollDialog
                    model={AppState.instance.caller}
                    onClose={v => setShouldEnrollSpeech(false)}
                    />
            )}
            </Grid>
        </ViewLayout>
    );
};

const Dashboard = () => {
    useContext(DashboardContext);
    return (
        <DashboardContextProvider>
            <DashboardWrapped />
        </DashboardContextProvider>
    );
}

export default Dashboard;