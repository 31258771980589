import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ObtainVoiceSample } from '../login/ObtainVoiceSample';

export interface IStep1Props {
    phoneNumber: string;
    onSubmit: (media: Blob) => void;
};

export function Step1(props: IStep1Props): JSX.Element {
    const [media, setMedia] = useState<Blob>(null);
    const [msg, setMsg] = useState<string>("Click on Mic and start speaking");

    const handleMedia = (media: Blob) => {
        if (media) {
            setMedia(media);
            setMsg("Audio captured. Press next");
        }
    }

    return (
        <Stack direction="column" spacing={1} sx={{ margin: "20px" }}>
            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                Step 1. Record voice
            </Typography>
            <Typography>
                To enroll, we need a recording of you saying: <b>"My name is [your name] and my phone number is [your mobile]. 9, 8, 7, 6, 5, 4, 3, 2, 1, 0"</b>.
            </Typography>
            <ObtainVoiceSample
                instructions={msg}
                disabled={media ? true : false}
                minSpeech_sec={10}
                timeout_sec={15}
                onObtained={media => {if (media) props.onSubmit(media);}}
                />
            <Button
                disabled={!media}
                variant="contained"
                onClick={() => props.onSubmit(media)}
                sx={{
                    width: "100px",
                    height: "50px",
                    alignSelf: "center",
                }}>
                Next
            </Button>
            <div />
        </Stack>
    );
};