import { Paper, Typography, Box, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    card: {
      maxWidth: 345,
      padding: "20px",
      paddingRight: "40px",
      margin: "10px",
    },
    title: {
      marginBottom: "10px",
    },
    media: {
    },
    description: {
      marginTop: "10px",
      textAlign: "justify",
    },
  });

  function MakeBasicCard(props) {
    const classes = useStyles();
    const {title, desc, children} = props;

    return <>
        <Paper className={classes.card}>
            <Grid container direction="column" spacing={1}>
                <Typography component="p" variant="h5" className={classes.title}>{title}</Typography>
                <Divider style={{marginBottom: "10px"}} />
                <Box className={classes.media}>{children}</Box>
                <Divider style={{marginTop: "10px"}} />
                <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>{desc}</Typography>
            </Grid>
        </Paper>
    </>;
};

export default MakeBasicCard;