import * as React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Grow, GrowProps, Tooltip, IconButton, Stack, TextField } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { AddCircle as CreateIcon, DeleteForever as DeleteIcon} from '@mui/icons-material';

import { useStateIfMounted, VerticalToolbar } from 'omni-voice-react-shared';
import { DataGrid, GridColumns, GridEditRowsModel } from '@mui/x-data-grid';

var id_seed = 0;

function getId() {
    id_seed++;
    return id_seed;
}


const Transition_Grow = React.forwardRef<JSX.Element, GrowProps>(function Transition(props, ref) {
    return (
        <Grow
            in={true}
            ref={ref}
            {...props}
            {...{ timeout: 500 }}
            />
        );
});

const scopeLocalityColumns: GridColumns = [
    { field: "fieldName", headerName: "Type", width: 200, editable: true, type: "singleSelect", valueOptions: ["Reseller", "Region", "Tag"], },
    { field: "filterValue", headerName: "Value", width: 200, editable: true, type: "string" },
];

function CrudButton({ disabled, icon, caption, color, onClick }) {
    return (
        <Tooltip title={caption} placement="right">
            <IconButton
                aria-label={caption}
                component="span"
                sx={{color: color}}
                onClick={() => onClick()}
                disabled={disabled}>
                {icon}
            </IconButton>
        </Tooltip>
    );
}

export function ScopeEditorDialog(props) {
    if (props.model === null) throw new Error("props.model === null");

    const { model, onClose, title } = props;
    const [localities, setLocalities] = useStateIfMounted(model.localities ?? []);
    const [selected, setSelected] = useStateIfMounted(null);
    const [scopeName, setScopeName] = useStateIfMounted(model.name);

    const handleClose = React.useCallback((apply) => {
        if (onClose) {
            if (!apply) onClose(null);
            else {
                const result = {
                    id: model?.id ?? "new" + getId(),
                    localities: localities,
                    name: scopeName,
                };
                onClose(result);
            }
        }
    }, [onClose, localities, scopeName, model]);

    const createNew = React.useCallback(() => {
        setLocalities([...localities, {
            id: "new" + getId(),
            fieldName: "Reseller",
            filterValue: "<value>",
        }]);
    }, [setLocalities, localities]);

    const deleteSelected = React.useCallback(() => {
        if (selected && selected.length === 1) {
            const [id] = selected;
            setLocalities(localities.filter(loc => loc.id !== id));
            setSelected([]);
        }
    }, [localities, setLocalities, selected, setSelected]);

    const selectById = React.useCallback((ids) => {
        setSelected(ids)
    }, [setSelected]);

    // const handleRowEditCommit = React.useCallback((id) => {
    //     const rows = localities.map(loc => loc.id === id ? editedRow : loc);
    //     setLocalities(rows);
    // }, [localities, setLocalities, editedRow]);

    const handleEditRowModelChange = React.useCallback((model: GridEditRowsModel) => {
        if (Object.keys(model).length > 0) {
            const id = Object.keys(model)[0];
            const filter_value = model[id].filterValue.value;
            const field_name = model[id].fieldName.value;
            const edited_row = {id: id, fieldName: field_name, filterValue: filter_value};
            const rows = localities.map(loc => loc.id === id ? edited_row : loc);
            setLocalities(rows);
        }
    }, [localities, setLocalities]);

    const toolbarButtons = [
        <CrudButton key={0} caption="Create New" color={green[700]} disabled={false} icon={<CreateIcon />} onClick={createNew} />,
        <CrudButton key={1} caption="Delete Selected" color={red[700]} disabled={selected === null || selected.length === 0} icon={<DeleteIcon />} onClick={deleteSelected} />
    ];
    
    const Toolbar = () => <VerticalToolbar {...props}>{toolbarButtons}</VerticalToolbar>;

    const styles = {
        container: {
            display: "flex",
            width: "100%",
            minHeight: "250px",
            flexFlow: "row",
            border: '0px solid green',
            p: "15px",
            alignItems: "stretch",
        },

        grid: {
            flex: 1,
            overflow: "auto",
            marginRight: "40px",
        },
    };
  
    return (
        <Dialog
            {...props}
            TransitionComponent={Transition_Grow}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth='sm'>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1}>
                    <TextField
                        sx={{ marginLeft: "60px", marginRight: "40px", marginTop: "10px" }}
                        variant="outlined"
                        label="Scope Name"
                        value={scopeName}
                        onChange={e => setScopeName(e.target.value)} />
                    <div style={styles.container}>
                        <Toolbar />
                        <div style={styles.grid}>
                            <DataGrid
                                editMode="row"
                                onEditRowsModelChange={handleEditRowModelChange}
                                rowHeight={30}
                                onSelectionModelChange={selectById}
                                rows={localities}
                                columns={scopeLocalityColumns} />
                        </div>
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleClose(false)}>CANCEL</Button>
                <Button variant="contained" onClick={() => handleClose(true)} autoFocus disabled={!localities || localities.length === 0}>SAVE</Button>
            </DialogActions>
        </Dialog>
    );
};