export class PermissionType {
    static None             = "None";
    static Org_Create       = "Organization: create";
    static Org_Delete       = "Organization: delete";
    static Org_Read         = "Organization: read";
    static Org_Rescope      = "Organization: rescope";
    static Org_Update       = "Organization: update";
    static Scope_Create     = "Scope: create";
    static Scope_Delete     = "Scope: delete";
    static Scope_Read       = "Scope: read";
    static Scope_Update     = "Scope: update";
    static User_Create      = "User: create";
    static User_Delete      = "User: delete";
    static User_Read        = "User: read";
    static User_ChangeRole  = "User: change role";
    static User_Rescope     = "User: rescope";
    static User_Update      = "User: update";
    static Caller_Read      = "Caller: read";
    static Caller_Update    = "Caller: update";
};