import { useEffect } from "react";
import { useEditorMetadata } from "omni-voice-react-shared";
import { FieldEditor } from "omni-voice-react-shared";
import { RegisterFormModel } from "../../services/crud-models/RegisterFormModel";

import { Stack } from "@mui/material";

export interface IGetRegistrationDetailsProps {
    onChange?: (is_valid: boolean, model: any) => void;
    details?: RegisterFormModel;
};

function isSame(mod1, mod2): boolean {
    if (mod1 && mod2) {
        const keys = Array.from(new Set<string>([...Object.keys(mod1), ...Object.keys(mod2)]));
        const result = keys
            .map(key => (key in mod1) && (key in mod2) && mod1[key] === mod2[key])
            .reduce((v1, v2) => v1 && v2);
        return result;
    }
    else return false;
}

export function GetRegistrationDetails(props: IGetRegistrationDetailsProps): JSX.Element {
    const editorMetadata = useEditorMetadata({ model: new RegisterFormModel(props.details) });

    useEffect(() => {
        const states = editorMetadata.editors.map(e => e.state);
        const valid: boolean = states
            .map(s => s.valid)
            .reduce((s1, s2) => s1 && s2);
        let model = {};
        editorMetadata
            .editors
            .forEach(e => model[e.propEditor.propertyKey] = e.propEditor.converter.fromString(e.state.stringValue));

        if (props.onChange && !isSame(model, props.details)) {
            console.log(valid);
            props.onChange(valid, model);
        }
    }, [editorMetadata.editors.map(e => e.state)]);

    return (
        <Stack
            spacing={2}
            direction="column">
            <Stack
                spacing={1}
                sx={{ margin: "10px", flexGrow: 1 }}
                direction="column">
                {editorMetadata.editors.map((editor, key) => <FieldEditor key={key} {...editor} />)}
            </Stack>
        </Stack>
    );
}