import { IAppConfig, IAppStatus, IPlatformConfig, IAppLoginResult } from '../ApiInterfaces';
import { Rest } from 'omni-voice-react-shared';
import { EmailConfig } from '../crud-models/EmailConfig';

export interface IResult {
    success: boolean;
    error: string;
};

export default class ManagePlatformApi {
    async getApps(): Promise<IAppStatus[]> {
        var rest_resp = await Rest.get<IAppStatus[]>('/managePlatform/getApps');
        var result = rest_resp && !rest_resp.hasHttpError ? rest_resp.content : [];
        return result;
    }

    async getPlatform(): Promise<IPlatformConfig> {
        var rest_resp = await Rest.get<IPlatformConfig>('/managePlatform/getPlatform');
        var result = rest_resp && !rest_resp.hasHttpError ? rest_resp.content : null;
        return result;
    }

    async updatePlatformConfig(config: IPlatformConfig): Promise<void> {
        await Rest.post<IAppStatus>('/managePlatform/updatePlatformConfig', config);
    }

    async updateAppConfig(app_config: IAppConfig): Promise<IAppStatus> {
        var rest_resp = await Rest.post<IAppStatus>('/managePlatform/updateAppConfig', app_config);
        var result = rest_resp && !rest_resp.hasHttpError ? rest_resp.content : null;
        return result;
    }

    async updateEmailConfig(email_config: EmailConfig): Promise<IResult> {
        try {
            const result = await Rest.post<IResult>('/managePlatform/updateEmailConfig/', email_config);
            if (result.hasHttpError) {
                return {
                    success: false,
                    error: result.httpError.errorDescription
                };
            }
            else return result.content;
        }
        catch(error) {
            return {
                success: false,
                error: error + "",
            };
        }
    }

    async mountApp(app_id: string): Promise<IAppStatus> {
        var rest_resp = await Rest.get<IAppStatus>('/managePlatform/mountApp/' + app_id);
        var result = rest_resp && !rest_resp.hasHttpError ? rest_resp.content : null;
        return result;
    }

    async unmountApp(app_id: string): Promise<IAppStatus> {
        var rest_resp = await Rest.get<IAppStatus>('/managePlatform/unmountApp/' + app_id);
        var result = rest_resp && !rest_resp.hasHttpError ? rest_resp.content : null;
        return result;
    }

    async loginToApp(app_id: string): Promise<IAppLoginResult> {
        var rest_resp = await Rest.post('/managePlatform/loginToApp/' + app_id, null);
        var result = rest_resp.content as IAppLoginResult;
        if (!rest_resp || rest_resp.hasHttpError) {
            result = {
                oAuthToken: "",
                success: false,
                errorMessage: rest_resp ? rest_resp.httpError.errorDescription : "rest_resp === null",
                frontendUrl: "",
                backendUrl: "",
                meta: {},
            };
        }
        return result;
    }
};