import { IIdentifiable } from "omni-voice-react-shared";

// public record VoiceAuthentication {
//     [JsonPropertyName("timestamp")]
//     public DateTimeOffset Timestamp { get; init; }

//     [JsonPropertyName("sampleLength_sec")]
//     public double SampleLength_sec { get; init; }

//     [JsonPropertyName("sampleQuality")]
//     public double SampleQuality { get; init; }

//     [JsonPropertyName("score")]
//     public double Score { get; init; }
// };

export interface IVoiceAuthentication {
    timestamp: Date;
    sampleLength_sec: number;
    sampleQuality: number;
    score: number;
};

export interface IOmniVoiceWorkflow {
    id: string;
    workflowType: string;
    state: string;
    metadata: string;
    expired: boolean;
    identifier: string;
    authentications: IVoiceAuthentication[];
};

export interface IApiCallResult {
    error: string;
    success: boolean;
};

export interface ILoginResult extends IApiCallResult {
    token: string;
    workflow: IOmniVoiceWorkflow;
};

export interface IEnrollResult extends IApiCallResult {
    metadata: string;
    netSpeechDuration_sec: number;
    identifier: string;
    identifierValidated: boolean;
};

export interface IUserDetails {
    fullName: string;
    position: string;
    phoneNumber: string;
    flags: number;
};

export enum UserFlags {
    None = 0,
    IsSpeeshEnrolled = 1 << 0,
}

export interface IPermission {
    name: string;
};

export interface IRoleDefinition {
    id: string;
    name: string;
    permissions: IPermission[];
}

export interface IRole extends IRoleDefinition {
    assignableRoles: IRoleDefinition[];
};

export interface IScopeLocality extends IIdentifiable {
    fieldName: string;
    filterValue: string;
};

export interface IScope extends IIdentifiable {
    name: string;
    localities: IScopeLocality[];
};

export interface IScopeDefinition {
    scopeId: string;
    orgId: string;
};

export interface IOrg extends IIdentifiable {
    name: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    website: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postcode: string;
    state: string;
    country: string;
    orgScope: string[];
};

export interface IUser extends IUserDetails, IIdentifiable {
    role: IRole;
    userName: string;
    org: IOrg;
    scope: IScope;
};

export interface ICaller extends IUser {
    invalidateEffectiveScope: boolean;
}

export interface IDashboardData {
    userCount: number;
    orgCount: number;
    error: string;
};

export interface IPlatformConfig {
    frontendUrl: string;
    backendUrl: string;
    siteName: string;
    logoUrl: string;
    biometricsPvtKey: string;
    biometricsPubKey: string;
    useStrongVoiceLoginStrategy: boolean;
};

export interface IAppConfig {
    id: string;
    isMounted: boolean;
    systemApiKey: string;
    backendUrl: string;
    frontendUrl: string;
};

export interface IAppStatus {
    id: string;
    error: string;
    hasError: boolean;
    isMounted: boolean;
    backendUrl: string;
    frontendUrl: string;
    systemApiKey: string;
};

export interface IAppLoginResult {
    oAuthToken: string;
    success: boolean;
    errorMessage: string;
    frontendUrl: string;
    backendUrl: string;
    meta: { [key: string]: string };
};