import { useEffect, useCallback } from 'react';

import { orgColumns } from './TableSchemas';
import { EditOrgModel } from '../../services/crud-models/EditOrgModel';
import { WrapsDataGrid, makeCrudButton, useDataGridState, UtilityDialog, ICrudApiResult } from 'omni-voice-react-shared';

import { EditorForm, useEditorState, IEditorStateControl } from 'omni-voice-react-shared';

// import { EditorForm } from "../register/editor-management/EditorForm";
// import { useEditorState } from '../register/editor-management/useEditorState';
// import { IEditorStateControl } from '../register/editor-management/useEditorState';

import { VerticalToolbar } from 'omni-voice-react-shared';

import { amber, brown, green, red } from '@mui/material/colors';

import OrgApi from '../../services/backend-apis/OrgApi';
import { PermissionType } from '../../services/PermissionType';
import { IOrg } from '../../services/ApiInterfaces';
import { useStateIfMounted } from 'omni-voice-react-shared';

import { RescopeDialog } from './RescopeDialog';
import { useAppState } from '../../services/app-state/useAppState';

// icons
import { AddCircle as CreateIcon, DeleteSweep as DeleteIcon,
    Policy as RescopeIcon, Edit as EditIcon} from '@mui/icons-material';
import { AppStateEnum } from '../../services/app-state/AppState';


const ManageOrgs = (props) => {
    const [errorMessage, setErrorMessage] = useStateIfMounted(null);
    const [pending, setPending] = useStateIfMounted(false);
    const appState = useAppState();
    const loaded = appState.state === AppStateEnum.idle;

    const processCrudResult = useCallback((result: ICrudApiResult<IOrg>) => {
        setPending(false);
        if (result) {
            if (result.hasError) {
                setErrorMessage(result.error);
            }
            else {
                dataGridState.updateEntries(result.data, result.total);
            }
        }
        else {
            setErrorMessage("System error occurred");
        }
    }, [setPending, setErrorMessage]);

    // Editor / dialogs management
    const createCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Org_Create),
        apply: async model => {
            const processed_model = {
                ...model,
                country: model.country.label,
            };
            setPending(true);
            const result: ICrudApiResult<IOrg> = await OrgApi
                .getInstance()
                .create(processed_model, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: true,
    } as IEditorStateControl;

    const editCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Org_Update),
        apply: async model => {
            setPending(true);
            console.log(model);
            const result: ICrudApiResult<IOrg> = await OrgApi
                .getInstance()
                .update({...model, country: model.country?.code}, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const rescopeCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Org_Rescope),
        apply: async model => {
            console.log(model);
            const dto = {
                orgId: rescopeState.model.id,
                scopeId: model.scopeId,
            }
            setPending(true);
            const result: ICrudApiResult<IOrg> = await OrgApi
                .getInstance()
                .rescope(dto, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: _ => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const deleteCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Org_Delete),
        apply: async model => {
            setPending(true);
            const result: ICrudApiResult<IOrg> = await OrgApi
                .getInstance()
                .delete([model.id], dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const createState = useEditorState(createCtrl);
    const editState = useEditorState(editCtrl);
    const rescopeState = useEditorState(rescopeCtrl);
    const deleteState = useEditorState(deleteCtrl);

    // Data Grid controls && state
    const dataGridControl = {
        pageSizeOptions: [10, 50, 100],
        populate: async data_view => {
            const view_res = await OrgApi.getInstance().get(data_view);
            return view_res;
        },
        handleEntrySelected: entry => {
            buttonsDef.forEach(def => def.state.selectModel(entry));
        },
    };

    // DataGrid management
    const dataGridState = useDataGridState(dataGridControl);

    useEffect(() => {
        if (loaded) {
            buttonsDef.forEach(def => def.state.invalidate());
            OrgApi.getInstance().invalidateState();
            dataGridState.refresh();
        }
    }, [loaded, appState.effectiveRole, appState.effectiveScope, appState.effectiveOrg]);

    // toolbar buttons def and render
    const buttonsDef = [
        {icon: <CreateIcon />, tooltip: "Create New", state: createState, color: green[700]},
        {icon: <EditIcon />, tooltip: "Edit Selected", state: editState, color: brown[500]},
        {icon: <RescopeIcon />, tooltip: "Rescope Selected", state: rescopeState, color: amber[700]},
        {icon: <DeleteIcon />, tooltip: "Delete Selected", state: deleteState, color: red[700]},
    ];

    const toolbarButtons = buttonsDef
        .filter(def => def.state.isAvailable)
        //editor_state, icon, caption, minimized=false
        .map((def, key) => makeCrudButton(def.state, def.icon, def.tooltip, key, true, def.color));
    
    useEffect(() => {
        if (dataGridState.error) {
            setErrorMessage(dataGridState.error);
        }
    }, [dataGridState.error]);

    useEffect(() => {
        buttonsDef.forEach(def => def.state.disablePermanently(pending || dataGridState.pendingOp));
    }, [pending, dataGridState.pendingOp, buttonsDef]);

    const Toolbar = () => <VerticalToolbar {...props}>{toolbarButtons}</VerticalToolbar>;

    const Error = () => {
        return <>
            {errorMessage && (
                <UtilityDialog
                    {...props}
                    variant="error"
                    title="Error"
                    desc={errorMessage}
                    onClose={() => setErrorMessage(null)}/>
            )}
        </>
    };

    const Dialogs = () => {
        return <>
            {deleteState.isOpen && (
                <UtilityDialog
                    {...props}
                    variant="confirmation"
                    title="Confirm Delete Org"
                    desc="This operation will delete the org, including its users and associated data across all apps. The operation is final and cannot be undone. Please confirm you wish to proceed."
                    onClose={ok => ok ? deleteState.apply(deleteState.model) : deleteState.cancel(deleteState.model)}
                    />
            )}

            {createState.isOpen && (
                <EditorForm
                    title={"Create Org"}
                    description={`Fields marked with (*) are required.`}
                    model={new EditOrgModel()}
                    onClose={v => v ? createState.apply(v) : createState.cancel(v)}
                    />
            )}
        
            {editState.isOpen && (
                <EditorForm
                    title="Edit Org"
                    description={`Fields marked with (*) are required.`}
                    model={new EditOrgModel(editState.model)}
                    onClose={v => v ? editState.apply(v) : editState.cancel(v)}
                    />
            )}

            {rescopeState.isOpen && (
                <RescopeDialog
                    title="Rescope Org"
                    model={rescopeState.model}
                    onClose={v => v ? rescopeState.apply(v) : rescopeState.cancel(v)}
                    allowLocal={false}
                    />
            )}
        </>;
    };

    const styles = {
        container: {
            display: "flex",
            width: "100%",
            flexFlow: "row",
            border: '0px solid green',
            p: "15px",
            alignItems: "stretch",
        },

        grid: {
            flex: 1,
            overflow: "auto",
            marginRight: "40px",
        },
    };

    return (
        <div style={styles.container}>
            <Toolbar />
            <div style={styles.grid}>
                <WrapsDataGrid
                    sx={null}
                    gridState={dataGridState}
                    columnSchema={orgColumns}
                    loading={pending}
                    />
            </div>
            <Dialogs />
            <Error />
        </div>
    );
};

export default ManageOrgs;