import { CountryType, lookupCountryByName } from "./countries";
import { IConverter, ModelEditorType } from "./interfaces"

function extractPhoneNumber(inputtxt) {
    var result = "";

    if (inputtxt) {
        var phoneno = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
        var match = inputtxt.match(phoneno);
        if (match) {
            result = match[0];
        }
    }

    return result;
}

export class NameConverter implements IConverter {
    /**
     * @param val is assumed to be a valid string name
     */
    toString(val: any): string { return val as string; }
    /**
     * @param str can be any string which is tested using regex to contain a name.
     * Name is something that doesn't have brackets or doesn't start with non alpha chars etc...
     */
    fromString(str: string): any {
        if(str) {
            const re = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$/gm;
            return str.match(re)?.join();
        }
        else return null;
    }
    canConvert(str: string): boolean { return (this.fromString(str) as string)?.length > 0; }
}

export class SelectConverter implements IConverter {
    readonly selectValues: string[];

    /**
     * @param val is assumed to be a valid string from the selectValues;
     */
    toString(val: any): string { return val + ""; }
    /**
     * @param str if str is in the selectValues list (case invariant), returns the selectValue it matches;
     * returns null otherwise.
     */
    fromString(str: string): any {
        const result = this.selectValues.filter(v => v.toLocaleLowerCase() === str?.toLocaleLowerCase());
        if (result && result.length > 0) return result[0]
        else return null;
    }
    canConvert(str: string): boolean {
        const invalid = !this.fromString(str);
        return !invalid;
    }

    constructor(select_values: string[]) {
        if (!select_values || select_values.length === 0) throw new Error("select_values is undefined, null or empty");
        select_values.forEach(v => { if (!v || v.length === 0) throw new Error("one of values in select_values is empty or null"); });

        this.selectValues = select_values;
    }
}

export class BoolConverter implements IConverter {
    toString(val: any): string { return val + ""; }
    fromString(str: string): any { return str ? str.toLocaleLowerCase() === "true" : false; }
    canConvert(str: string): boolean { return true; }
};

export class FloatConverter implements IConverter {
    toString(val: any): string { return val + ""; }
    fromString(str: string): any { return parseFloat(str); }
    canConvert(str: string): boolean {
        var test = this.fromString(str);
        return !isNaN(test);
    }
};

export class IntConverter implements IConverter {
    toString(val: any): string { return val + ""; }
    fromString(str: string): any { return parseInt(str, 10); }
    canConvert(str: string): boolean {
        var test = this.fromString(str);
        return !isNaN(test);
    }
};

export class TextConverter implements IConverter {
    toString(val: any): string { return val; }
    fromString(str: string): any { return str; }
    canConvert(str: string): boolean { return str && str.length > 0; }
};

export class PasswordConverter implements IConverter {
    toString(val: any): string { return val; }
    fromString(str: string): any { return str; }
    canConvert(str: string): boolean { return str && str.length >= 8; }
};

export class PhoneNbrConverter implements IConverter {
    toString(val: any): string { return val; }
    fromString(str: string): any { return extractPhoneNumber(str); }
    canConvert(str: string): boolean {
        var phone_nbr = extractPhoneNumber(str);
        return phone_nbr !== null && phone_nbr.length >= 8;
    }
};

export class DateConverter implements IConverter {
    toString(val: any): string { return val + ""; }
    fromString(str: string): any { return str; }
    canConvert(str: string): boolean { return true; }
};

export class DateTimeConverter implements IConverter {
    toString(val: any): string { return val + ""; }
    fromString(str: string): any { return str; }
    canConvert(str: string): boolean { return true; }
};

export class EmailConverter implements IConverter {
    toString(val: any): string { return val + ""; }
    fromString(str: string): any { return str; }
    canConvert(str: string): boolean {
        // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression?page=2&tab=active#answer-14075810
        let regex = /^("(?:[!#-\[\]-\u{10FFFF}]|\\[\t -\u{10FFFF}])*"|[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}])*)@([!#-'*+\-/-9=?A-Z\^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}])*|\[[!-Z\^-\u{10FFFF}]*\])$/u
        return regex.test(str);
    }
};

export class CountryConverter implements IConverter {
    toString(val: any): string { return (val as CountryType).label; }
    fromString(str: string) { return lookupCountryByName(str); }
    canConvert(str: string): boolean { return str && lookupCountryByName(str) !== null; }
};

export function CreateConverter(editor_type: ModelEditorType, select_values: string[]): IConverter {
    switch(editor_type) {
        case ModelEditorType.Bool:
            return new BoolConverter();
        case ModelEditorType.Float:
            return new FloatConverter();
        case ModelEditorType.Int:
            return new IntConverter();
        case ModelEditorType.Text:
            return new TextConverter();
        case ModelEditorType.Password:
            return new PasswordConverter();
        case ModelEditorType.Phone:
            return new PhoneNbrConverter();
        case ModelEditorType.Date:
            return new DateConverter();
        case ModelEditorType.DateTime:
            return new DateTimeConverter();
        case ModelEditorType.Email:
            return new EmailConverter();
        case ModelEditorType.Country:
            return new CountryConverter();
        case ModelEditorType.Name:
            return new NameConverter();
        case ModelEditorType.Select:
            return new SelectConverter(select_values);
        default:
            throw new Error(`Model editor type of ${editor_type} isn't supported`);
    };
}