import { IPropEditorDef } from "./interfaces";
import { PropEditor } from './PropEditor';
import { EditorMetadata } from "./EditorMetadata";


/**
 * The idea behind the editor decorator is to fully define a form field required to
 * edit the property. This would allow to fully automate the rendering of forms that edit
 * models.
 * 
 * How it works. When an instance of a model class is instantiated, editor decorator will
 * kick-in and append metadata field to the instance. The metadata field will contains an
 * array of IPropEditor for each field decorated with @editor. IPropEditor provides:
 * 1. Information on the type of the field (text, int, float or bool)
 * 2. Can convert the value to/from string
 * 3. Can get/set the prop value (see commit) thus updating the underlying model
 * 4. Can validate a user input
 * 5. Title, validation text, whether the field is required etc...
 * 6. Provides order information (index) that is used to position the field on the form
 * 
 * When a form is rendered, it will work with these editors to render itself
 */
export const editor = (def: IPropEditorDef) => (target: Object, propertyKey: string) => {
    if (!target["metadata"]) {
        target["metadata"] = new EditorMetadata();
    }

    const metadata: EditorMetadata = target["metadata"] as EditorMetadata;
    if (!metadata[propertyKey]) {
        metadata[propertyKey] = new PropEditor(metadata, propertyKey, def);
    }
};