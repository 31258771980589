import { IScopeDefinition, IUser } from "./ApiInterfaces";

export enum UserType {
    Null = 0,
    Local = 1,
    Scoped = 2,
    Global = 3,
};

export class Scope {
    static scopeDef(user: IUser): IScopeDefinition {
        var result: IScopeDefinition = null;

        if (user) {
            result = {
                scopeId: user.scope?.id,
                orgId: user.org?.id,
            };
        }

        return result;
    }
    static userType(scope_def: IScopeDefinition): UserType {
        var result = UserType.Null;

        if (scope_def) {
            result = UserType.Global;
            if (scope_def.scopeId) result = UserType.Scoped;
            if (scope_def.orgId) result = UserType.Local;
        }

        return result;
    }

    static isInScopeOf(narrow: IScopeDefinition, wide: IScopeDefinition): boolean {
        const narrow_type = Scope.userType(narrow);
        const wide_type = Scope.userType(wide);

        var result = false;

        if (narrow && wide && narrow_type !== UserType.Null && wide_type !== UserType.Null) {
            result = narrow_type <= wide_type;
            if (result && wide_type === UserType.Local) {
                result = result && narrow.orgId === wide.orgId;
            }
        }

        return result;
    }
};