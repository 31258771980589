import { IIconPalette, IColorAnimationInfo,
    IWavVisualizerStyle } from "./browser-api/WavVisualizer";
// https://github.com/qix-/color#readme
import Color from 'color';

export enum RecorderThemeId {
    dark = 0,
    light = 1,
};

interface IThemeParams {
    background: number;
    backgroundHover: number;
    icon: number;
    shadowIcon: number;
    thickness: number;
    led: number;
    ledSaturationHigh: number;
    ledSaturationLow: number;
    shadowLed: number;
};

const dark: IThemeParams = {
    background: 40,
    backgroundHover: 30,
    icon: 95,
    shadowIcon: 99,
    thickness: 0.7,
    led: 50,
    ledSaturationHigh: 100,
    ledSaturationLow: 40,
    shadowLed: 99,
};

const light: IThemeParams = {
    background: 95,
    backgroundHover: 100,
    icon: 40,
    shadowIcon: 50,
    thickness: 1,
    led: 50,
    ledSaturationHigh: 100,
    ledSaturationLow: 40,
    shadowLed: 99,
};

/**
 * Good reading on colors and theming:
 * https://www.smashingmagazine.com/2010/02/color-theory-for-designers-part-2-understanding-concepts-and-terminology/
 */
export class RecorderTheme {
    private static _defaultThemeHue = new Color("brown").hsl().hue();
    private static _defaultRecordingHue = new Color("red").hsl().hue();
    private _themeHue: number;
    private _recordingHue: number;
    private _id: RecorderThemeId;
    private _theme: IWavVisualizerStyle;
    private _saturation: number;

    private _greyscale(palette: IIconPalette, lighten: number): IIconPalette {
        var result = {};
        for(var prop in palette) {
            var new_color = Color(palette[prop])
                .grayscale()
                .lighten(lighten)
                .hex();
            result[prop] = new_color;
        }
        return result as IIconPalette;
    }

    private _createTheme(config: IThemeParams) {
        // basic idea is as follows:
        // - for light theme we use a light background and dark outlines (mic and recording)
        // - for dark theme, we just invert...
        // - the mic outlines as well as signal level are informational only so we use a low saturation values
        // - the recording indication is two-fold:
        //      1. No active recording - LED is stationary and has low saturation
        //      2. Active recording in progress: LED is animated between low and high saturation
        // - shades:
        //  o Icon outline will drop a shadow
        //  o LED will dtop a shadow
        // So the theme is fully defined by the lightness while hue and saturations are hardcoded
        // I think this is enough of democracy...
        // Terminology and ideas are taken from here: https://www.smashingmagazine.com/2010/02/color-theory-for-designers-part-2-understanding-concepts-and-terminology/
        const base_hue = this._themeHue;
        const saturation = this._saturation; // values range from 0 ... 100        
        const LED_hue = this._recordingHue;

        var rec_on = Color.hsl(LED_hue, config.ledSaturationHigh, config.led);
        var rec_off = Color.hsl(LED_hue, config.ledSaturationLow, config.led);

        const enabled: IIconPalette = {
            background: Color.hsl(base_hue, saturation, config.background).hex(),
            icon: Color.hsl(base_hue, saturation, config.icon).hex(),
            shadowIcon: Color
                .hsl(base_hue, saturation, config.shadowIcon)
                .grayscale()
                .hex(),
            led: rec_off.hex(),
            shadowLed: Color.hsl(base_hue, saturation, config.shadowLed).hex(),
            backgroundHover: Color.hsl(base_hue, saturation, config.backgroundHover).hex(),
        };
        const disabled: IIconPalette = {
            ...this._greyscale(enabled, 0.6),
            background: Color(enabled.background).grayscale().hex(),
        };
        const animation: IColorAnimationInfo = {
            color1: {
                r: rec_off.red(),
                g: rec_off.green(),
                b: rec_off.blue(),
            },
            color2: {
                r: rec_on.red(),
                g: rec_on.green(),
                b: rec_on.blue(),
            },
        };

        this._theme = {
            enabled: enabled,
            disabled: disabled,
            led: animation,
            thickness: config.thickness,
        };
    }

    private _createLightTheme() { this._createTheme(light); }
    private _createDarkTheme() { this._createTheme(dark); }

    public get theme(): IWavVisualizerStyle { return this._theme; }
    public get themeId(): RecorderThemeId { return this._id; }
    public set themeId(id: RecorderThemeId) {
        if (id != this._id) {
            this._id = id;
            switch(id) {
                case RecorderThemeId.dark:
                    this._createDarkTheme();
                    break;
                case RecorderThemeId.light:
                    this._createLightTheme();
                    break;
                default:
                    throw new Error(`Theme "${id}" is not supported. Supported values are <${RecorderThemeId.dark} | ${RecorderThemeId.light}>`);
            }
        }
    }
    public get saturation(): number { return this._saturation; }
    public set saturation(val: number) { this._saturation = val; }

    constructor(theme_color: string, recording_color: string, theme_id: RecorderThemeId) {
        if (theme_color) {
            this._themeHue = Color(theme_color).hcg().hue();
        }
        else {
            this._themeHue = RecorderTheme._defaultThemeHue;
        }

        if (recording_color) {
            this._recordingHue = Color(recording_color).hcg().hue();
        }
        else {
            this._recordingHue = RecorderTheme._defaultRecordingHue;
        }

        this._saturation = 30;
        this.themeId = theme_id;
    }
};