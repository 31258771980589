import * as React from 'react';
import { Paper, Grid, IconButton } from '@mui/material';

import ViewLayout from '../ViewLayout';

import ManagePlatform from './manage-platform/ManagePlatform';
import ManageOrgs from './ManageOrgs';
import ManageScopes from './manage-scopes/ManageScopes';
import ManageUsers from './manage-users/ManageUsers';

import { useNavigate, useParams } from 'react-router-dom';
import { RootRoutePaths } from '../routes/RootRoutePaths';
import { WrapsTabs } from 'omni-voice-react-shared';

import { PermissionType } from '../../services/PermissionType';
import { useAppState } from '../../services/app-state/useAppState';

import { Apartment as ManageOrgsIcon, ManageAccounts as ManageUsersIcon,
    AddModerator as ManageScopesIcon, Language as ManagePlatformIcon } from '@mui/icons-material/';
import { Home as HomeIcon } from '@mui/icons-material';

import { AppStateEnum } from '../../services/app-state/AppState';

const panelDefs = [
    {
        permissionOrRole: PermissionType.Org_Read,
        icon: <ManageOrgsIcon />,
        label: "Orgs",
        component: <ManageOrgs />,
        route: RootRoutePaths.ManageOrgs,
    },
    {
        permissionOrRole: PermissionType.User_Read,
        icon: <ManageUsersIcon />,
        label: "Users",
        component: <ManageUsers />,
        route: RootRoutePaths.ManageUsers,
    },
    {
        permissionOrRole: PermissionType.Scope_Read,
        icon: <ManageScopesIcon />,
        label: "Scopes",
        component: <ManageScopes />,
        route: RootRoutePaths.ManageScopes,
    },
    {
        permissionOrRole: "Global Admin",
        icon: <ManagePlatformIcon />,
        label: "Platform",
        component: <ManagePlatform />,
        route: RootRoutePaths.ManagePlatform,
    },
];

export default function AdminView(props) {
    const { route } = useParams() ?? "orgs";
    const navigate = useNavigate();

    const appState = useAppState();
    const loaded = appState.state === AppStateEnum.idle;

    const [panels, setPanels] = React.useState([]);
    const [activePanelId, setActivePanelId] = React.useState(0);

    React.useEffect(() => {
        const ensure_permissions = def => def.permissionOrRole === appState.effectiveRole.name ||
            appState.effectiveRole.permissions.find(p => p.name === def.permissionOrRole);

        if (loaded && appState.effectiveRole) {
            const active_panel_def = panelDefs.filter(def => def.route === route && ensure_permissions(def));
            if (active_panel_def.length === 1) {
                const built_by_defs = panelDefs
                    .filter(def => ensure_permissions(def))
                    .map((def, idx) => {
                        const result = {
                            ...def,
                            id: idx,
                        }
                        return result;
                    });
                setPanels(built_by_defs);
                setActivePanelId(built_by_defs.filter(pnl => pnl.route === route)[0].id);
            }
            else navigate(`/${RootRoutePaths.ManageOrgs}`);
        }
    // eslint-disable-next-line
    }, [appState.effectiveRole, appState.effectiveScope, appState.effectiveOrg, loaded, route, navigate]);

    const handleTabChange = React.useCallback(panel => {
        if (loaded) {
            navigate(RootRoutePaths.adminNav(panel.route));
        }
    }, [navigate, loaded]);

    const Nav = () => (
        <Grid container direction="row" spacing={3}>
            <Grid item>
                <IconButton onClick={() => navigate(RootRoutePaths.homeNav(RootRoutePaths.AppsHome))}>
                    <HomeIcon style={{ color: "white" }} />
                </IconButton>
            </Grid>
        </Grid>
    );

    return (
        <ViewLayout nav={<Nav />}>
            <Paper
                sx={{ bgcolor: 'background.paper', display: "flex", m: "20px", height: "calc(100vh - 180px)" }}
                elevation={4}>
                
                {loaded && panels && panels.length > 0 &&
                    <WrapsTabs
                        {...props}
                        onChange={handleTabChange}
                        panels={panels}
                        activePanelId={activePanelId}
                        description="omnivoice administration view" />
                }
            </Paper>
        </ViewLayout>
    );
};