import { createContext } from 'react';

const VoiceRecorderContext = createContext(null);

const VoiceRecorderContextProvider = ({ children, voiceRecorder }) => {
    return (
        <VoiceRecorderContext.Provider value={voiceRecorder}>
            {children}
        </VoiceRecorderContext.Provider>
    );
};

export { VoiceRecorderContext, VoiceRecorderContextProvider };