import { editor } from "omni-voice-react-shared";
import { ModelBase } from "omni-voice-react-shared";
import { ModelEditorType } from "omni-voice-react-shared";

export class RegisterFormModel extends ModelBase {
    @editor({
        index: 10,
        label: "Full Name",
        doc: "Please provide your full name so we know how to address you in our communications.",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    fullName: string = "";

    @editor({
        index: 20,
        label: "Organization Name",
        doc: "When registering a new account, this account will be associated with an organization (org) created automatically. Later on you will be able to add more users to your org. Specifying the org name will help us identify and support you in the future.",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    orgName: string = "";

    @editor({
        index: 25,
        label: "Country",
        doc: "We need to know your country to ensure we comply with the local regulations (e.g. European GDPR).",
        required: true,
        type: ModelEditorType.Country,
        validationError: "",
    })
    country: string = "United States";

    @editor({
        index: 20,
        label: "Email",
        doc: "Email is also used as your username. To complete your registration, you will be required to set a password by clicking on a link sent to this email address.",
        required: true,
        type: ModelEditorType.Email,
        validationError: "Must be a valid email address",
    })
    username: string = "";

    @editor({
        index: 30,
        label: "Mobile Phone",
        doc: "Your phone number is used for voice login as well as 2-factor authentication (we will send and SMS to it to confirm).",
        required: true,
        type: ModelEditorType.Phone,
        validationError: "Must be in E164 format (e.g. +1234567890)",
    })
    phoneNumber: string = "";
    
    constructor(partial?: any) {
        super();
        if (partial) Object.assign(this, partial);
        for(const prop in this) {
            //console.log(prop, typeof this[prop], this[prop] === null);
            if (prop !== 'metadata' && this[prop] === null) {
                this[prop as string] = "";
            }
        }
    }
};