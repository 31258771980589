import './Callout.css';

export interface ICalloutProps {
    children: any;
    backgroundColor: string;
    textColor: string;
    style: any;
    shaken: boolean;
    flashing: boolean;
};

export function Callout(props: ICalloutProps): JSX.Element {
    const bkgColor = props.backgroundColor ? props.backgroundColor : "brown";
    const txtColor = props.textColor ? props.textColor : "white";

    return (
        <div
            className={props.shaken ? "shaken" : null}
            style={{
                ...props.style,
                display: "flex",
                flexDirection: "row",
                gap: "0",
                justifyContent: "stretch",
                alignItems: "center",
            }}>
            <div style={{
                width: 0,
                height: 0,
                borderTop: "8px solid transparent",
                borderRight: `16px solid ${bkgColor}`,
                borderBottom: "8px solid transparent",
                flexGrow: 1,
            }}/>
            <div
                style={{
                    flexGrow: 0,
                    borderRadius: "15px",
                    background: bkgColor,
                    padding: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    color: txtColor,
                    alignSelf: "stretch"
                }}>
                    <div className={props.flashing ? "flashing" : null}>
                        {props.children}
                    </div>
            </div>
        </div>
    );
}