import { useCallback, useEffect } from "react";

import { ObtainVoiceSample } from "./ObtainVoiceSample";
import { AuthApi } from "../../services/backend-apis/AuthApi";
import { ILoginResult } from "../../services/ApiInterfaces";
import { useAuthState, useStateIfMounted } from 'omni-voice-react-shared';
import { Button, Typography } from "@mui/material";
import { useCountdown } from "./voice-recorder/useCountdown";
import { LanguagePicker } from "./language-picker/LanguagePicker";
import { UtilityDialog } from "omni-voice-react-shared";

export interface IVoiceLoginProps {
    onLoginError: (error: string) => void;
};

export function VoiceLogin(props: IVoiceLoginProps) {
    const authState = useAuthState();
    const [langCode, setLangCode] = useStateIfMounted(navigator.language);
    const [workflow, setWorkflow] = useStateIfMounted(null);
    const [pending, setPending] = useStateIfMounted(false);
    const [message, setMessage] = useStateIfMounted("Check SMS on your phone and say the verification code");
    const [scoreDialogState, setScoreDialogState] = useStateIfMounted(
        {
            showDialog: false,
            score: 0,
            signIn: () => {}
        }
    );

    const countdown = useCountdown();

    const handleVoiceObtained = useCallback(async (media: Blob) => {
        var login_result: ILoginResult = null;
        setPending(true);
        if (!workflow) {
            login_result = await AuthApi.instance.voiceLogin(media, langCode);
        }
        else {
            login_result = await AuthApi.instance.submitVoiceCode(workflow.id, media, langCode);
        }
        setPending(false);

        console.log(login_result);

        if (login_result.success) {
            if (login_result.token) {
                setScoreDialogState({
                    showDialog: true,
                    score: (workflow.authentications[0]?.score * 100 ?? 0).toFixed(1),
                    signIn: () => authState.signIn(login_result.token)
                });
                //authState.signIn(login_result.token);
            }
            else {
                if (login_result.workflow.state === "identified") {
                    countdown.start(90);
                    setWorkflow(login_result.workflow);
                }
                else {
                    countdown.stop();
                    props.onLoginError("Sorry, you were not authenticated");
                    setWorkflow(null);
                }
            }
        }
        else {
            countdown.stop();
            props.onLoginError(login_result.error);
            setWorkflow(null);
        }
    }, [authState, setWorkflow, workflow, langCode, setPending]);

    useEffect(() => {
        if (!pending) {
            if (countdown.secLeft > 0) {
                setMessage(`Check SMS on your phone and say the verification code (time-out: ${countdown.secLeft} sec)`);
            }
            else setWorkflow(null);
        }
    }, [countdown.secLeft, pending]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch"
        }}>
            <LanguagePicker
                className="language-picker"
                onChange={setLangCode}
                />
            <Typography color="primary" sx={{ alignSelf: "center" }}>
                The recording stops automatically
            </Typography>
            {!workflow && (
                <ObtainVoiceSample
                    instructions={pending ? "Authenticating, please wait..." : "Click on Mic and say your name and mobile phone number"}
                    minSpeech_sec={2}
                    timeout_sec={10}
                    onObtained={handleVoiceObtained}
                    disabled={pending}
                    />
            )}
            {workflow && (
            <ObtainVoiceSample
                instructions={pending ? "Verifying, please wait..." : message}
                minSpeech_sec={2}
                timeout_sec={7}
                onObtained={handleVoiceObtained}
                disabled={pending}
                />
            )}
            <Button
                sx={{ alignSelf: "center" }}
                disabled={!workflow || pending}
                onClick={() => setWorkflow(null)}>Start Over
            </Button>
            {scoreDialogState.showDialog && (
                <UtilityDialog
                    desc={`You have been authentucated with score ${scoreDialogState.score}`}
                    title={`Logged-in as ${workflow.identifier}`}
                    variant="info"
                    onClose={(ok) => scoreDialogState.signIn()}
                />
            )}
        </div>
    );
}