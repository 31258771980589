// // ideas are taken from https://github.com/remix-run/react-router/blob/main/FAQ.md#how-do-i-access-the-history-object-outside-of-components
// import { createBrowserHistory } from "history";
// export default createBrowserHistory();

let navigate;

export const setNavigate = (nav) => {
  navigate = nav;
};

export const navigateWrapped = (trg) => {
    if (navigate) {
        navigate(trg);
      }
};
