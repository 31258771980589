export interface IConverter {
    selectValues?: string[];
    toString(val: any): string;
    fromString(str: string): any;
    canConvert(str: string): boolean;
};

export enum ModelEditorType {
    Text,
    Float,
    Int,
    Bool,
    Phone,
    DateTime,
    Date,
    Password,
    Country,
    Email,
    Name,
    Select,
};

export interface IPropEditorDef {
    // order in which it appears on the form
    index: number;
    // label of the field
    label: string;
    // documentation to be printed next to the field on the form
    doc: string;
    // displayed if the user input fails validation
    validationError: string;
    // determines editor that is used to modify the value of the field
    type: ModelEditorType;
    // displays a star to mark the field as required
    required: boolean;
    // list of values available for selection in the dropdown
    selectValues?: string[];
};