import Languages from './languages.json';
import { TextField, Autocomplete } from '@mui/material';
import { useStateIfMounted } from 'omni-voice-react-shared';

export interface ILanguage {
    label: string;
    code: string;
};

export interface ILanguagePickerProps {
    onChange?: (code: string) => void;
    className?: string;
};

function getDefaultLanguage(): ILanguage {
    const nav_code = navigator.language?.toLowerCase() ?? "en-us";
    const result = Languages.filter((lng: ILanguage) => lng.code.toLowerCase() === nav_code)[0];
    return result;
}

export function LanguagePicker(props: ILanguagePickerProps): JSX.Element {
    const [value, setValue] = useStateIfMounted(getDefaultLanguage());

    const handleValueChanged = (new_value: ILanguage) => {
        if (props.onChange) props.onChange(new_value.code);
        setValue(new_value);
    }

    return (
        <Autocomplete
            className={props.className}
            disablePortal
            disableClearable
            size="small"
            value={value}
            onChange={(e,v) => handleValueChanged(v)}
            options={Languages}
            renderInput={params => <TextField {...params} label="Language" />}
            />
    );
}