import { useMemo, useCallback } from "react";
import { IPlatformConfig } from "../../../services/ApiInterfaces";
import { PlatformConfig } from "../../../services/crud-models/PlatformConfig";
import { useStateIfMounted, UtilityDialog, Centered, useEditorMetadata,
    FieldEditor, IEditorStateControl, useEditorState } from "omni-voice-react-shared";
import { EditorForm } from "omni-voice-react-shared";
import { EmailConfig } from "../../../services/crud-models/EmailConfig";

import ManagePlatformApi from "../../../services/backend-apis/ManagePlatformApi";

import { Stack, Button } from "@mui/material";

type UtilityDialogProps = { title: string, desc: string, onClose: any, variant: string };

export interface IPlatformSettingsProps {
    config: IPlatformConfig;
};

export function PlatformSettings(props: IPlatformSettingsProps): JSX.Element {
    const api = useMemo(() => new ManagePlatformApi(), []);

    const [error, setError] = useStateIfMounted(null);
    const [pending, setPending] = useStateIfMounted(false);

    const configureEmailCtrl = {
        checkPermission: () => true, // permission already checked by parent
        apply: async model => {
            setPending(true);
            const result = await api.updateEmailConfig(model);
            setPending(false);
            if (!result.success) {
                setError(result.error);
            }
        },
        cancel: () => {},
        alwaysEnabled: true,
    } as IEditorStateControl;

    const configureEmailState = useEditorState(configureEmailCtrl);

    const editorMetadata = useEditorMetadata({ model: new PlatformConfig(props.config) });

    const openEmailConfigDialog = () => {
        configureEmailState.selectModel(new EmailConfig(), true);
    };

    const handleApply = useCallback(async () => {
        if (editorMetadata.valid) {
            const committed_model = editorMetadata.commit();
            try {
                setPending(true);
                console.log(committed_model);
                await api.updatePlatformConfig(committed_model);
            }
            catch(exception) {
                setError(exception + "");
            }
            setPending(false);
        }
    }, [editorMetadata]);

    return (
        <Stack
            spacing={2}
            direction="column">
            <Stack
                spacing={1}
                sx={{ margin: "10px", flexGrow: 1 }}
                direction="column">
                {editorMetadata.editors.map((editor, key) => <FieldEditor key={key} {...editor} />)}
            </Stack>
            <Centered>
                <Stack direction="row" spacing={2}>
                    <Button
                        autoFocus
                        variant="contained"
                        onClick={() => handleApply()}
                        disabled={!editorMetadata.valid || !editorMetadata.modified || pending}>
                        Apply
                    </Button>

                    <Button
                        autoFocus
                        variant="contained"
                        onClick={() => openEmailConfigDialog()}>
                        Email
                    </Button>
                </Stack>
            </Centered>

            {error && (
                <UtilityDialog
                    {...{
                        onClose: () => setError(null),
                        variant: "error",
                        title: "Settings Update Error",
                        desc: error,
                    } as UtilityDialogProps} />
            )}

            {configureEmailState.isOpen && (
                <EditorForm
                    title={"Global Email Settings"}
                    description={`Fields marked with (*) are required.`}
                    model={configureEmailState.model}
                    onClose={v => v ? configureEmailState.apply(v) : configureEmailState.cancel(v)}
                    />
            )}
        </Stack>
    );
}