import OmniVoiceWallpaper from '../../img/biomtetrics-banner.png';

import { List } from '@mui/material';
import { Typography, Paper } from '@mui/material';

import { Fingerprint as EnrollmentsIcon, VpnKey as ApiKeysIcon, LiveHelpOutlined as DocsIcon,
    Storage as AppliancesIcon } from '@mui/icons-material';

import { AppId } from '../../services/hooks/useAppLogin';
import { OpenAppButton } from '../admin/manage-platform/OpenAppButton';
import { PanelItem } from './PanelItem';

export interface IBiometricsPanelProps {
    speakerCount?: number;
    lastEnrollmentDateUTC?: number;
    applianceCount?: number;
}

export function BiometricsPanel(props: IBiometricsPanelProps): JSX.Element {
    return <>
        <Paper className="card" elevation={10}>
            <div className="bkg">
                {/*<div className={classes.rotated} style={{backgroundImage: `url(${ReportWallpaper})`}} />*/}
                <img className="rotated" src={OmniVoiceWallpaper} />
            </div>

            <Typography className="card-title" component="div" variant="h4">
                <OpenAppButton appId={AppId.OmniVoice}>Biometrics</OpenAppButton>
            </Typography>

            <List className="root">
                <PanelItem
                    className="card-item"
                    icon={<EnrollmentsIcon />}
                    subscript={`Last Enrolment Date: ${props.lastEnrollmentDateUTC ?? "--"}`}>
                    <OpenAppButton
                        appId={AppId.OmniVoice}
                        path="manageSpeakers"
                        justifyContent="flex-start">
                            Speakers: {`${props.speakerCount ?? "--"}`}
                    </OpenAppButton>
                </PanelItem>

                <PanelItem
                    className="card-item"
                    icon={<AppliancesIcon />}
                    subscript="Telephony Appliances">
                    <OpenAppButton
                        appId={AppId.OmniVoice}
                        path="manageServers"
                        justifyContent="flex-start">
                            Appliances: {`${props.applianceCount ?? "--"}`}
                    </OpenAppButton>
                </PanelItem>

                <PanelItem
                    className="card-item"
                    icon={<ApiKeysIcon />}
                    subscript="Integrations Security">
                    <OpenAppButton
                        appId={AppId.OmniVoice}
                        path="manageAPIKeys"
                        justifyContent="flex-start">
                            API Keys
                    </OpenAppButton>
                </PanelItem>

                <PanelItem
                    className="card-item"
                    icon={<DocsIcon />}
                    subscript="API Docs & Tutorials">
                    <OpenAppButton
                        appId={AppId.OmniVoice}
                        path="docs"
                        justifyContent="flex-start">
                            Docs
                    </OpenAppButton>
                </PanelItem>

            </List>

            <Typography className="description">
                
            </Typography>
        </Paper>
    </>;
}