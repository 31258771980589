import { Typography, Stack, Box } from "@mui/material";
import { Centered } from "omni-voice-react-shared";

export function RegistrationComplete(): JSX.Element {
    return (
        <Centered>
            <Stack
                direction="column"
                spacing={3}
                sx={{
                    textAlign: "center",
                    border: "2px solid #F0F0F0",
                    borderRadius: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Box>
                    <Typography component="div" variant="h3" sx={{ margin: "80px 80px 20px" }}>
                        Thank You!
                    </Typography>
                </Box>
                <Box>
                    <Typography component="div" sx={{ margin: "0px 80px 80px" }}>
                        We have emailed you a password reset link to the nominated email address. You can start using OMNI Voice as soon as you set your password.
                        Please note, the password reset link will expire in 5 minutes.
                    </Typography>
                </Box>
            </Stack>
        </Centered>
    );
}