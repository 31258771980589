import { Utils } from 'omni-voice-react-shared';
import { ILoginResult, IApiCallResult, IEnrollResult } from '../ApiInterfaces';

import { RootRoutePaths } from '../../components/routes/RootRoutePaths';
import { RegisterFormModel } from '../crud-models/RegisterFormModel';

export class AuthApi {
    private static _instance: AuthApi = new AuthApi();
    private static _api_url(api_name: string): string { return Utils.joinUrl(RootRoutePaths.BaseBackendAddress, "auth", api_name); }
    private static async _enact_fetch<T extends IApiCallResult>(action: () => Promise<Response>): Promise<T> {
        try {
            const response = await action();
            if (response.ok) {
                const result: T = await response.json();
                return result;
            }
            else {
                return {
                    success: false,
                    error: `${response.status} (${response.statusText})`,
                } as T;
            }
        }
        catch(error) {
            return {
                success: false,
                error: error + "",
            } as T;
        }
    };

    public async submitVoiceCode(workflow_id: string, voice: Blob, lang_code: string): Promise<ILoginResult> {
        if (!voice) throw new Error("voice is null or undefined");
        if (!lang_code) throw new Error("lang_code is null or undefined");
        if (!workflow_id) throw new Error("workflow_id is null or undefined");

        const url = AuthApi._api_url("submitVerificationCode");
        const form_data = new FormData();

        form_data.append("wavFile", voice);
        form_data.append("languageCode", lang_code);
        form_data.append("workflowId", workflow_id);
        form_data.append("verificationCode", "");

        const action = () => fetch(url, {
            method: "POST",
            body: form_data,
        });

        return await AuthApi._enact_fetch<ILoginResult>(action);
    }

    public async submitTextCode(workflow_id: string, code: string): Promise<ILoginResult> {
        if (!code) throw new Error("code is null or undefined");
        if (!workflow_id) throw new Error("workflow_id is null or undefined");

        const url = AuthApi._api_url("submitVerificationCode");
        const form_data = new FormData();

        form_data.append("wavFile", null);
        form_data.append("languageCode", null);
        form_data.append("workflowId", workflow_id);
        form_data.append("verificationCode", code);

        const action = () => fetch(url, {
            method: "POST",
            body: form_data,
        });

        return await AuthApi._enact_fetch<ILoginResult>(action);
    }

    public async voiceLogin(voice: Blob, lang_code: string): Promise<ILoginResult> {
        if (!voice) throw new Error("voice is null or undefined");
        if (!lang_code) throw new Error("lang_code is null or undefined");

        const url = AuthApi._api_url("voiceLogin");
        const form_data = new FormData();

        form_data.append("WavFile", voice);
        form_data.append("LanguageCode", lang_code);
        form_data.append("WorkflowId", "00000000-0000-0000-0000-000000000000");
        form_data.append("VerificationCode", "");

        const action = () => fetch(url, {
            method: "POST",
            body: form_data,
        });

        return await AuthApi._enact_fetch<ILoginResult>(action);
    }

    public static async enroll(voice: Blob, phoneNumber: string, confirmation: string = ""): Promise<IEnrollResult> {
        if (!voice) throw new Error("voice is null or undefined");
        if (!phoneNumber) throw new Error("Phone Number is null or undefined");

        const url = AuthApi._api_url("enroll");
        const form_data = new FormData();

        form_data.append("WavFile",         voice);
        form_data.append("Phone",           phoneNumber);
        form_data.append("Confirmation",    confirmation);

        const action = () => fetch(url, {
            method: "POST",
            body: form_data,
        });

        return await AuthApi._enact_fetch<IEnrollResult>(action);
    }

    public async login(email: string, password: string): Promise<ILoginResult> {
        if (!email || !password) throw new Error("Either email or password is undefined or null");

        const url = AuthApi._api_url("login");
        const form_data = new FormData();

        form_data.append("username", email);
        form_data.append("password", password);

        const action = () => fetch(url, {
            method: "POST",
            body: form_data,
        });

        return await AuthApi._enact_fetch<ILoginResult>(action);
    }

    public async register(dto: RegisterFormModel): Promise<IApiCallResult> {
        if (!dto) throw new Error("dto is undefined or null");
        const url = AuthApi._api_url("register");
        const action = () => fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dto),
        });
        return await AuthApi._enact_fetch<IApiCallResult>(action);
    }

    public async requestPasswordReset(username: string): Promise<IApiCallResult> {
        if (!username) throw new Error("Username is undefined or null");
        const url = AuthApi._api_url("requestPasswordReset");
        const action = () => fetch(`${url}/${username}`);
        return await AuthApi._enact_fetch<IApiCallResult>(action);
    }

    public async resetPassword(confirmation_id: string, password: string): Promise<IApiCallResult> {
        if (!confirmation_id || !password) throw new Error("Either confirmation_id or password is undefined or null");
        const url = AuthApi._api_url("resetPassword");
        const dto = {
            confirmationId: confirmation_id,
            password: password,
        };
        const action = () => fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dto),
        });
        return await AuthApi._enact_fetch<IApiCallResult>(action);
    }

    public static get instance(): AuthApi { return AuthApi._instance; }
};