import { useEffect, useCallback } from 'react';

import { scopeColumns } from '../TableSchemas';
import { WrapsDataGrid, makeCrudButton, useDataGridState,
    useEditorState, UtilityDialog, ICrudApiResult, IEditorStateControl, IIdentifiable } from 'omni-voice-react-shared';
import { VerticalToolbar, useStateIfMounted } from 'omni-voice-react-shared';

import { ScopeEditorDialog } from './ScopeEditorDialog';

import { brown, green, red } from '@mui/material/colors';

import ScopeApi from '../../../services/backend-apis/ScopeApi';
import { PermissionType } from '../../../services/PermissionType';
import { IScope, IScopeLocality } from '../../../services/ApiInterfaces';

import { useAppState } from '../../../services/app-state/useAppState';

// icons
import { AddCircle as CreateIcon, DeleteSweep as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { AppStateEnum } from '../../../services/app-state/AppState';

function sanitizeIdentifiable(identifiable: IIdentifiable) {
    var result = identifiable;
    if (identifiable.id.startsWith("new")) {
        result = {...identifiable, id: "00000000-0000-0000-0000-000000000000",};
    }
    return result;
}

function sanitizeScope(scope: IScope) {
    return {
        ...sanitizeIdentifiable(scope),
        localities: scope.localities.map(loc => sanitizeIdentifiable(loc)),
    };
}

const ManageScopes = (props) => {
    const [errorMessage, setErrorMessage] = useStateIfMounted(null);
    const [pending, setPending] = useStateIfMounted(false);
    const appState = useAppState();
    const loaded = appState.state === AppStateEnum.idle;

    const processCrudResult = useCallback((result: ICrudApiResult<IScope>) => {
        setPending(false);
        if (result) {
            if (result.hasError) {
                setErrorMessage(result.error);
            }
            else {
                dataGridState.selectEntry(null);
                dataGridState.updateEntries(result.data, result.total);
            }
        }
        else {
            setErrorMessage("System error occurred");
        }
    }, [setPending, setErrorMessage]);

    // Editor / dialogs management
    const createCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Scope_Create),
        apply: async model => {
            setPending(true);
            const sanitized_model = sanitizeScope(model);
            const result: ICrudApiResult<IScope> = await ScopeApi.getInstance().create(sanitized_model, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: true,
    } as IEditorStateControl;

    const editCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Scope_Update),
        apply: async model => {
            setPending(true);
            const sanitized_model = sanitizeScope(model);
            console.log(sanitized_model);
            const result: ICrudApiResult<IScope> = await ScopeApi.getInstance().update(sanitized_model, dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const deleteCtrl = {
        checkPermission: () => appState.hasPermission(PermissionType.Scope_Delete),
        apply: async model => {
            setPending(true);
            const result: ICrudApiResult<IScope> = await ScopeApi.getInstance().delete([model.id], dataGridState.dataView);
            processCrudResult(result);
        },
        cancel: () => {},
        alwaysEnabled: false,
    } as IEditorStateControl;

    const createState = useEditorState(createCtrl);
    const editState = useEditorState(editCtrl);
    const deleteState = useEditorState(deleteCtrl);

    // Data Grid controls && state
    const dataGridControl = {
        pageSizeOptions: [10, 50, 100],
        populate: async data_view => {
            const resp = await ScopeApi.getInstance().get(data_view);
            return resp;
        },
        handleEntrySelected: entry => {
            buttonsDef.forEach(def => def.state.selectModel(entry));
        },
    };

    // DataGrid management
    const dataGridState = useDataGridState(dataGridControl);

    useEffect(() => {
        if (loaded) {
            buttonsDef.forEach(def => def.state.invalidate());
            ScopeApi.getInstance().invalidateState();
            dataGridState.refresh();
        }
    }, [loaded, appState.effectiveRole, appState.effectiveScope, appState.effectiveOrg]);

    // toolbar buttons def and render
    const buttonsDef = [
        {icon: <CreateIcon />, tooltip: "Create New", state: createState, color: green[700]},
        {icon: <EditIcon />, tooltip: "Edit Selected", state: editState, color: brown[500]},
        {icon: <DeleteIcon />, tooltip: "Delete Selected", state: deleteState, color: red[700]},
    ];

    const toolbarButtons = buttonsDef
        .filter(def => def.state.isAvailable)
        //editor_state, icon, caption, minimized=false
        .map((def, key) => makeCrudButton(def.state, def.icon, def.tooltip, key=key, true, def.color));
    
    useEffect(() => {
        if (dataGridState.error) {
            setErrorMessage(dataGridState.error);
        }
    }, [dataGridState.error]);

    useEffect(() => {
        buttonsDef.forEach(def => def.state.disablePermanently(pending || dataGridState.pendingOp));
    }, [pending, dataGridState.pendingOp, buttonsDef]);

    const Toolbar = () => <VerticalToolbar {...props}>{toolbarButtons}</VerticalToolbar>;

    const Error = () => {
        return <>
            {errorMessage && (
                <UtilityDialog
                    {...props}
                    variant="error"
                    title="Error"
                    desc={errorMessage}
                    onClose={() => setErrorMessage(null)}/>
            )}
        </>
    };

    const Dialogs = () => {
        return <>
            {deleteState.isOpen && (
                <UtilityDialog
                    {...props}
                    variant="confirmation"
                    title="Confirm Delete Scope"
                    desc="Deleting scope does not delete any organizations / users in the scope. The operation is final and cannot be undone. Please confirm you wish to proceed"
                    onClose={ok => ok ? deleteState.apply(deleteState.model) : deleteState.cancel(deleteState.model)}
                    />
            )}

            {createState.isOpen && (
                <ScopeEditorDialog
                    title={"Create Scope"}
                    model={{
                        name: "New Scope",
                        localities: [],
                    }}
                    onClose={v => v ? createState.apply(v) : createState.cancel(v)}
                    />
            )}
        
            {editState.isOpen && (
                <ScopeEditorDialog
                    title="Edit Scope"
                    model={editState.model}
                    onClose={v => v ? editState.apply(v) : editState.cancel(v)}
                    />
            )}
        </>;
    };

    const styles = {
        container: {
            display: "flex",
            width: "100%",
            flexFlow: "row",
            border: '0px solid green',
            p: "15px",
            alignItems: "stretch",
        },

        grid: {
            flex: 1,
            overflow: "auto",
            marginRight: "40px",
        },
    };

    return (
        <div style={styles.container}>
            <Toolbar />
            <div style={styles.grid}>
                <WrapsDataGrid
                    sx={null}
                    gridState={dataGridState}
                    columnSchema={scopeColumns}
                    loading={pending}
                    />
            </div>
            <Dialogs />
            <Error />
        </div>
    );
};

export default ManageScopes;