import React from 'react';
import { VoiceRecorderContext } from './VoiceRecorderContext';
import { IUseVoiceRecorderResult } from './useWavRecorder';
import { RecorderTheme, RecorderThemeId } from './RecorderTheme';
import { IRecordedMedia } from './browser-api/WavRecorder_AudioCaptureBased';
import { Callout } from './Callout';
import './VoiceRecorder.css';
import { useCountdown } from './useCountdown';

export enum VoiceRecorderSize {
    tiny,
    small,
    medium,
    large,
};

const sizes = [60, 80, 140, 200];

export interface IVoiceRecorderProps {
    size: VoiceRecorderSize;
    themeId: RecorderThemeId;
    color: string;
    timeoutSec: number;
    minNetSpeechSec: number;
    disabled: boolean;
    prompt: string;
    onVoiceObtained: (media: IRecordedMedia) => void;
};

export function VoiceRecorder(props: IVoiceRecorderProps): JSX.Element {
    const { size, color, timeoutSec, disabled } = props;
    const minNetSpeechSec: number = props.minNetSpeechSec ? props.minNetSpeechSec : 10;
    const voiceRecorder: IUseVoiceRecorderResult = React.useContext(VoiceRecorderContext);
    const countdown = useCountdown();
    const canvasRef = React.useRef(null);
    const [media, setMedia] = React.useState(null);
    const [recorderTheme] = React.useState(new RecorderTheme(color, null, RecorderThemeId.dark));
    const [shaken, setShaken] = React.useState(true);
    const [message, setMessage] = React.useState(props.prompt ? props.prompt : "Click or Tap to start recording");
    const [disabledInternally, setDisabledInternally] = React.useState(false);

    const isInteractive = !disabled && !disabledInternally && !voiceRecorder.isRecording && voiceRecorder.loaded;
    const background = !isInteractive ? recorderTheme.theme.disabled.background : recorderTheme.theme.enabled.background;
    const text = !isInteractive ? recorderTheme.theme.disabled.icon : recorderTheme.theme.enabled.icon;

    React.useEffect(() => {
        const rec: IRecordedMedia = media;
        if (rec) {
            if (rec.success) {
                console.log("Net speech captured: " + rec.netSpeechSec);
                if (rec.netSpeechSec < minNetSpeechSec) {
                    setShaken(true);
                    setMessage("Not enough speech. Please try again!")
                }
                else {
                    if (props.onVoiceObtained) {
                        props.onVoiceObtained(media);
                    }
                    if (!props.prompt) {
                        setMessage(`Net speech captured: ${rec.netSpeechSec}`);
                    }
                }
            }
            else {
                setShaken(true);
                setMessage("Opps! Something went wrong. Please try again!")
            }
        }
    }, [media]);

    React.useEffect(() => {
        setMessage(props.prompt);
    }, [props.prompt, setMessage]);

    React.useEffect(() => {
        if (voiceRecorder.loaded && !voiceRecorder.error) {
            voiceRecorder.setDisabled(disabled);
        }
    }, [disabled]);

    React.useEffect(() => {
        if (voiceRecorder.loaded) {
            voiceRecorder.visualizeTo(canvasRef.current, recorderTheme.theme);
        }
        if (!voiceRecorder.error) {
            voiceRecorder.setDisabled(disabled);
        }
        else {
            voiceRecorder.setDisabled(true);
            setDisabledInternally(true);
            setMessage(voiceRecorder.error);
        }
    }, [voiceRecorder.loaded]);

    React.useEffect(() => {
        if (voiceRecorder.recordedMedia) {
            //console.log(`URL='${voiceRecorder.recordedMedia.blobUrl}', type='${voiceRecorder.recordedMedia.blob.type}', size=${voiceRecorder.recordedMedia.blob.size}`);
            setMedia(voiceRecorder.recordedMedia);
        }
    }, [voiceRecorder.recordedMedia]);

    const handleRecordButtonClick = React.useCallback(() => {
        if (canvasRef.current !== null && isInteractive) {
            setShaken(false);
            countdown.start(timeoutSec);
            voiceRecorder.start(timeoutSec);
        }
    }, [voiceRecorder, canvasRef, isInteractive]);

    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "stretch",
                }}>
                <div
                    className={isInteractive ? "voice-recorder" : null}
                    style={{
                        margin: "10px",
                        minWidth: `${sizes[size]}px`,
                        minHeight: `${sizes[size]}px`,
                        alignSelf: "center",
                    }}>
                    <canvas
                        ref={canvasRef}
                        onClick={handleRecordButtonClick}
                        width={sizes[size]}
                        height={sizes[size]}
                        style={{
                            borderRadius: `${sizes[size]/2}px`,
                            width: `${sizes[size]}px`,
                            height: `${sizes[size]}px`,
                            boxShadow: "1px 1px 10px grey",
                            background: isInteractive ? null : recorderTheme.theme.enabled.background
                        }}/>
                </div>
                <Callout {...props}
                    flashing={voiceRecorder.isRecording}
                    shaken={shaken}
                    backgroundColor={background}
                    textColor={text}
                    style={{
                        alignSelf: "center",
                    }}>
                    {voiceRecorder.isRecording ? `Recording will stop in ${countdown.secLeft} sec...` : message}
                </Callout>
            </div>
        </div>
    );
};