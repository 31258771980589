import { ModelBase, editor, ModelEditorType } from "omni-voice-react-shared";
// import { ModelBase } from "../editable-model/ModelBase";
// import { editor } from "../editable-model/decorator";
// import { ModelEditorType } from "../editable-model/interfaces";

export class EditOrgModel extends ModelBase {
    @editor({
        index: 0,
        label: "Org Name",
        doc: "Please enter your name or email here if org name is not available. Providing a proper name of the org will greatly help us locate the account in case of support enquiries. This will lower our response times considerably",
        required: true,
        type: ModelEditorType.Name,
        validationError: "",
    })
    name: string = "";

    @editor({
        index: 10,
        label: "Contact Name",
        doc: "Please enter the name of the contact person within your org. Our automated platform communications (such as alert emails) will be directed to this name",
        required: true,
        type: ModelEditorType.Name,
        validationError: "Must be at least 5 carachters",
    })
    contactName: string = "";

    @editor({
        index: 15,
        label: "Contact Email",
        doc: "Please enter the email of the contact person within your org. Our automated platform communications (such as alert emails) will be directed to this email",
        required: true,
        type: ModelEditorType.Email,
        validationError: "Must be a valid email address",
    })
    contactEmail: string = "";

    @editor({
        index: 20,
        label: "Contact Phone",
        doc: "Please provide a phone number that we can reach the org Contact Name on. The number is used for security reasons in case of lost login credentials",
        required: true,
        type: ModelEditorType.Phone,
        validationError: "Must contain digits only ('+' for phone numbers) and have the length of at least 8 characters",
    })
    contactPhone: string = "";

    @editor({
        index: 30,
        label: "Org Website",
        doc: "Optional company website will help us verify your org for corporate contract plans",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    website: string = "";

    @editor({
        index: 40,
        label: "Address Line 1",
        doc: "Street address or building name",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    addressLine1: string = "";

    @editor({
        index: 50,
        label: "Address Line 2",
        doc: "Street address or suburb",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    addressLine2: string = "";

    @editor({
        index: 60,
        label: "Address Line 3",
        doc: "Suburb or city name",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    addressLine3: string = "";

    @editor({
        index: 70,
        label: "Postcode",
        doc: "",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    postcode: string = "";

    @editor({
        index: 80,
        label: "State",
        doc: "",
        required: false,
        type: ModelEditorType.Text,
        validationError: "",
    })
    state: string = "";

    // TODO: implement country ModelEditorType
    @editor({
        index: 90,
        label: "Country",
        doc: "",
        required: true,
        type: ModelEditorType.Country,
        validationError: "",
    })
    country: string = "";

    constructor(partial?: any) {
        super();
        if (partial) Object.assign(this, partial);
        for(const prop in this) {
            //console.log(prop, typeof this[prop], this[prop] === null);
            if (prop !== 'metadata' && this[prop] === null) {
                this[prop as string] = "";
            }
        }
    }
};