import { IHttpError, IRestHeader, OmniVoiceRestState } from "omni-voice-react-shared";
import Auth from '../../stores/Auth';
import { RootRoutePaths } from '../../components/routes/RootRoutePaths';
import { AuthScheme } from "omni-voice-react-shared";
import { AppState } from "./AppState";
import { Scope, UserType } from "../Scope";
import { navigateWrapped } from '../navigate';

export class RestState extends OmniVoiceRestState {
    get authScheme(): string { return AuthScheme.identity_bearer; }
    get backendUrl(): string { return RootRoutePaths.BaseBackendAddress; }
    get headers(): IRestHeader[] {
        const role = AppState.instance.effectiveRole;
        const scope = AppState.instance.effectiveScopeDef;
        var result = [];

        if (role && scope && Scope.userType(scope) !== UserType.Null) {
            result = [
                { name: "X-OMNIVOICE-ROLE", value: role.name },
                { name: "X-OMNIVOICE-SCOPE-ID", value: scope.scopeId ?? null },
                { name: "X-OMNIVOICE-ORG-ID", value: scope.orgId },
            ];
        }
        
        return result;
    }
    getCachedAuth(): string { return Auth.getToken(); }
    setCachedAuth(token: string) { Auth.setToken(token); }
    removeCachedAuth() { Auth.removeToken(); }
    onAuthorized(): void { /*history.push(RoutePaths.AppsHome);*/ }
    onUnauthorized(): void {
        AppState.instance.invalidateState();
        //history.push(RoutePaths.SignIn);
    }
    onHttpError(error: IHttpError): void {
        if (this.getCachedAuth()) {
            let details = "";
            if (error.error){
                details = Object.keys(error.error).map(key => `${key}: ${error.error[key]}`).join(", ");
            }
            navigateWrapped(RootRoutePaths.errorNav(error.errorDescription, details));
        }
    }
    onException(error: Error): void {
        if (this.getCachedAuth()) {
            var error_url = RootRoutePaths.errorNav(error + "", "");
            navigateWrapped(error_url);
        }
    }
};