export type EventArgs<TPayload, TSource> = { sender: TSource, payload: TPayload };
export type EventHandler<TPayload, TSource> = (args: EventArgs<TPayload, TSource>) => void;

export class Event<TPayload, TSource> {
    private _handlers: EventHandler<TPayload, TSource>[] = [];
    private _source: TSource = null;

    subscribe(handler: EventHandler<TPayload, TSource>) {
        if (!handler) throw new Error("handler is undefined or null");
        this._handlers = [...this._handlers, handler];
    }

    unsubscribe(handler: EventHandler<TPayload, TSource>) {
        this._handlers = this._handlers.filter(h => h !== handler);
    }

    raise(payload: TPayload) {
        this._handlers.forEach(handler => handler({sender: this._source, payload: payload}));
    }

    get source(): TSource { return this._source; }

    constructor(source: TSource) { this._source = source; }
};