import { ICrudApiResult, IDataView, Rest } from 'omni-voice-react-shared';
import { IScope } from '../ApiInterfaces';
import { CrudApi } from 'omni-voice-react-shared';

export default class ScopeApi extends CrudApi<IScope> {
    private constructor() { super(1000); }
    private static _instance = new ScopeApi();

    protected async onGet(data_view: IDataView): Promise<ICrudApiResult<IScope>> {
        const resp = await Rest.post<ICrudApiResult<IScope>>('/scope/get', data_view);
        return this.processRestResponse(resp, e => e);
    }
    protected async onUpdate(entity: IScope): Promise<ICrudApiResult<IScope>> {
        const resp = await Rest.post<ICrudApiResult<IScope>>('/scope/update', entity);
        return this.processRestResponse(resp, e => e);
    }
    protected async onDelete(entity_ids: string[]): Promise<ICrudApiResult<IScope>> {
        if (entity_ids.length > 1) throw new Error("The API supports the deletion of a single entry only");
        const resp = await Rest.get<ICrudApiResult<IScope>>(`/scope/delete/${entity_ids[0]}`)
        return this.processRestResponse(resp, e => e);
    }
    protected onDeleteView(data_view: IDataView): Promise<ICrudApiResult<IScope>> {
        throw new Error("Method not supported");
    }
    protected onDeleteAll(): Promise<ICrudApiResult<IScope>> {
        throw new Error("Method not supported");
    }
    protected async onCreate(create_entity_dto: object): Promise<ICrudApiResult<IScope>> {
        const resp = await Rest.post<ICrudApiResult<IScope>>('/scope/create', create_entity_dto);
        return this.processRestResponse(resp, e => e);
    }

    static getInstance(): ScopeApi { return ScopeApi._instance; }
};
