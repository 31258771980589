import { ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';

export interface IPanelItemProps {
    icon: JSX.Element;
    subscript?: string;
    children: any | any[];
    className?: string;
};

export function PanelItem(props: IPanelItemProps) {
    return (
        <ListItem className={props.className}>
            <ListItemAvatar>
                <Avatar>{props.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText secondary={props.subscript ?? ""}>
                {props.children}
            </ListItemText>
        </ListItem>
    );
}