import WavVisualizer, { IWavVisualizerStyle } from './WavVisualizer';
import AudioCapture from './AudioCapture';
import hark from 'hark';

/**
 * Encapsulates the recording ready to be sent to the backend for processing.
 */
 export interface IRecordedMedia {
    blob: Blob;
    blobUrl: string;
    durationSec: number;
    netSpeechSec: number;
    success: boolean;
    error: string;
};

/**
 * Records speaker's voice at 16KHZ and returns it as a wav blob.
 * This is based on the re-engineered AudioCapture that is supposed to
 * work on the majority of the browsers, including mobile chrome and safari.
 */
export default class WavRecorder_AudioCaptureBased {
    private _error: string = "Uninitialized";
    private _hasError: boolean;
    private _recorder: AudioCapture;
    private _startTime: number;
    private _visualizer: WavVisualizer = null;
    private _hark;
    private _speechEvents: {startSec: number, stopSec: number}[] = [];
    private _speechEvent: number = null;

    private _throwIfError(): void { if (this._hasError) throw new Error(this._error); }

    private _speechStart(): void {
        //console.log("speech start");
        const start_time = new Date().getTime();
        this._speechEvent = start_time;
    }
    private _speechEnd(): void {
        //console.log("speech end");
        if (this._speechEvent !== null) {
            const stop_time = new Date().getTime();
            this._speechEvents.push({
                startSec: this._speechEvent,
                stopSec: stop_time,
            });
        }

        this._speechEvent = null;
    }

    public get netSpeechSec(): number {
        var result = 0;
        if (this._speechEvents) {
            result = this
                ._speechEvents
                .map(ev => ev.stopSec >= ev.startSec ? ev.stopSec - ev.startSec : 0)
                .reduce((x,y) => x+y, 0);
        }
        return result / 1000;
    }

    /**
     * Must be called before using.
     */
    public async initialize(): Promise<void> {
        // https://stackoverflow.com/questions/68485089/navigator-mediadevices-not-available-on-ios-14-3
        // in order for it to work on an ios device, it should be https:// otherwise it won't work so
        // for debug, do not use the iphone
        if (navigator.mediaDevices?.getUserMedia({ audio: true, video: false })) {
            try {
                this._recorder = await AudioCapture.create();
            }
            catch( error ) {
                this._hasError = true;
                this._error = error.message;
            }
        }
        else {
            this._hasError = true;
            this._error = "Audio capture is not supported by your browser";
        }
    }

    public async dispose(): Promise<void> {
        await this.stop();
        if (this._visualizer) this._visualizer.dispose();
        try {
            this._recorder.dispose();
        }
        catch(error) {
            console.log(error);
        }
    }

    public async stop(): Promise<IRecordedMedia> {
        if (this._recorder?.recording) {
            this._hark.stop(); // this will clean up, all good...
            this._speechEnd();
            this._recorder.stop();
            var export_ = await this._recorder.exportWav();
            console.log(export_);
            var result: IRecordedMedia = {
                blob: export_.result,
                blobUrl: window.URL.createObjectURL(export_.result),
                durationSec: Math.round((new Date().getTime() - this._startTime) / 1000),
                error: export_.error,
                success: export_.success,
                netSpeechSec: this.netSpeechSec,
            };
            await this._recorder.clear();
            return result;
        }
        else return null;
    }

    public start(): void {
        this._throwIfError();
        if (!this._recorder.recording) {
            this._startTime = new Date().getTime();
            this._speechEvents = [];
            
            this._hark = hark(this.stream, { audioContext: this.audioContext, threshold: -50 });
            this._hark.on('speaking', this._speechStart.bind(this));
            this._hark.on('stopped_speaking', this._speechEnd.bind(this));

            this._recorder.record();

            this.audioContext.resume();
        }
        else throw new Error(`Recorder is already started`);
    }

    public get error(): string { return this._error; }
    public get hasError(): boolean { return this._hasError; }
    public get visualizer(): WavVisualizer { return this._visualizer; }
    public get stream(): MediaStream { return this._recorder.stream ?? null; }
    public get audioContext(): AudioContext { return this._recorder.audioContext ?? null; }

    public visualizeTo(canvas: HTMLCanvasElement, theme: IWavVisualizerStyle): void {
        this._visualizer = new WavVisualizer(this._recorder, canvas, theme);
    }

    constructor() {}
};