import { useCallback, useEffect } from 'react';
import { useDebouncedState } from './useDebouncedState';
import { useStateIfMounted } from 'omni-voice-react-shared';
import { TextField, Autocomplete, } from '@mui/material';
import { IEvent } from "../access-mode/useEvent";

export interface ILookupObject {
    id: string;
    name: string;
};

export interface ILookupProps {
    defaultValueLabel?: string;
    initialOptions?: ILookupObject[];
    value: ILookupObject;
    label: string;
    disabled?: boolean;
    refetchEvent?: IEvent;
    search?: (search_pattern: string) => Promise<ILookupObject[]>;
    onValueChange(value: ILookupObject): void;
};

export function Lookup(props: ILookupProps): JSX.Element {
    const [options, setOptions] = useStateIfMounted(props.initialOptions ? props.initialOptions ?? [] : []);
    const [searchTerm, setSearchTerm] = useDebouncedState("", 300);
    const [value, setValue] = useStateIfMounted((props.value ?? null) as ILookupObject);

    const optionEqualToValue = useCallback((e: any, option: ILookupObject) => {
        return !option && !value || option.id === value.id;
    }, [value]);

    const formatLookupObject = useCallback((value: ILookupObject) => {
        return value?.name ? value.name : props.defaultValueLabel ?? "(not set)";
    }, [props.defaultValueLabel]);

    const fetchFromServer = useCallback(async () => {
        if (props.search) {
            const options = await props.search(searchTerm);
            setOptions(options);
        }
    }, [setOptions, searchTerm, props.search]);

    const handleChange = useCallback((_, val) => {
        //console.log("set new lookup value", val);
        setValue(val);
        if (props.onValueChange) {
            props.onValueChange(val);
        }
    }, [setValue, props.onValueChange]);

    useEffect(() => {
        if (!props.initialOptions) {
            fetchFromServer();
        }
    }, []);
    useEffect(() => { fetchFromServer(); }, [searchTerm]);
    useEffect(() => {
        if (props.value || props.value === null) {
            fetchFromServer();
            setValue(props.value ?? null);
        }
    }, [props.value]);
    useEffect(() => {
        fetchFromServer();
    }, [props.refetchEvent]);

    return (
        <Autocomplete
            disabled={props.disabled ?? false}
            options={options}
            getOptionLabel={formatLookupObject}
            renderInput={(params) => <TextField {...params} label={props.label} />}
            isOptionEqualToValue={optionEqualToValue}
            value={value}
            onChange={handleChange}
            onInputChange={(e, term) => setSearchTerm(term)}
            autoSelect
            filterOptions={x => x}
            />
    );
};