import { useCallback, forwardRef, useEffect } from 'react';

import { Button, Typography, Dialog, DialogActions,
    DialogContent, DialogTitle, Grow, GrowProps, Stack } from '@mui/material';

import { useStateIfMounted, DataView } from 'omni-voice-react-shared';
import { IScopeDefinition, IUser } from '../../services/ApiInterfaces';

import { Lookup, ILookupProps } from '../access-mode/Lookup';

import ScopeApi from '../../services/backend-apis/ScopeApi';
import OrgApi from '../../services/backend-apis/OrgApi';
import { AppState } from '../../services/app-state/AppState';
import { UserType } from '../../services/Scope';
import { useEvent } from '../access-mode/useEvent';

const Transition_Grow = forwardRef<JSX.Element, GrowProps>(function Transition(props, ref) {
    return (
        <Grow
            in={true}
            ref={ref}
            {...props}
            {...{ timeout: 500 }}
            />
        );
});

export interface IRescopeDialogProps {
    onClose: (scope_def: IScopeDefinition) => void;
    /**
     * If true, the dialog will support an org lookup field
     */
    allowLocal?: boolean;
    model: IUser,
    title: string;
};

export function RescopeDialog(props: IRescopeDialogProps) {
    if (!props.model) throw new Error("props.model is null or undefined");

    const [scope, setScope] = useStateIfMounted(props.model.scope ?? null);
    const [org, setOrg] = useStateIfMounted(props.model.org ?? null);
    const refetchOrgsEvent = useEvent();

    const canLookupScope = AppState.instance.callerType === UserType.Global;
    const canLookupOrg = props.allowLocal && scope && AppState.instance.callerType > UserType.Local;

    useEffect(() => {
        if (scope) {
            OrgApi.getInstance().invalidateState();
            refetchOrgsEvent.raise(scope);
        }
    }, [scope, refetchOrgsEvent]);

    const fetchOrgs = useCallback(async (term: string) => {
        const data_view = {
            ...DataView.searchByNamePattern(term, 20),
            scope: scope,
        };
        const crud_res = await OrgApi.getInstance().get(data_view);
        if (crud_res && !crud_res.hasError) {
            return crud_res.data;
        }
        else return [];
    }, [scope]);

    const fetchScopes = async (term: string) => {
        const crud_res = await ScopeApi.getInstance().get(DataView.searchByNamePattern(term, 20));
        if (crud_res && !crud_res.hasError) {
            return crud_res.data;
        }
        else return [];
    };

    const handleScopeChange = useCallback((new_value) => {
        setScope(new_value);
        setOrg(null);
    }, [setScope, setOrg]);

    const handleClose = useCallback((apply: boolean) => {
        if (props.onClose) {
            if (apply) {
                const scope_id = scope?.id ?? null;
                const org_id = scope_id ? (org?.id ?? null) : null; // can't set local scope if the scopeId is null - scope rules
                const scope_def: IScopeDefinition = {
                    orgId: org_id,
                    scopeId: scope_id,
                };
                props.onClose(scope_def);
            }
            else {
                props.onClose(null);
            }
        }
    }, [scope, org, props]);

    const scopeLookupProps: ILookupProps = {
        disabled: !canLookupScope,
        label: canLookupScope ? "Select Scope" : "Scope",
        value: scope,
        defaultValueLabel: "(global)",
        search: fetchScopes,
        onValueChange: handleScopeChange,
    };

    const orgLookupProps: ILookupProps = {
        disabled: !canLookupOrg,
        label: canLookupOrg ? "Select Org" : "Org",
        value: org,
        defaultValueLabel: "(not set)",
        search: fetchOrgs,
        onValueChange: setOrg,
        refetchEvent: refetchOrgsEvent,
    };
  
    return (
        <Dialog TransitionComponent={Transition_Grow} open={true} onClose={() => handleClose(false)} fullWidth={true} maxWidth="sm">
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <div>
                        <Typography variant="h6">Choose Scope</Typography>
                        <Typography sx={{ mb: "10px" }} color="text.secondary">You can type in the field to search for available scopes</Typography>
                        <Lookup {...scopeLookupProps} />
                    </div>
                    {props.allowLocal &&
                        <div>
                            <Typography variant="h6">Choose Org</Typography>
                            <Typography sx={{ mb: "10px" }} color="text.secondary">This will associate the user with an org. Users associated with orgs are considered "local" org users</Typography>
                            <Lookup {...orgLookupProps} />
                        </div>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleClose(false)}>CANCEL</Button>
                <Button variant="contained" onClick={() => handleClose(true)} autoFocus>SAVE</Button>
            </DialogActions>
        </Dialog>
    );
};