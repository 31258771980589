import { Event } from "./Event";
import { ICaller, IRoleDefinition, IScope, IScopeDefinition, IOrg } from "../ApiInterfaces";
import { Scope, UserType } from "../Scope";
import UserApi from "../backend-apis/UserApi";
import Cache from "../../stores/Cache";
import { Utils } from "omni-voice-react-shared";

export enum AppStateEnum {
    unknown,
    pending,
    idle,
    error
};

/**
 * Keeps track of all state except for authentication.
 */
export class AppState {
    private static _instance = new AppState();

    private _state = AppStateEnum.unknown;
    private _error: string = null;

    private _stateChangeEvent = new Event<AppStateEnum, AppState>(this);
    private _effectiveScopeDefChangeEvent = new Event<IScopeDefinition, AppState>(this);
    private _effectiveRoleChangeEvent = new Event<IRoleDefinition, AppState>(this);
    private _effectiveOrgChangeEvent = new Event<IOrg, AppState>(this);
    private _effectiveScopeChangeEvent = new Event<IScope, AppState>(this);

    invalidateState(): void {
        this._state = AppStateEnum.unknown;
        this._stateChangeEvent.raise(this.state);
        Cache.callerUser = null;
    }

    get stateChangeEvent(): Event<AppStateEnum, AppState> { return this._stateChangeEvent; }

    get caller(): ICaller { return Cache.callerUser; }
    get callerType(): UserType {
        const caller_scope_def = Scope.scopeDef(this.caller);
        const caller_type = Scope.userType(caller_scope_def);
        return caller_type;
    }

    get effectiveScopeDef(): IScopeDefinition { return { scopeId: Cache.effectiveScope?.id ?? null, orgId: Cache.effectiveOrg?.id ?? null } as IScopeDefinition; }
    get effectiveScopeDefChangeEvent(): Event<IScopeDefinition, AppState> { return this._effectiveScopeDefChangeEvent; }

    get org(): IOrg { return Cache.effectiveOrg; }
    set org(org: IOrg) {
        if (!Utils.deepCompare(org, Cache.effectiveOrg)) {
            Cache.effectiveOrg = org;
            this._effectiveScopeDefChangeEvent.raise(this.effectiveScopeDef);
            this._effectiveOrgChangeEvent.raise(org);
        }
    }
    get effectiveOrgChangeEvent() { return this._effectiveOrgChangeEvent; }

    get scope(): IScope { return Cache.effectiveScope; }
    set scope(scope: IScope) {
        if (!Utils.deepCompare(scope, Cache.effectiveScope)) {
            Cache.effectiveScope = scope;
            this._effectiveScopeDefChangeEvent.raise(this.effectiveScopeDef);
            this._effectiveScopeChangeEvent.raise(scope);
        }
    }
    get effectiveScopeChangeEvent() { return this._effectiveScopeChangeEvent; }

    get effectiveRole(): IRoleDefinition { return Cache.effectiveRole; }
    set effectiveRole(role: IRoleDefinition) {
        Cache.effectiveRole = role;
        this._effectiveRoleChangeEvent.raise(role);
    }
    get effectiveRoleChangeEvent(): Event<IRoleDefinition, AppState> { return this._effectiveRoleChangeEvent; }

    get error(): string { return this._error; }
    get state(): AppStateEnum { return this._state; }

    /**
     * Updates state by sending requests to the server
     */
    async fetchState(): Promise<void> {
        if (this._state === AppStateEnum.pending) return;

        // make pending and notify
        this._state = AppStateEnum.pending;
        this._stateChangeEvent.raise(AppStateEnum.pending);

        try {
            // ... logic
            var resp = await UserApi.getInstance().getCaller();
            if ((resp && resp.hasHttpError)) {
                throw new Error(resp?.httpError?.errorDescription ?? "Backend is not accessible");
            }
            var caller = resp.content;
            Cache.callerUser = caller;

            if (caller.invalidateEffectiveScope) {
                Cache.effectiveRole = caller.role;
                Cache.effectiveScope = caller.scope;
                Cache.effectiveOrg = caller.org;
                this._effectiveRoleChangeEvent.raise(this.effectiveRole);
                this._effectiveScopeDefChangeEvent.raise(this.effectiveScopeDef);
            }
            else {
                // check if scope / org got renamed and update cache if so
                if (Cache.effectiveOrg && caller.org?.id === Cache.effectiveOrg.id) {
                    Cache.effectiveOrg = caller.org;
                }
                if (Cache.effectiveScope && caller.scope?.id === Cache.effectiveScope.id) {
                    Cache.effectiveScope = caller.scope;
                }
            }

            //console.log("Effective Scope On Init", Cache.effectiveScope);

            // logic done, now update state and notify
            this._state = AppStateEnum.idle;
            this._stateChangeEvent.raise(AppStateEnum.idle);

            //console.log("State changed to ", this._state);
        }
        catch(error) {
            // critical error, update state and notify
            console.log(`Critical error occurred while fetching root state graph: ${error}`);
            this._error = error;
            this._state = AppStateEnum.error;
            this._stateChangeEvent.raise(AppStateEnum.error);
        }
    }

    static get instance(): AppState { return this._instance; }
};