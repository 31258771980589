import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Typography, Paper } from '@mui/material';

import { Person, Power, AttachMoney, LiveHelpOutlined as DocsIcon, AttachMoney as BillingIcon } from '@mui/icons-material';

import { AppId } from '../../services/hooks/useAppLogin';
import { OpenAppButton } from '../admin/manage-platform/OpenAppButton';
import { PanelItem } from './PanelItem';

export interface IBillingPanelProps {
    balance?: number;
    lastPaymentDateUTC?: number;
    currentPlan?: string;
}

export function BillingPanel(props: IBillingPanelProps): JSX.Element {
    return <>
        <Paper className="card" elevation={10}>
            <div className="bkg">
                {/*<div className={classes.rotated} style={{backgroundImage: `url(${ReportWallpaper})`}} />*/}
                {/*<img className={classes.rotated} src={Settings} style={{marginTop: "-15%"}} />*/}
                <BillingIcon className="rotated" style={{marginTop: "-30%"}} />
            </div>

            <Typography className="card-title" component="div" variant="h4">
                <OpenAppButton appId={AppId.Billing}>Billing</OpenAppButton>
            </Typography>

            <List className="root">
                <PanelItem
                    className="card-item"
                    icon={<BillingIcon />}
                    subscript={`Current Plan: ${props.currentPlan ?? "--"}`}>
                    <OpenAppButton
                        appId={AppId.Billing}
                        path=""
                        justifyContent="flex-start">
                            Balance: {`${props.balance!=undefined ? '$' + (Math.round( props.balance * 100 + Number.EPSILON ) / 100.).toFixed(2) : "--"}`}
                    </OpenAppButton>
                </PanelItem>

                {/* <PanelItem
                    className="card-item"
                    icon={<AppliancesIcon />}
                    subscript="Telephony Appliances">
                    <OpenAppButton
                        appId={AppId.OmniVoice}
                        path="manageServers"
                        justifyContent="flex-start">
                            Appliances: {`${props.applianceCount ?? "--"}`}
                    </OpenAppButton>
                </PanelItem> */}

                 <PanelItem
                    className="card-item"
                    icon={<DocsIcon />}
                    subscript="API Docs & Tutorials">
                    <OpenAppButton
                        appId={AppId.Billing}
                        path="docs"
                        justifyContent="flex-start">
                            Docs
                    </OpenAppButton>
                </PanelItem>
            </List>

            <Typography className="description">
                
            </Typography>
        </Paper>
    </>;
}