import { InlineWorker, InlineWorkerJob, IWorkerResult } from "./InlineWorker";
import { exportSourceCode } from './resampler/resampler';

export interface IAudioCaptureConfig {
    bufferLen: number;
    numChannels: number;
    mimeType: string;
    wavSamplingRate_Hz: number;
};

function resample(samples, from_SR, to_SR) { throw new Error("Should not be called directly - only from a web worker that has access to the implementation"); }

/**
 * Introduced instead of the "standard" MediaRecorder which has too much "black magic" and is currently (12/02/2022)
 * supported by only 53% of the browsers.
 * Adopted based on the tested Recorder.js written by Matt Diamond (https://github.com/mattdiamond/Recorderjs) which,
 * apparently, has the best cross-browser support.
 * The resampling is done using a small and simple JS lib from https://github.com/rochars/wave-resampler.
 * Because of simplicity, this should be easy to turn into a redistributable JS package to be shipped with a component.
 * 
 * This class uses web workers which are rather cumbersome. I have followed this guide to implement them:
 * https://gist.github.com/julianpoemp/1292445696eae2ea319d92ae15ecffa4 (it doesn't require placing a js file into the
 * /public folder that will be executed by the worker - yakkk...).
 */
export default class AudioCapture {
    private _inlineWorker = new InlineWorker([...exportSourceCode(), `const AudioCaptureWorkerApi = ${AudioCaptureWorkerApi.toString()}`]);
    private _config: IAudioCaptureConfig = {
        bufferLen: 4096,
        numChannels: 1,
        mimeType: "audio/wav",
        wavSamplingRate_Hz: 16000,
    };
    private _recording: boolean = false;
    private _context: AudioContext = null;
    private _processor: ScriptProcessorNode = null;
    private _stream: MediaStream = null;

    private _onaudioprocess(e: AudioProcessingEvent) {
        if (this._recording) {
            //console.log("recording");
            var buffer = [];
            for(var channel = 0; channel < this._config.numChannels; channel++) {
                buffer.push(e.inputBuffer.getChannelData(channel));
            }

            var record_job = new InlineWorkerJob((context, args) => {
                return new Promise<void>(function(resolve) {
                    var input_buffer = args[0];
                    var api = <AudioCaptureWorkerApi>context["workerApi"];
                    api.record(input_buffer);
                    resolve();
                });
            },
            [buffer],
            `function(context, args) {
                return new Promise(function (resolve) {
                  var input_buffer = args[0];
                  var api = context["workerApi"];
                  api.record(input_buffer);
                  resolve();
                });
              }`);

            this._inlineWorker.run(record_job);
        }
    }

    private _getInitJob() {
        var init_job = new InlineWorkerJob((context, args) => {
            return new Promise<void>(resolve => {
                var num_channels = args[0];
                var aud_SR_Hz = args[1];
                var wav_SR_Hz = args[2];
                context["workerApi"] = new AudioCaptureWorkerApi(num_channels, aud_SR_Hz, wav_SR_Hz);
                resolve();
            });
        },
        [this._config.numChannels, this._context.sampleRate, this._config.wavSamplingRate_Hz],
        `function(context, args) {
            return new Promise(resolve => {
              var num_channels = args[0];
              var aud_SR_Hz = args[1];
              var wav_SR_Hz = args[2];
              context["workerApi"] = new AudioCaptureWorkerApi(num_channels, aud_SR_Hz, wav_SR_Hz);
              resolve();
            });
          }`
        );
        return init_job;
    }

    public get recording() { return this._recording; }
    public record() { this._recording = true; }
    public stop() { this._recording = false; }
    public async clear() { await this._inlineWorker.run<void>(this._getInitJob()); }
    // public async getBuffer(): Promise<IWorkerResult<any>> {
    //     var get_buffer_job = new InlineWorkerJob((context, args) => {
    //         return new Promise(resolve => {
    //             var api = <AudioCaptureWorkerApi>context["workerApi"];
    //             var result = api.getBuffer();
    //             resolve(result);
    //         });
    //     }, []);

    //     return await this._inlineWorker.run(get_buffer_job);
    // }
    public async exportWav(mime_type = "audio/wav"): Promise<IWorkerResult<Blob>> {
        mime_type = mime_type ?? this._config.mimeType;
        var export_wav = new InlineWorkerJob(async (context, args) => {
            try {
                var mime_type = args[0];
                var api = <AudioCaptureWorkerApi>context["workerApi"];
                var result = await api.exportWav(mime_type);
                //console.log(`${result.type}: ${result.size} bytes`);
                return result;
            }
            catch(error) {
                console.log("Error exporting to wav: " + error);
                return null;
            }
        },
        [mime_type],
        `async function(context, args) {
            try {
              var mime_type = args[0];
              var api = context["workerApi"];
              var result = await api.exportWav(mime_type);
      
              return result;
            } catch (error) {
              console.log("Error exporting to wav: " + error);
              return null;
            }
          }`);

        return await this._inlineWorker.run<Blob>(export_wav);
    }

    public get audioContext(): AudioContext { return this._context; }
    public get stream(): MediaStream { return this._stream; }

    public dispose() {
        this._inlineWorker.destroy();
        this._stream
            .getTracks()
            .forEach(t => t.stop());
        this._context.close();
    }

    public static async create(config?: any): Promise<AudioCapture> {
        const context = new AudioContext({ latencyHint: "interactive", sampleRate: 16000 });
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        const result = new AudioCapture(context, stream, config);
        await context.resume();
        return result;
    }

    private constructor(context: AudioContext, stream: MediaStream, config?: any) {
        if (config) Object.assign(this._config, config);
        this._context = context;
        this._stream = stream;
        var source = context.createMediaStreamSource(this._stream);
        // the below seems depricated; however, I have found that back in 2018, for example, FireFox didn't support the alternative!
        this._processor = this._context.createScriptProcessor(this._config.bufferLen, this._config.numChannels, this._config.numChannels);
        this._processor.onaudioprocess = this._onaudioprocess.bind(this);
        source.connect(this._processor);
        this._processor.connect(this._context.destination);
        this._inlineWorker.run(this._getInitJob());
    }
};

class AudioCaptureWorkerApi {
    private _numChannels: number = 1;
    private _recBuffers: any[] = [];
    private _recLength: number = 0;
    private _audioSamplingRate_Hz: number;
    private _wavSamplingRate_Hz: number = 16000;

    public get numChannels() { return this._numChannels; }
    public get wavSamplingRate() { return this._wavSamplingRate_Hz; }

    public record(input_buffer: any[]) {
        //console.log(`Recording buf: ${input_buffer.length}`);
        for(var channel = 0; channel < this.numChannels; channel++) {
            this._recBuffers[channel].push(input_buffer[channel]);
        }
        this._recLength += input_buffer[0].length;
    }

    public merge(rec_buffers, rec_length) {
        var result = new Float32Array(rec_length);
        var offset = 0;
        for(var i = 0; i < rec_buffers.length; i++) {
            result.set(rec_buffers[i], offset);
            offset += rec_buffers[i].length;
        }
        return result;
    }

    public getBuffer() {
        var buffers = [];
        for(var channel = 0; channel < this.numChannels; channel++) {
            var merged = this.merge(this._recBuffers[channel], this._recLength);
            buffers.push(merged);
        }
        return buffers;
    }

    public interleave(inputL, inputR) {
        var length = inputL.length + inputR.length;
        var result = new Float32Array(length);

        var index = 0,
            inputIndex = 0;

        while (index < length) {
            result[index++] = inputL[inputIndex];
            result[index++] = inputR[inputIndex];
            inputIndex++;
        }
        return result;
    }

    public floatTo16BitPCM(output, offset, input) {
        for (var i = 0; i < input.length; i++, offset += 2) {
            var s = Math.max(-1, Math.min(1, input[i]));
            output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }
    }

    public writeString(view, offset, string) {
        for (var i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    public encodeWav(samples) {
        var buffer = new ArrayBuffer(44 + samples.length * 2);
        var view = new DataView(buffer);

        /* RIFF identifier */
        this.writeString(view, 0, 'RIFF');
        /* RIFF chunk length */
        view.setUint32(4, 36 + samples.length * 2, true);
        /* RIFF type */
        this.writeString(view, 8, 'WAVE');
        /* format chunk identifier */
        this.writeString(view, 12, 'fmt ');
        /* format chunk length */
        view.setUint32(16, 16, true);
        /* sample format (raw) */
        view.setUint16(20, 1, true);
        /* channel count */
        view.setUint16(22, this.numChannels, true);
        /* sample rate */
        view.setUint32(24, this.wavSamplingRate, true);
        /* byte rate (sample rate * block align) */
        view.setUint32(28, this.wavSamplingRate * 4, true);
        /* block align (channel count * bytes per sample) */
        view.setUint16(32, this.numChannels * 2, true);
        /* bits per sample */
        view.setUint16(34, 16, true);
        /* data chunk identifier */
        this.writeString(view, 36, 'data');
        /* data chunk length */
        view.setUint32(40, samples.length * 2, true);

        this.floatTo16BitPCM(view, 44, samples);

        return view;
    }

    public exportWav(mime_type) {
        var buffers = [];
        for (var channel = 0; channel < this.numChannels; channel++) {
            buffers.push(this.merge(this._recBuffers[channel], this._recLength));
        }
        var interleaved = undefined;

        for(var i = 0; i<this._numChannels; i++) {
            buffers[i] = resample(buffers[i], this._audioSamplingRate_Hz, this._wavSamplingRate_Hz);
        }

        if (this.numChannels === 2) {
            interleaved = this.interleave(buffers[0], buffers[1]);
        } else {
            interleaved = buffers[0];
        }
        var dataview = this.encodeWav(interleaved);
        var audioBlob = new Blob([dataview], { type: mime_type });

        return audioBlob;
    }

    constructor(num_channels: number, audio_SR_Hz: number, wav_SR_Hz = 16000) {
        this._numChannels = num_channels;
        this._wavSamplingRate_Hz = wav_SR_Hz;
        this._audioSamplingRate_Hz = audio_SR_Hz;
        for(var channel = 0; channel < num_channels; channel++) {
            this._recBuffers[channel] = [];
        }
    }
};