import { useEffect, useCallback } from "react";
import { useStateIfMounted } from "omni-voice-react-shared";
import { AppState, AppStateEnum } from "./AppState";
import { IRoleDefinition, IScopeDefinition, IOrg, IScope } from "../ApiInterfaces";

export interface IAppState {
    state: AppStateEnum;
    effectiveRole: IRoleDefinition;
    effectiveScopeDef: IScopeDefinition;
    effectiveOrg: IOrg;
    effectiveScope: IScope;
    hasPermission: (permission: string) => boolean;
};

export function useAppState(): IAppState {
    const [state, setState] = useStateIfMounted(AppState.instance.state);
    const [effectiveRole, setEffectiveRole] = useStateIfMounted(AppState.instance.effectiveRole);
    const [effectiveScopeDef, setEffectiveScopeDef] = useStateIfMounted(AppState.instance.effectiveScopeDef);
    const [effectiveOrg, setEffectiveOrg] = useStateIfMounted(AppState.instance.org);
    const [effectiveScope, setEffectiveScope] = useStateIfMounted(AppState.instance.scope);

    const handleAppStateChange = useCallback(({ sender, payload }) => {
        setState(payload);
        if (payload === AppStateEnum.idle) {
            setEffectiveOrg(sender.org);
            setEffectiveScope(sender.scope);
        }
    }, [setState, setEffectiveOrg, setEffectiveScope]);

    const handleEffectiveRoleChange = useCallback(({ sender, payload }) => {
        setEffectiveRole(payload);
    }, [setEffectiveRole]);
    const handleEffectiveScopeDefChange = useCallback(({ sender, payload }) => {
        setEffectiveScopeDef(payload);
    }, [setEffectiveScopeDef]);
    const handleEffectiveOrgChange = useCallback(({ sender, payload }) => {
        setEffectiveOrg(payload);
    }, [setEffectiveOrg]);
    const handleEffectiveScopeChange = useCallback(({ sender, payload }) => {
        setEffectiveScope(payload);
    }, [setEffectiveScope]);

    useEffect(() => {
        AppState.instance.stateChangeEvent.subscribe(handleAppStateChange);
        AppState.instance.effectiveRoleChangeEvent.subscribe(handleEffectiveRoleChange);
        AppState.instance.effectiveScopeDefChangeEvent.subscribe(handleEffectiveScopeDefChange);
        AppState.instance.effectiveOrgChangeEvent.subscribe(handleEffectiveOrgChange);
        AppState.instance.effectiveScopeChangeEvent.subscribe(handleEffectiveScopeChange);

        if (AppState.instance.state !== AppStateEnum.idle) AppState.instance.fetchState();

        return () => {
            AppState.instance.stateChangeEvent.unsubscribe(handleAppStateChange);
            AppState.instance.effectiveRoleChangeEvent.unsubscribe(handleEffectiveRoleChange);
            AppState.instance.effectiveScopeDefChangeEvent.unsubscribe(handleEffectiveScopeDefChange);
            AppState.instance.effectiveOrgChangeEvent.unsubscribe(handleEffectiveOrgChange);
            AppState.instance.effectiveScopeChangeEvent.unsubscribe(handleEffectiveScopeChange);
        };
    }, []);

    return {
        state: state,
        effectiveRole: effectiveRole,
        effectiveScopeDef: effectiveScopeDef,
        effectiveScope: effectiveScope,
        effectiveOrg: effectiveOrg,
        hasPermission: (permission: string) =>
            effectiveRole?.permissions.find(p => p.name === permission) ? true : false,
    } as IAppState;
};