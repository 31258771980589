export const InlineWorkerCompiled =
`

const Interpolator = class Interpolator {
    constructor(scaleFrom, scaleTo, details) {
      this.length_ = scaleFrom;
      this.scaleFactor_ = (scaleFrom - 1) / scaleTo;
      this.interpolate = this.cubic;
  
      if (details.method === 'point') {
        this.interpolate = this.point;
      } else if (details.method === 'linear') {
        this.interpolate = this.linear;
      } else if (details.method === 'sinc') {
        this.interpolate = this.sinc;
      }
      this.tangentFactor_ = 1 - Math.max(0, Math.min(1, details.tension || 0)); // Configure the kernel for sinc
  
      this.sincFilterSize_ = details.sincFilterSize || 1;
      this.kernel_ = sincKernel_(details.sincWindow || window_);
    }
    point(t, samples) {
      return this.getClippedInput_(Math.round(this.scaleFactor_ * t), samples);
    }
    linear(t, samples) {
      t = this.scaleFactor_ * t;
      let k = Math.floor(t);
      t -= k;
      return (1 - t) * this.getClippedInput_(k, samples) + t * this.getClippedInput_(k + 1, samples);
    }
    cubic(t, samples) {
      t = this.scaleFactor_ * t;
      let k = Math.floor(t);
      let m = [this.getTangent_(k, samples), this.getTangent_(k + 1, samples)];
      let p = [this.getClippedInput_(k, samples), this.getClippedInput_(k + 1, samples)];
      t -= k;
      let t2 = t * t;
      let t3 = t * t2;
      return (2 * t3 - 3 * t2 + 1) * p[0] + (t3 - 2 * t2 + t) * m[0] + (-2 * t3 + 3 * t2) * p[1] + (t3 - t2) * m[1];
    }
    sinc(t, samples) {
      t = this.scaleFactor_ * t;
      let k = Math.floor(t);
      let ref = k - this.sincFilterSize_ + 1;
      let ref1 = k + this.sincFilterSize_;
      let sum = 0;
  
      for (let n = ref; n <= ref1; n++) {
        sum += this.kernel_(t - n) * this.getClippedInput_(n, samples);
      }
  
      return sum;
    }
    getTangent_(k, samples) {
      return this.tangentFactor_ * (this.getClippedInput_(k + 1, samples) - this.getClippedInput_(k - 1, samples)) / 2;
    }
    getClippedInput_(t, samples) {
      if (0 <= t && t < this.length_) {
        return samples[t];
      }
  
      return 0;
    }
  
  }
  
  
  const FIRLPF = class FIRLPF {
    constructor(order, sampleRate, cutOff) {
      let omega = 2 * Math.PI * cutOff / sampleRate;
      let dc = 0;
      this.filters = [];
  
      for (let i = 0; i <= order; i++) {
        if (i - order / 2 === 0) {
          this.filters[i] = omega;
        } else {
          this.filters[i] = Math.sin(omega * (i - order / 2)) / (i - order / 2); // Hamming window
  
          this.filters[i] *= 0.54 - 0.46 * Math.cos(2 * Math.PI * i / order);
        }
  
        dc = dc + this.filters[i];
      } // normalize
  
  
      for (let i = 0; i <= order; i++) {
        this.filters[i] /= dc;
      }
  
      this.z = this.initZ_();
    }
    filter(sample) {
      this.z.buf[this.z.pointer] = sample;
      let out = 0;
  
      for (let i = 0, len = this.z.buf.length; i < len; i++) {
        out += this.filters[i] * this.z.buf[(this.z.pointer + i) % this.z.buf.length];
      }
  
      this.z.pointer = (this.z.pointer + 1) % this.z.buf.length;
      return out;
    }
    reset() {
      this.z = this.initZ_();
    }
    initZ_() {
      let r = [];
  
      for (let i = 0; i < this.filters.length - 1; i++) {
        r.push(0);
      }
  
      return {
        buf: r,
        pointer: 0
      };
    }
  
  }
  
  
  const ButterworthLPF = class ButterworthLPF {
    constructor(order, sampleRate, cutOff) {
      let filters = [];
  
      for (let i = 0; i < order; i++) {
        filters.push(this.getCoeffs_({
          Fs: sampleRate,
          Fc: cutOff,
          Q: 0.5 / Math.sin(Math.PI / (order * 2) * (i + 0.5))
        }));
      }
  
      this.stages = [];
  
      for (let i = 0; i < filters.length; i++) {
        this.stages[i] = {
          b0: filters[i].b[0],
          b1: filters[i].b[1],
          b2: filters[i].b[2],
          a1: filters[i].a[0],
          a2: filters[i].a[1],
          k: filters[i].k,
          z: [0, 0]
        };
      }
    }
    filter(sample) {
      let out = sample;
  
      for (let i = 0, len = this.stages.length; i < len; i++) {
        out = this.runStage_(i, out);
      }
  
      return out;
    }
  
    getCoeffs_(params) {
      let coeffs = {};
      coeffs.z = [0, 0];
      coeffs.a = [];
      coeffs.b = [];
      let p = this.preCalc_(params, coeffs);
      coeffs.k = 1;
      coeffs.b.push((1 - p.cw) / (2 * p.a0));
      coeffs.b.push(2 * coeffs.b[0]);
      coeffs.b.push(coeffs.b[0]);
      return coeffs;
    }
  
    preCalc_(params, coeffs) {
      let pre = {};
      let w = 2 * Math.PI * params.Fc / params.Fs;
      pre.alpha = Math.sin(w) / (2 * params.Q);
      pre.cw = Math.cos(w);
      pre.a0 = 1 + pre.alpha;
      coeffs.a0 = pre.a0;
      coeffs.a.push(-2 * pre.cw / pre.a0);
      coeffs.k = 1;
      coeffs.a.push((1 - pre.alpha) / pre.a0);
      return pre;
    }
  
    runStage_(i, input) {
      let temp = input * this.stages[i].k - this.stages[i].a1 * this.stages[i].z[0] - this.stages[i].a2 * this.stages[i].z[1];
      let out = this.stages[i].b0 * temp + this.stages[i].b1 * this.stages[i].z[0] + this.stages[i].b2 * this.stages[i].z[1];
      this.stages[i].z[1] = this.stages[i].z[0];
      this.stages[i].z[0] = temp;
      return out;
    }
    reset() {
      for (let i = 0; i < this.stages.length; i++) {
        this.stages[i].z = [0, 0];
      }
    }
  
  }
  
  
  const DEFAULT_LPF_USE = {'point': false, 'linear': false, 'cubic': true, 'sinc': true};
  
  
  const DEFAULT_LPF_ORDER = {'IIR': 16, 'FIR': 71};
  
  
  const DEFAULT_LPF = {'IIR': ButterworthLPF, 'FIR': FIRLPF};
  
  
  const FACTORY = {'INTER': Interpolator}
  
  
  const resample = function resample(samples, oldSampleRate, sampleRate, details = {}) {
    // Make the new sample container
    let rate = (sampleRate - oldSampleRate) / oldSampleRate + 1;
    let newSamples = new Float64Array(samples.length * rate); // Create the interpolator
  
    details.method = details.method || 'cubic';
    const INTER = FACTORY["INTER"];
    let interpolator = new INTER(samples.length, newSamples.length, {
      method: details.method,
      tension: details.tension || 0,
      sincFilterSize: details.sincFilterSize || 6,
      sincWindow: details.sincWindow || undefined
    }); // Resample + LPF
  
    if (details.LPF === undefined) {
      details.LPF = DEFAULT_LPF_USE[details.method];
    }
  
    if (details.LPF) {
      details.LPFType = details.LPFType || 'IIR';
      const LPF = DEFAULT_LPF[details.LPFType]; // Upsampling
  
      if (sampleRate > oldSampleRate) {
        let filter = new LPF(details.LPFOrder || DEFAULT_LPF_ORDER[details.LPFType], sampleRate, oldSampleRate / 2);
        upsample_(samples, newSamples, interpolator, filter); // Downsampling
      } else {
        let filter = new LPF(details.LPFOrder || DEFAULT_LPF_ORDER[details.LPFType], oldSampleRate, sampleRate / 2);
        downsample_(samples, newSamples, interpolator, filter);
      } // Resample, no LPF
  
    } else {
      resample_(samples, newSamples, interpolator);
    }
  
    return newSamples;
  }
  
  
  const resample_ = function resample_(samples, newSamples, interpolator) {
    // Resample
    for (let i = 0, len = newSamples.length; i < len; i++) {
      newSamples[i] = interpolator.interpolate(i, samples);
    }
  }
  
  
  const downsample_ = function downsample_(samples, newSamples, interpolator, filter) {
    // Filter
    for (let i = 0, len = samples.length; i < len; i++) {
      samples[i] = filter.filter(samples[i]);
    } // Reverse filter
  
  
    filter.reset();
  
    for (let i = samples.length - 1; i >= 0; i--) {
      samples[i] = filter.filter(samples[i]);
    } // Resample
  
  
    resample_(samples, newSamples, interpolator);
  }
  
  
  const upsample_ = function upsample_(samples, newSamples, interpolator, filter) {
    // Resample and filter
    for (let i = 0, len = newSamples.length; i < len; i++) {
      newSamples[i] = filter.filter(interpolator.interpolate(i, samples));
    } // Reverse filter
  
  
    filter.reset();
  
    for (let i = newSamples.length - 1; i >= 0; i--) {
      newSamples[i] = filter.filter(newSamples[i]);
    }
  }
  
  
  const window_ = function window_(x) {
    return Math.exp(-x / 2 * x / 2);
  }
  
  
  const sincKernel_ = function sincKernel_(window) {
    return function (x) {
      return sinc_(x) * window(x);
    };
  }
  
  
  const sinc_ = function sinc_(x) {
    if (x === 0) {
      return 1;
    }
  
    return Math.sin(Math.PI * x) / (Math.PI * x);
  }
  
  
  const AudioCaptureWorkerApi = class AudioCaptureWorkerApi {
    get numChannels() {
      return this._numChannels;
    }
  
    get wavSamplingRate() {
      return this._wavSamplingRate_Hz;
    }
  
    record(input_buffer) {
      for (var channel = 0; channel < this.numChannels; channel++) {
        this._recBuffers[channel].push(input_buffer[channel]);
      }
  
      this._recLength += input_buffer[0].length;
    }
  
    merge(rec_buffers, rec_length) {
      var result = new Float32Array(rec_length);
      var offset = 0;
  
      for (var i = 0; i < rec_buffers.length; i++) {
        result.set(rec_buffers[i], offset);
        offset += rec_buffers[i].length;
      }
  
      return result;
    }
  
    getBuffer() {
      var buffers = [];
  
      for (var channel = 0; channel < this.numChannels; channel++) {
        var merged = this.merge(this._recBuffers[channel], this._recLength);
        buffers.push(merged);
      }
  
      return buffers;
    }
  
    interleave(inputL, inputR) {
      var length = inputL.length + inputR.length;
      var result = new Float32Array(length);
      var index = 0,
          inputIndex = 0;
  
      while (index < length) {
        result[index++] = inputL[inputIndex];
        result[index++] = inputR[inputIndex];
        inputIndex++;
      }
  
      return result;
    }
  
    floatTo16BitPCM(output, offset, input) {
      for (var i = 0; i < input.length; i++, offset += 2) {
        var s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
      }
    }
  
    writeString(view, offset, string) {
      for (var i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    }
  
    encodeWav(samples) {
      var buffer = new ArrayBuffer(44 + samples.length * 2);
      var view = new DataView(buffer);
      /* RIFF identifier */
  
      this.writeString(view, 0, 'RIFF');
      /* RIFF chunk length */
  
      view.setUint32(4, 36 + samples.length * 2, true);
      /* RIFF type */
  
      this.writeString(view, 8, 'WAVE');
      /* format chunk identifier */
  
      this.writeString(view, 12, 'fmt ');
      /* format chunk length */
  
      view.setUint32(16, 16, true);
      /* sample format (raw) */
  
      view.setUint16(20, 1, true);
      /* channel count */
  
      view.setUint16(22, this.numChannels, true);
      /* sample rate */
  
      view.setUint32(24, this.wavSamplingRate, true);
      /* byte rate (sample rate * block align) */
  
      view.setUint32(28, this.wavSamplingRate * 4, true);
      /* block align (channel count * bytes per sample) */
  
      view.setUint16(32, this.numChannels * 2, true);
      /* bits per sample */
  
      view.setUint16(34, 16, true);
      /* data chunk identifier */
  
      this.writeString(view, 36, 'data');
      /* data chunk length */
  
      view.setUint32(40, samples.length * 2, true);
      this.floatTo16BitPCM(view, 44, samples);
      return view;
    }
  
    exportWav(mime_type) {
      var buffers = [];
  
      for (var channel = 0; channel < this.numChannels; channel++) {
        buffers.push(this.merge(this._recBuffers[channel], this._recLength));
      }
  
      var interleaved = undefined;
  
      for (var i = 0; i < this._numChannels; i++) {
        buffers[i] = resample(buffers[i], this._audioSamplingRate_Hz, this._wavSamplingRate_Hz);
      }
  
      if (this.numChannels === 2) {
        interleaved = this.interleave(buffers[0], buffers[1]);
      } else {
        interleaved = buffers[0];
      }
  
      var dataview = this.encodeWav(interleaved);
      var audioBlob = new Blob([dataview], {
        type: mime_type
      });
      return audioBlob;
    }
  
    constructor(num_channels, audio_SR_Hz, wav_SR_Hz = 16000) {
      this._numChannels = 1;
      this._recBuffers = [];
      this._recLength = 0;
      this._audioSamplingRate_Hz = void 0;
      this._wavSamplingRate_Hz = 16000;
      this._numChannels = num_channels;
      this._wavSamplingRate_Hz = wav_SR_Hz;
      this._audioSamplingRate_Hz = audio_SR_Hz;
  
      for (var channel = 0; channel < num_channels; channel++) {
        this._recBuffers[channel] = [];
      }
    }
  
  }
  
  
  
  var job = null;
  var base = self;
  var context = new Object();
  onmessage = function (msg) {
      var data = msg.data;
      var command = data.command;
      var args = data.args;
      switch (command) {
          case("run"):
              base.job = args[0];
              var func = new Function("return " + base.job.doFunction)();
              func(context, base.job.args).then(function (result) {
                  base.postMessage({
                      success: true,
                      result: result,
                      error: "",
                  });
              }).catch(function (error) {
                  base.postMessage({
                      success: false,
                      result: null,
                      error: error,
                  });
              });
              break;
          default:
              base.postMessage({
                  success: false,
                  result: null,
                  error: "invalid command"
              });
              break;
      }
  }
`;